import { useEffect } from 'react';
import { useTitleStore } from '../../framework/store/TitleStore';

export function Title({ title }: { title: string }) {
  const { setTitle } = useTitleStore();
  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
  return null;
}
