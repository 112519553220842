import {
  Avatar,
  Box, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FamilyMember as FamilyMemberType } from '../../../framework/types/FamilyMember';
import { GenderIcon } from '../../atoms/GenderIcon';
import { Roles } from '../../../framework/constants/roles';
import { AuthGuard } from '../../utils/AuthGuard';
import { prettyFormatFromDate } from '../../../utils/dateHelper';

type Props = { member: FamilyMemberType, openEditMode: (member: FamilyMemberType) => void, openDeleteMode: (member: FamilyMemberType) => void };

export function FamilyMember({ member, openEditMode, openDeleteMode }: Props) {
  const dateOfBirth = member.dob ? prettyFormatFromDate(new Date(member.dob)) : '';
  return (
    <ListItem
      sx={{
        '& .secondaryActions': {
          display: 'none',
        },
        '&:hover': {
          bgcolor: 'secondary.main',
          '& .secondaryActions': {
            display: 'block',
          },
        },
      }}
      secondaryAction={(
        <div className="secondaryActions">
          <AuthGuard roles={[Roles.ADMIN]}>
            <IconButton onClick={() => openEditMode(member)}><EditIcon /></IconButton>
            <IconButton onClick={() => openDeleteMode(member)}><DeleteIcon /></IconButton>
          </AuthGuard>
        </div>
      )}
    >
      <ListItemAvatar>
        <Avatar>
          <GenderIcon gender={member.gender} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={(
          <Box sx={{ display: 'flex', gap: '10px' }}>
            {member.first_name}
            {' '}
            {member.last_name}
            {member.is_emergency_contact && (
              <Tooltip title="Emergency Contact">
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  {member.phone}
                  <CallIcon />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
        secondary={dateOfBirth}
      />
    </ListItem>
  );
}
