import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { GenericTrimmedRequiredString } from '../../../../utils/zodHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { MealTypesService } from '../../../services/api/MealTypesService';
import { MealType, MealTypeRequest } from '../../../types/MealTypes';

export function useMealTypes() {
  const {
    data, isError, error, isLoading, isRefetching,
  } = useQuery(
    [QueryKey.MEAL_TYPES],
    () => MealTypesService.GetAll({
      page: 1, size: 1000,
    }),
    { keepPreviousData: true },
  );

  const queryClient = useQueryClient();

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editModeItem, setEditModeItem] = useState<MealType | null>(null);
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteModeItem, setDeleteModeItem] = useState<MealType | null>(null);

  const schema = z.object({
    name: GenericTrimmedRequiredString,
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  const {
    isLoading: createLoading, isError: isCreateError, mutate: create, variables: createVariables,
  } = useMutation(
    (formData: MealTypeRequest) => MealTypesService.Create(formData),
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          setCreateMode(false);
        }
        queryClient.invalidateQueries([QueryKey.MEAL_TYPES]);
      },
    },
  );
  const {
    isLoading: updateLoading, isError: isUpdateError, mutate: update, variables: updateVariables,
  } = useMutation(
    ({ id, formData }: { id: number, formData: MealTypeRequest }) => MealTypesService.Update(id, formData),
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          setEditMode(false);
          setEditModeItem(null);
        }
        queryClient.invalidateQueries([QueryKey.MEAL_TYPES]);
      },
    },
  );
  const {
    isLoading: deleteLoading, isError: isDeleteError, mutate: deleteMealType, variables: deleteVariables,
  } = useMutation(
    (id: number) => MealTypesService.Delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.MEAL_TYPES]);
      },
    },
  );

  const handleCreateSubmit = handleSubmit((formData) => create(formData as MealTypeRequest));
  const handleCloseCreateMode = () => {
    setCreateMode(false);
  };

  const handleUpdateSubmit = (id: number) => handleSubmit((formData) => update({ id, formData }));
  const handleCloseEditMode = () => {
    setEditMode(false);
    setEditModeItem(null);
  };
  const handleOpenEditMode = (item: MealType) => {
    setEditMode(true);
    setEditModeItem(item);
  };

  const handleOpenDeleteMode = (item: MealType) => {
    setDeleteMode(true);
    setDeleteModeItem(item);
  };
  const handleCloseDeleteMode = () => {
    setDeleteMode(false);
    setDeleteModeItem(null);
  };

  return {
    mealTypesData: data,
    mealTypesIsLoading: isLoading,
    mealTypesIsError: isError,
    mealTypesError: error,
    mealTypesIsRefetching: isRefetching,
    register,
    errors,
    createPackage: {
      createMode, setCreateMode, handleCreateSubmit, createLoading, isCreateError, createVariables, handleCloseCreateMode,
    },
    updatePackage: {
      editMode,
      editModeItem,
      setEditMode,
      handleUpdateSubmit,
      updateLoading,
      isUpdateError,
      updateVariables,
      handleCloseEditMode,
      handleOpenEditMode,
    },
    deletePackage: {
      deleteLoading,
      isDeleteError,
      deleteMealType,
      deleteVariables,
      deleteMode,
      deleteModeItem,
      handleOpenDeleteMode,
      handleCloseDeleteMode,
    },
  };
}
