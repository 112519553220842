import {
  Avatar,
  IconButton, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoFoodIcon from '@mui/icons-material/NoFood';
import { Allergen } from '../../../framework/types/Allergens';

type Props = { allergen: Allergen, openDeleteMode: (item: Allergen) => void, openEditMode: (item: Allergen) => void };

export function AllergenItem({ allergen, openDeleteMode, openEditMode }: Props) {
  return (
    <ListItem
      sx={{
        '& .secondaryActions': {
          display: 'none',
        },
        '&:hover': {
          bgcolor: 'secondary.main',
          '& .secondaryActions': {
            display: 'block',
          },
        },
      }}
      secondaryAction={(
        <div className="secondaryActions">
          <IconButton onClick={() => openEditMode(allergen)}><EditIcon /></IconButton>
          <IconButton onClick={() => openDeleteMode(allergen)}><DeleteIcon /></IconButton>
        </div>
      )}
    >
      <ListItemAvatar>
        <Avatar>
          <NoFoodIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={allergen.name} />
    </ListItem>
  );
}
