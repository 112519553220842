import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteInputChangeReason, createFilterOptions } from '@mui/material/Autocomplete';
import {
  Chip,
  debounce,
  FilterOptionsState,
  ListItemText, MenuItem, Skeleton,
} from '@mui/material';
import {
  Control, Controller, FieldValues, Path,
} from 'react-hook-form';
import { useState, useCallback, SyntheticEvent } from 'react';

type Props<T, R> = {
  options: T[],
  isLoading: boolean,
  name: Path<R & FieldValues>,
  control: Control<R & FieldValues, any>,
  openNewDialog?: (newValue: string) => void,
  setSearch?: (search: string) => void,
  onNewCustomValue?: (newValue: string) => T,
  label?: string,
  readOnly?: boolean,
  getOptionsLabel?: (option: T) => string

};
export default function ComboBox<T extends { id?: number, name?: string, value?: string, label?: string, inputValue?: string }, R>({
  name, options, isLoading, control, openNewDialog, setSearch, onNewCustomValue, label, readOnly, getOptionsLabel,
}: Props<T, R>) {
  const filter = createFilterOptions<T>();

  const [inputValue, setInputValue] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((s: string) => {
      if (setSearch) setSearch(s);
    }, 400),
    [],
  );

  const setFilterInputValue = (searchValue: string) => {
    setInputValue(searchValue);
    if (setSearch) debounceSearch(searchValue);
  };
  const onValueChange = (event: any, newValue: (T | string)[] | string | undefined, onChange: (...event: any) => void) => {
    if (newValue) {
      if (typeof newValue === 'string') {
        if (openNewDialog) openNewDialog(newValue);
      } else {
        const customValue = newValue.find((value) => typeof value !== 'string' && value.inputValue);
        if (customValue && typeof customValue !== 'string' && customValue.inputValue) {
          if (openNewDialog) openNewDialog(customValue.inputValue);
        } else if (newValue.every((value) => typeof value !== 'string')) {
          onChange(newValue as T[]);
          setFilterInputValue('');
        }
      }
    }
  };
  const onInputChange = (event: SyntheticEvent<Element, Event>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    if (event && event.type === 'blur') {
      setFilterInputValue('');
    } else if (reason !== 'reset') {
      setFilterInputValue(newInputValue);
    }
  };
  const onFilterOptions = (filteredOptions: T[], params: FilterOptionsState<T>) => {
    const filtered = filter(filteredOptions, params);
    if (filteredOptions.length === 0 && onNewCustomValue) {
      filtered.push(onNewCustomValue(params.inputValue));
    }
    return filtered;
  };
  const getOptionLabel = (option: string | T) => {
    if (typeof option !== 'string') {
      if (option.id) {
        return getOptionsLabel ? getOptionsLabel(option) : option.name || '';
      }
      return getOptionsLabel ? getOptionsLabel(option) : option.label || '';
    }
    return option;
  };
  if (options) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error, invalid } }) => (
          <Autocomplete
            fullWidth
            autoComplete
            includeInputInList
            disableCloseOnSelect
            filterSelectedOptions
            freeSolo
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} label={getOptionLabel(option)} />
            ))}
            clearOnBlur
            multiple
            options={options}
            loading={isLoading}
            {...field}
            filterOptions={onFilterOptions}
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={(event: any, value: (string | T)[]) => onValueChange(event, value, field.onChange)}
            renderInput={(params) => <TextField {...params} label={label || ''} error={invalid} helperText={error?.message} />}
            renderOption={(props, option) => {
              if (option.id) {
                return (
                  <MenuItem value={option.id} key={option.id} {...props}>{getOptionsLabel ? getOptionsLabel(option) : option.name || ''}</MenuItem>
                );
              }
              return (
                <MenuItem
                  value={option.value}
                  key={option.value}
                  {...props}
                >
                  {getOptionsLabel ? getOptionsLabel(option) : option.label || ''}
                </MenuItem>
              );
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => {
              if (option.id) {
                return option.id === value.id;
              }
              return option.value === value.value;
            }}
            readOnly={readOnly}
          />
        )}
      />
    );
  }
  return (
    <ListItemText>
      <Skeleton variant="text" width={200} height={30} sx={{ fontSize: '24px' }} />
    </ListItemText>
  );
}
