export class PersonalData {
  address: string;

  address_id_card: string;

  avatar: string;

  bank_account: string;

  city: string;

  city_id_card: string;

  country: string;

  country_id_card: string;

  county_id_card: string;

  dob: string;

  edu_degree: string;

  work_experience: number;

  lbo: string;

  marriage_status: string;

  personal_email: string;

  phone: string;

  pin: string;

  religious_date: string | null;

  passport_number: string;

  constructor(personal?: PersonalData) {
    this.address = personal?.address || '';
    this.address_id_card = personal?.address_id_card || '';
    this.avatar = personal?.avatar || '';
    this.bank_account = personal?.bank_account || '';
    this.city = personal?.city || '';
    this.city_id_card = personal?.city_id_card || '';
    this.country = personal?.country || '';
    this.country_id_card = personal?.country_id_card || '';
    this.county_id_card = personal?.county_id_card || '';
    this.dob = personal?.dob || '';
    this.edu_degree = personal?.edu_degree || '';
    this.work_experience = personal?.work_experience || 0;
    this.lbo = personal?.lbo || '';
    this.marriage_status = personal?.marriage_status || '';
    this.personal_email = personal?.personal_email || '';
    this.phone = personal?.phone || '';
    this.pin = personal?.pin || '';
    this.religious_date = personal?.religious_date || '';
    this.passport_number = personal?.passport_number || '';
  }
}

export type PersonalDataForm = PersonalData & { work_experience_months: number, work_experience_years: number };

export type PersonalDataResponse = any & { errors?: Record<keyof PersonalData, string[]> };
