import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../constants/QueryKey';
import { MealsService } from '../../../services/api/MealsService';

export function useMeal(id: string) {
  const {
    data, isError, error, isLoading,
  } = useQuery(
    [QueryKey.SINGLE_MEAL, id],
    () => MealsService.GetOne(id!),
  );

  return {
    data,
    isError,
    error,
    isLoading,
  };
}
