import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  ButtonGroup,
  IconButton,
  CardActions,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  isWeekend, isBefore, isToday, format,
} from 'date-fns';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import {
  ReactNode, useEffect, useState,
} from 'react';
import { useCalendar } from '../../../framework/hooks/useCalendar';
import { formatMonthName } from '../../../utils/dateHelper';

type Props = {
  getRenderForDay?: (day: Date) => ReactNode;
  getRenderForDayTitle?: (day: Date) => ReactNode;
  getRenderForDayActions?: (day: Date) => ReactNode;
  onDateClick?: (date: Date) => void;
  loading?: boolean;
  disableWeekends?: boolean;
  disableBeforeToday?: boolean;
  getRenderForWeekActions?: (days: Date[]) => ReactNode;
  getDayBackgroundColor?: (day: Date) => string;
  getRenderForMonthActions?: (month: number) => void;
} & ReturnType<typeof useCalendar>;

const weekendWidth = 80;

export function Calendar({
  getRenderForDay,
  getRenderForDayTitle,
  getRenderForDayActions,
  onDateClick,
  loading,
  today,
  view,
  currentMonth,
  days,
  changeStart,
  setViewToMonth,
  setViewToWeek,
  disableWeekends,
  disableBeforeToday,
  getRenderForWeekActions,
  getDayBackgroundColor,
  getRenderForMonthActions,
}: Props) {
  const handleDayClick = (day: Date) => {
    if (onDateClick) {
      if (!(disableWeekends && isWeekend(day)) && !(disableBeforeToday && isBefore(day, today))) {
        onDateClick(day);
      }
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const goThroughCalendar = (direction: number) => {
    changeStart(direction);
    if (mobile) {
      scrollToTop();
    }
  };
  const [todayEl, setTodayEl] = useState<Element | null>(null);
  useEffect(() => {
    if (days?.find((d) => isToday(d))) {
      const el = document.querySelector('.today');
      setTodayEl(el);
    }
  }, [days]);
  useEffect(() => {
    const y = todayEl?.getBoundingClientRect().top || 200;
    window.scrollTo({
      top: y - 200,
      left: 0,
      behavior: 'smooth',
    });
  }, [todayEl]);
  return (
    <Box sx={{
      display: 'flex', flexWrap: 'wrap', gap: '20px', position: 'relative', px: { xs: 0, md: 8 }, pb: { xs: 6, md: 0 },
    }}
    >
      <Box sx={{
        display: 'flex', flexWrap: 'wrap', width: '100%',
      }}
      >
        <Card sx={{ width: '100%', borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
          <CardContent sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: { xs: 'wrap', md: 'nowrap' },
            }}
            >
              <Box sx={{
                display: 'flex', alignItems: 'center', gap: '30px', flex: '1',
              }}
              >
                <ButtonGroup variant="contained">
                  <IconButton type="button" onClick={() => changeStart(-1)} color="primary"><KeyboardArrowLeftIcon /></IconButton>
                  <IconButton type="button" onClick={() => changeStart(1)} color="primary"><KeyboardArrowRightIcon /></IconButton>
                </ButtonGroup>
                {view === 'month' && (getRenderForMonthActions?.(currentMonth) || null)}
              </Box>
              <Typography
                variant="h5"
                sx={{
                  order: { xs: '-1', md: '0' },
                  width: { xs: '100%', md: 'auto' },
                  mb: { xs: '10px', md: '0' },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                {formatMonthName(currentMonth)}
              </Typography>

              <Box sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonGroup variant="contained">
                  <Button
                    type="button"
                    onClick={setViewToWeek}
                    startIcon={<ViewWeekIcon />}
                    variant="contained"
                    color={view === 'week' ? 'primary' : 'secondary'}
                    sx={{ pointerEvents: view === 'week' ? 'none' : 'all', color: view === 'week' ? 'white' : 'primary.main' }}
                  >
                    Week
                  </Button>
                  <Button
                    type="button"
                    onClick={setViewToMonth}
                    startIcon={<CalendarViewMonthIcon />}
                    variant="contained"
                    color={view === 'month' ? 'primary' : 'secondary'}
                    sx={{ pointerEvents: view === 'month' ? 'none' : 'all', color: view === 'month' ? 'white' : 'primary.main' }}
                  >
                    Month
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </CardContent>
          <Box sx={{ width: '100%', height: '5px' }}>{loading && <LinearProgress sx={{ width: '100%' }} />}</Box>
        </Card>
        <Box sx={{
          display: 'flex', flexDirection: 'column', width: '100%',
        }}
        >
          <Box sx={{
            display: { xs: 'none', md: 'flex' }, flex: '1', width: '100%',
          }}
          >
            {Array.from({ length: 7 }).map((_, index: number) => (
              <Card
                sx={{ width: index > 4 ? `${weekendWidth}px` : `calc((100% - ${2 * weekendWidth}px) / 5)`, borderRadius: '0px' }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <CardHeader title={format(days[index], 'E')} disableTypography sx={{ fontWeight: '500' }} />
              </Card>
            ))}
          </Box>
          <Box sx={{
            display: 'flex', flexWrap: 'wrap', flex: '1',
          }}
          >
            {Array.from({ length: Math.ceil(days.length / 7) }).map((_, index: number) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: { xs: 'wrap', md: 'nowrap' },
                  width: '100%',
                  position: 'relative',
                  '&:hover': {
                    '.weekActions': {
                      display: 'block',
                      fontWeight: 600,
                    },
                  },
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Box sx={{
                  position: { xs: 'static', md: 'absolute' },
                  left: 0,
                  top: '50%',
                  transform: { xs: 'none', md: 'translate(-100%, -50%)' },
                  height: { xs: 'auto', md: '100%' },
                  width: { xs: '100%', md: 'auto' },
                }}
                >
                  <Box
                    className="weekActions"
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      height: '100%',
                      width: {
                        xs: '100%',
                        md: 'auto',
                      },
                      bgcolor: { xs: 'secondary.main', md: 'transparent' },
                      boxShadow: {
                        xs: 1,
                        md: 0,
                      },
                    }}
                  >
                    {getRenderForWeekActions?.(days.slice(index * 7, index * 7 + 7))}
                  </Box>
                </Box>

                {days.slice(index * 7, index * 7 + 7).map((day) => {
                  const disabled = (disableWeekends && isWeekend(day)) || (disableBeforeToday && isBefore(day, today));
                  const disabledStyles = disabled ? {
                    filter: 'grayscale(1)',
                    cursor: 'not-allowed',
                  } : {};
                  return (
                    <Card
                      sx={{
                        width: { xs: '100%', md: isWeekend(day) ? `${weekendWidth}px` : `calc((100% - ${2 * weekendWidth}px) / 5)` },
                        minHeight: { xs: isWeekend(day) ? '0px' : '200px', md: '200px' },
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        borderRadius: 0,
                        outlineOffset: '-2px',
                        outline: isToday(day) ? '2px solid #7169FF' : '0px solid transparent',
                        pb: { xs: 3, md: 'initial' },
                        '&:hover': {
                          outline: '2px solid #7169FF',
                          '& .dayActions': {
                            opacity: 1,
                          },
                        },
                      }}
                      className={isToday(day) ? 'today' : ''}
                      key={day.valueOf()}
                    >
                      <Box sx={(muiTheme) => ({
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        bgcolor: isWeekend(day) ? 'background.default' : getDayBackgroundColor?.(day),
                        opacity: muiTheme.palette.mode === 'dark' ? '0.2' : '0.1',
                      })}
                      />
                      <Divider sx={{ display: { xs: 'block', md: 'none' } }} />
                      <CardHeader
                        sx={{ bgcolor: { md: 'secondary.main' }, boxShadow: 1 }}
                        title={(
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ display: { xs: 'none', md: 'inline', fontWeight: '500' } }}>{format(day, 'dd')}</Typography>
                            <Typography sx={{ display: { xs: 'inline', md: 'none', fontWeight: '500' } }}>
                              {isToday(day) ? 'Today' : format(day, 'EEE, dd MMM')}
                            </Typography>
                            {getRenderForDayTitle?.(day)}
                          </Box>
                        )}
                        disableTypography
                      />
                      <CardContent
                        sx={{
                          p: 1, flex: 1, cursor: 'pointer', ...disabledStyles,
                        }}
                        onClick={() => handleDayClick(day)}
                      >
                        {getRenderForDay?.(day)}
                      </CardContent>
                      <CardActions>
                        <Box className="dayActions" sx={{ width: '100%', opacity: 1 }}>{getRenderForDayActions?.(day)}</Box>
                      </CardActions>
                    </Card>
                  );
                })}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          display: {
            xs: 'flex',
            md: 'none',
          },
          flexDirection: 'column',
          bgcolor: 'secondary.main',
          zIndex: 10,
        }}
      >
        {view === 'month' && (
          <Box sx={{
            display: {
              xs: 'flex', md: 'none', padding: '10px 0', justifyContent: 'center', width: '100%',
            },
          }}
          >
            {getRenderForMonthActions?.(currentMonth) || null}
          </Box>
        )}
        <Box sx={{
          display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', p: 1,
        }}
        >
          <ButtonGroup variant="contained">
            <Button
              type="button"
              onClick={setViewToWeek}
              variant="contained"
              color={view === 'week' ? 'primary' : 'secondary'}
              sx={{ pointerEvents: view === 'week' ? 'none' : 'all' }}
            >
              <ViewWeekIcon />
            </Button>
            <Button
              type="button"
              onClick={setViewToMonth}
              variant="contained"
              color={view === 'month' ? 'primary' : 'secondary'}
              sx={{ pointerEvents: view === 'month' ? 'none' : 'all' }}
            >
              <CalendarViewMonthIcon />
            </Button>
          </ButtonGroup>
          <Typography
            variant="h6"
          >
            {formatMonthName(currentMonth)}
          </Typography>
          <ButtonGroup variant="contained">
            <Button
              type="button"
              onClick={() => {
                goThroughCalendar(-1);
              }}
              color="secondary"
            >
              <KeyboardArrowLeftIcon />
            </Button>
            <Button
              type="button"
              onClick={() => {
                goThroughCalendar(1);
              }}
              color="secondary"
            >
              <KeyboardArrowRightIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Box>
  );
}
