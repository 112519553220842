import { PersonalData } from '../framework/types/PersonalData';

export function getAddressString(personal: PersonalData, keys: (keyof PersonalData)[]) {
  const addressArray: (string | number | null)[] = [];
  keys.forEach((field) => {
    if (personal[field]) {
      addressArray.push(personal[field]);
    }
  });
  const string = addressArray.join(', ');
  return string;
}
