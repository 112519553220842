import GroupIcon from '@mui/icons-material/Group';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ListIcon from '@mui/icons-material/List';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NoFoodIcon from '@mui/icons-material/NoFood';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Roles } from '../../../framework/constants/roles';
import { Role } from '../../../framework/types/Role';

export type MenuItemType = {
  label: string;
  href?: string;
  icon: React.ReactElement;
  children?: MenuItemType[];
  authorize?: Role[];
};

export const menuItems: MenuItemType[] = [
  {
    label: 'Users',
    href: '/admin/users',
    icon: <GroupIcon />,
    authorize: [Roles.ADMIN],
  },
  {
    label: 'Calendar',
    href: '/calendar',
    icon: <CalendarMonthIcon />,
  },
  {
    label: 'Checkins',
    href: '/admin/checkins',
    icon: <DoneAllIcon />,
    authorize: [Roles.ADMIN],
  },
  {
    label: 'Menus',
    href: '/admin/menus',
    icon: <ListIcon />,
    authorize: [Roles.ADMIN, Roles.MEAL_PROVIDER],
  },
  {
    label: 'Meals',
    href: '/admin/meals',
    icon: <FastfoodIcon />,
    authorize: [Roles.ADMIN, Roles.MEAL_PROVIDER],
  },
  {
    label: 'Allergens',
    href: '/admin/allergens',
    icon: <NoFoodIcon />,
    authorize: [Roles.ADMIN, Roles.MEAL_PROVIDER],
  },
];
