import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import {
  Box, IconButton, Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import Typography from '@mui/material/Typography';
// import Badge from '@mui/material/Badge';
import { useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation } from 'react-router-dom';
import { AvatarMenu } from '../molecules/navigation/AvatarMenu';
import { useTitleStore } from '../../framework/store/TitleStore';
import { usePortalStore } from '../../framework/store/PortalStore';
import ThemeSwitch from '../atoms/ThemeSwitch';

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<MuiAppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export function AppToolbar({ toggle, isUser }: { toggle: () => void, isUser?: boolean }) {
  const location = useLocation();
  const { title } = useTitleStore();
  const portalStore = usePortalStore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => portalStore.setToolbarElement(), []);
  return (
    <AppBar position="fixed">
      <Toolbar>
        {!isUser && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggle}
          >
            <MenuIcon />
          </IconButton>
        )}
        {isUser && !location.pathname.includes('/calendar') && (
          <IconButton
            component={Link}
            to="/calendar"
            color="inherit"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, ml: 2 }}
        >
          Symphony Operations
          {`${title ? ' | ' : ''}`}
          {title}
        </Typography>

        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <div id="toolbar-portal" />
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <ThemeSwitch />
          </Box>
          <AvatarMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
