import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { ResponsivePortalButton } from './ResponsivePortalButton';
import { LoadingIconButton } from './LoadingIconButton';

export function ResponsiveAddNewButton({ onClick, to }: { onClick?: () => void, to?: string }) {
  if (to) {
    return (
      <ResponsivePortalButton
        small={(
          <IconButton component={Link} to={to} color="primary">
            <AddIcon color="secondary" />
          </IconButton>
        )}
        large={(
          <Button component={Link} to={to} variant="contained" endIcon={<AddIcon />} color="secondary">Add New</Button>
        )}
      />
    );
  }

  if (onClick) {
    return (
      <ResponsivePortalButton
        small={(
          <LoadingIconButton onClick={() => onClick()} color="primary">
            <AddIcon color="secondary" />
          </LoadingIconButton>
        )}
        large={(
          <Button onClick={() => onClick()} variant="contained" endIcon={<AddIcon />} color="secondary">Add New</Button>
        )}
      />
    );
  }
  return null;
}
