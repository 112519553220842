import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import { Tooltip } from '@mui/material';
import { GenderTypes } from '../../framework/constants/GenderOptions';

export function GenderIcon({ gender }: { gender: 'male' | 'female' | '' }) {
  if (gender === GenderTypes.male.value) {
    return (
      <Tooltip title={GenderTypes.male.label}>
        <ManIcon />
      </Tooltip>
    );
  } if (gender === GenderTypes.female.value) {
    return (
      <Tooltip title={GenderTypes.female.label}>
        <WomanIcon />
      </Tooltip>
    );
  }
  return null;
}
