import { useMutation, useQueryClient } from '@tanstack/react-query';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersService } from '../../../services/api/UsersService';
import { User, UserBasicInfoRequest } from '../../../types/User';
import { useBasicInfoSchema } from './useBasicInfoSchema';
import { parseResponseFormErrors } from '../../../../utils/responseFormErrorHelper';

export function useBasicInfoUpdate(user: User, closeMode?: () => void) {
  const {
    register, handleSubmit, errors, setValue, control, setError, reset,
  } = useBasicInfoSchema(user);

  const queryClient = useQueryClient();

  const {
    isLoading, mutate: update, // isError: isUpdateError,
  } = useMutation(
    (formData: UserBasicInfoRequest) => {
      const started_at = standardFormatFromDate(new Date(formData.started_at));

      const ended_at = formData.ended_at && standardFormatFromDate(new Date(formData.ended_at));
      const preparedData = {
        ...formData, started_at, ended_at,
      };
      if (!formData.nickname) {
        delete preparedData.nickname;
      }
      return UsersService.UpdateBasicInfo(user.id!, preparedData);
    },
    {
      onSuccess: (responseData) => {
        if (responseData.success) {
          if (responseData?.data) {
            queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
            if (closeMode) closeMode();
          }
        } else {
          parseResponseFormErrors(responseData?.data?.errors, setError);
        }
      },
    },
  );

  const onSubmit = handleSubmit((formData) => update(formData));

  return {
    isLoading, update, register, onSubmit, errors, setValue, control, reset,
  };
}
