import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { MIN_CHAR, validations } from '../../../../utils/validationHelper';
import {
  GenericFirstCharacterNoNumber, GenericNoSpecialCharacters, GenericTrimmedRequiredString, getGenericTrimmedRequiredString,
} from '../../../../utils/zodHelper';
import { MealRequest } from '../../../types/MealRequest';

export function useMealSchema(item?: MealRequest) {
  const allergenSchema = z.object({
    id: z.number(),
    name: z.string(),
  });
  const schema = z.object({
    name: GenericTrimmedRequiredString
      .refine(GenericFirstCharacterNoNumber, { message: validations.FIRST_CHAR_NUMBER })
      .refine(GenericNoSpecialCharacters, { message: validations.NO_SPEC }),
    description: z.string().trim().optional()
      .refine(GenericFirstCharacterNoNumber, { message: validations.FIRST_CHAR_NUMBER })
      .refine(GenericNoSpecialCharacters, { message: validations.NO_SPEC })
      .refine((val?: string) => getGenericTrimmedRequiredString(1, val), { message: MIN_CHAR(2) }),
    meal_type_id: z.number().min(1, validations.REQUIRED_FIELD).or(z.string().min(1, validations.REQUIRED_FIELD)),
    allergen_ids: z.array(allergenSchema),
  });

  const {
    register, handleSubmit, formState: { errors }, setValue, getValues, control, reset, setError,
  } = useForm<MealRequest>({
    resolver: zodResolver(schema),
    defaultValues: item,
  });

  return {
    register, handleSubmit, errors, setValue, getValues, control, reset, setError,
  };
}
