import { SingleDateResponse } from '../framework/types/Calendar';
import { SimpleUser } from '../framework/types/User';

const sortSimpleUsersByFirstName = (a: SimpleUser, b: SimpleUser) => {
  const nameA = a.first_name.toUpperCase();
  const nameB = b.first_name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortUserCheckins = (checkins?: SingleDateResponse['checkins']) => {
  const resObj: Record<string, SimpleUser[]> = {};
  if (checkins) {
    Object.entries(checkins).forEach(([key, value]) => {
      resObj[key] = value.users.sort((a: SimpleUser, b: SimpleUser) => sortSimpleUsersByFirstName(a, b));
    });
  }
  return resObj;
};

const searchSimpleUserFirstLastName = (users: SimpleUser[], search: string) => {
  const lowerCaseSearch = search.toLowerCase();
  const res = users.filter((item: SimpleUser) => {
    const lowerCaseFirstName = item.first_name.toLowerCase();
    const lowerCaseLastName = item.last_name.toLowerCase();
    return lowerCaseFirstName.includes(lowerCaseSearch) || lowerCaseLastName.includes(lowerCaseSearch);
  });
  return res;
};

export const searchUserCheckins = (search: string, checkins?: Record<string, SimpleUser[]>) => {
  const resObj: Record<string, SimpleUser[]> = {};
  if (checkins) {
    Object.entries(checkins).forEach(([key, value]) => {
      resObj[key] = searchSimpleUserFirstLastName(value, search);
    });
  }
  return resObj;
};
