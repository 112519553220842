import { Box, Skeleton, CircularProgress } from '@mui/material';

export function UserProfileSkeleton() {
  return (
    <Box sx={{
      display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '30px', width: { xs: '100%', md: 'auto', alignItems: 'center' },
    }}
    >
      <Box sx={{
        width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="text" width={180} height={20} />
        <Skeleton variant="text" width={80} height={20} />
      </Box>
    </Box>
  );
}
