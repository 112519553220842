import {
  CircularProgress, SxProps, Box, PropTypes, Fab,
} from '@mui/material';
import { ReactNode } from 'react';

export function LoadingIconButton({
  onClick, isLoading, children, disabled, sx, color,
}: { onClick: () => void, isLoading?: boolean, children: ReactNode, disabled?: boolean, sx?: SxProps, color: PropTypes.Color }) {
  return (
    <Fab onClick={onClick} disabled={disabled} sx={sx} color={color}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {!isLoading && children}
        {isLoading && <CircularProgress />}
      </Box>
    </Fab>
  );
}
