import {
  Card, CardContent, CardHeader, Skeleton,
} from '@mui/material';

export function MealFormSkeleton() {
  return (
    <Card>
      <CardHeader title={(
        <Skeleton variant="text" width={300} height={70} />
      )}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Skeleton variant="text" height={48} />
        <Skeleton variant="text" height={48} />
        <Skeleton variant="text" height={140} />
        <Skeleton variant="text" height={48} />
      </CardContent>
    </Card>
  );
}
