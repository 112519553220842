import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { differenceInMonths, differenceInYears } from 'date-fns';
import { StyledLink } from '../../components/molecules/StyledLink';
import { FamilyTable } from '../../components/organisms/users/FamilyTable';
import { User } from '../types/User';
import { Relationships, RelationStatuses } from './RelationshipOptions';
// import { GenderTypes } from './GenderOptions';
import { expandWorkExperience } from '../../utils/workExperienceHelper';
import { prettyFormatFromDate } from '../../utils/dateHelper';
import { PeopleDataColumn } from '../../components/organisms/users/PeopleDataColumn';
import { FamilyMember } from '../types/FamilyMember';
import { Vehicle } from '../types/Vehicles';
import { userTableFields } from './UserTableColumnFields';
import { getAddressString } from '../../utils/addressHelper';

type GridGetterUser = GridValueGetterParams<User>;

export const UsersTableColumns: GridColDef<User>[] = [
  {
    field: userTableFields.ID, headerName: 'ID', filterable: false, valueGetter: (params: GridGetterUser) => params.row.core_tool_user_id,
  },
  {
    field: userTableFields.EMPLOYEE,
    headerName: 'Employee',
    width: 200,
    filterable: false,
    renderCell: (params: GridRenderCellParams<User>) => (
      <StyledLink to={`/admin/users/${params.row.id}`}>{`${params.row.first_name || ''} ${params.row.last_name || ''}`}</StyledLink>
    ),
    hideable: false,
  },
  {
    field: userTableFields.JOB_TITLE,
    headerName: 'Job Title',
    width: 200,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.position?.name,
  },
  {
    field: userTableFields.LBO,
    headerName: 'LBO',
    width: 200,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.lbo && `${params.row.personal.lbo}`,
  },
  {
    field: userTableFields.PASSPORT,
    headerName: 'Passport Number',
    width: 200,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.passport_number && `${params.row.personal.passport_number}`,
  },
  {
    field: userTableFields.PHONE,
    headerName: 'Phone',
    width: 200,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.phone && `${params.row.personal.phone}`,
  },
  {
    field: userTableFields.ACTUAL_ADDRESS,
    headerName: 'Actual Address',
    width: 300,
    sortable: false,
    filterable: false,
    valueGetter:
      (params: GridGetterUser) => {
        const { personal } = params.row;
        const arr: (keyof GridGetterUser['row']['personal'])[] = ['address', 'city', 'country'];
        return getAddressString(personal, arr);
      },
  },
  {
    field: userTableFields.ADDRESS,
    headerName: 'Address',
    width: 300,
    sortable: false,
    filterable: false,
    valueGetter:
      (params: GridGetterUser) => {
        const { personal } = params.row;
        const arr: (keyof GridGetterUser['row']['personal'])[] = ['address_id_card', 'city_id_card', 'county_id_card', 'country_id_card'];
        return getAddressString(personal, arr);
      },
  },
  {
    field: userTableFields.STARTED_AT,
    headerName: 'Contract Started',
    width: 150,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.started_at && prettyFormatFromDate(new Date(params.row.started_at)),
  },
  {
    field: userTableFields.ENDED_AT,
    headerName: 'Contract Ended',
    width: 150,
    filterable: false,
    valueGetter: (params: GridGetterUser) => (params.row.ended_at ? prettyFormatFromDate(new Date(params.row.ended_at)) : 'Indefinite'),
  },
  {
    field: userTableFields.EDUCATION,
    headerName: 'Education Level',
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.edu_degree && `${params.row.personal.edu_degree}`,
  },
  // {
  //   field: userTableFields.GENDER,
  //   sortable: false,
  //   filterable: false,
  //   headerName: 'Gender',
  //   valueGetter: (params: GridGetterUser) => params.row.gender && GenderTypes[params.row.gender].label,
  // },
  {
    field: userTableFields.DOB,
    headerName: 'Date of birth',
    width: 150,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.dob && prettyFormatFromDate(new Date(params.row.personal.dob)),
  },
  {
    field: userTableFields.AGE,
    headerName: 'Age',
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.dob && differenceInYears(new Date(), new Date(params.row.personal.dob)),
  },
  {
    field: userTableFields.PIN,
    headerName: 'Unique Citizens Identity Number',
    width: 200,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.pin && `${params.row.personal.pin}`,
  },
  {
    field: userTableFields.BANK,
    headerName: 'Bank Account',
    width: 200,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.bank_account && `${params.row.personal.bank_account}`,
  },
  {
    field: userTableFields.EMAIL,
    headerName: 'Email Address',
    width: 200,
    filterable: false,
  },
  {
    field: userTableFields.WORK_EXP,
    headerName: 'Work Experience before Symphony',
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => {
      const we = expandWorkExperience(params.row.personal.work_experience);
      return params.row.personal.work_experience && `${we.work_experience_years}y ${we.work_experience_months}m`;
    },
  },
  {
    field: userTableFields.WORK_EXP_SYM,
    headerName: 'Work Experience at Symphony',
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => {
      const months = params.row.started_at ? differenceInMonths(new Date(), new Date(params.row.started_at)) : 0;
      const we = expandWorkExperience(months);
      return params.row.personal.work_experience && params.row.started_at && `${we.work_experience_years}y ${we.work_experience_months}m`;
    },
  },
  {
    field: userTableFields.WORK_EXP_TOTAL,
    headerName: 'Total Work Experience',
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => {
      const before = params.row.personal.work_experience || 0;
      const at = params.row.started_at ? differenceInMonths(new Date(), new Date(params.row.started_at)) : 0;
      const we = expandWorkExperience(at + before);
      return params.row.personal.work_experience && params.row.started_at && `${we.work_experience_years}y ${we.work_experience_months}m`;
    },
  },
  {
    field: userTableFields.REL_DATE,
    headerName: 'Slava Day',
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.religious_date && `${params.row.personal.religious_date}`,
  },
  {
    field: userTableFields.NICKNAME, headerName: 'Nickname', width: 150, filterable: false, sortable: false,
  },
  {
    field: userTableFields.STATUS,
    headerName: 'Status',
    width: 150,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.personal.marriage_status && RelationStatuses[params.row.personal.marriage_status].label,
  },
  {
    field: userTableFields.PARTNER_NAME,
    headerName: 'Partner Name',
    width: 200,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn
        members={params.row.family
          .filter((f) => f.relationship_type === Relationships.PARTNER.value || f.relationship_type === Relationships.SPOUSE.value)}
        getRenderForFieldValue={(f) => `${f.first_name} ${f.last_name}`}
      />
    ),
  },
  {
    field: userTableFields.PARTNER_DOB,
    headerName: 'Partner Date of Birth',
    width: 150,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn
        members={params.row.family
          .filter((f) => f.relationship_type === Relationships.PARTNER.value || f.relationship_type === Relationships.SPOUSE.value)}
        field="dob"
      />
    ),
  },
  {
    field: userTableFields.EC_NAME,
    headerName: 'Emergency Contact',
    width: 200,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn<FamilyMember>
        members={params.row.family
          .filter((f) => f.is_emergency_contact)}
        getRenderForFieldValue={(f) => `${f.first_name} ${f.last_name}`}
      />
    ),
  },
  {
    field: userTableFields.EC_PHONE,
    headerName: 'Emergency Contact Phone',
    width: 200,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn<FamilyMember>
        members={params.row.family
          .filter((f) => f.is_emergency_contact)}
        field="phone"
      />
    ),
  },
  {
    field: userTableFields.NUM_CHILDREN,
    headerName: 'Number of Children',
    width: 100,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridGetterUser) => params.row.family.filter((f) => f.relationship_type === Relationships.CHILD.value).length,
  },
  {
    field: userTableFields.CHILDREN,
    headerName: 'Children',
    width: 500,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <FamilyTable members={params.row.family.filter((f) => f.relationship_type === Relationships.CHILD.value)} />
    ),
  },
  {
    field: userTableFields.VEHICLE,
    headerName: 'Vehicles',
    width: 150,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn<Vehicle>
        members={params.row.vehicles}
        field="model"
      />
    ),
  },
  {
    field: userTableFields.FUEL_TYPE,
    headerName: 'Fuel Type',
    width: 150,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn<Vehicle>
        members={params.row.vehicles}
        field="fuel_type"
      />
    ),
  },
  {
    field: userTableFields.AVG_CONSUMPTION,
    headerName: 'Average Consumption',
    width: 150,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn<Vehicle>
        members={params.row.vehicles}
        field="avg_consumption"
      />
    ),
  },
  {
    field: userTableFields.REG_NUMBER,
    headerName: 'Registration Number',
    width: 150,
    sortable: false,
    filterable: false,
    cellClassName: 'no-padding',
    renderCell: (params: GridRenderCellParams<User>) => (
      <PeopleDataColumn<Vehicle>
        members={params.row.vehicles}
        field="reg_number"
      />
    ),
  },
];
