import QueryString from 'qs';
// eslint-disable-next-line import/no-cycle
import { RefreshWrapper } from './fetch';
import { ParamObject } from './ParamObject';

const credentials: RequestInit = {
  credentials: 'include',
};

const headers = () => {
  const headersObj: HeadersInit = {
    'Content-Type': 'application/json',
  };
  return headersObj;
};

export const http = {
  get(url: string, params?: ParamObject) {
    const stringifiedQuery = QueryString.stringify(
      params,
      { addQueryPrefix: true },
    );
    let finalUrl = url;
    if (stringifiedQuery) {
      finalUrl = `${url}${stringifiedQuery}`;
    }
    const options = {
      method: 'GET',
      headers: headers(),
    };
    return RefreshWrapper(finalUrl, { ...options, ...credentials });
  },
  post<D>(url: string, data: D) {
    const options = {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify(data),
    };
    return RefreshWrapper(url, { ...options, ...credentials });
  },
  patch<D>(url: string, data: D) {
    const options = {
      method: 'PATCH',
      headers: headers(),
      body: JSON.stringify(data),
    };
    return RefreshWrapper(url, { ...options, ...credentials });
  },
  put<D>(url: string, data: D) {
    const options = {
      method: 'PUT',
      headers: headers(),
      body: JSON.stringify(data),
    };
    return RefreshWrapper(url, { ...options, ...credentials });
  },
  delete(url: string) {
    const options = {
      method: 'DELETE',
      headers: headers(),
    };
    return RefreshWrapper(url, { ...options, ...credentials });
  },
};
