import { Allergen } from './Allergens';
import { Meal } from './Meals';

export class MealRequest {
  name: string;

  description?: string;

  meal_type_id: number | '';

  allergen_ids: Allergen[];

  constructor(meal?: Meal) {
    this.name = meal?.name || '';
    this.description = meal?.description || '';
    this.meal_type_id = meal?.meal_type.id || '';
    this.allergen_ids = meal?.allergens || [];
  }
}

export type MealResponse = Meal & {
  errors?: Record<keyof MealRequest, string[]>
};
