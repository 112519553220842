import { useMutation, useQuery } from '@tanstack/react-query';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { CalendarService } from '../../../services/api/CaledarService';
import { CheckinAnswerType } from '../../../constants/CheckinAnswers';
import { CheckInsService } from '../../../services/api/CheckInsService';
import { CheckinPostRequest } from '../../../types/Checkin';

export function useCalendar(start: Date, end: Date) {
  const {
    data: calendarData, isError, error, isLoading, isRefetching,
  } = useQuery(
    [QueryKey.CALENDAR, start, end],
    () => {
      const query = { date_from: standardFormatFromDate(start), date_to: standardFormatFromDate(end) };
      return CalendarService.GetAll(query);
    },
    { keepPreviousData: true },
  );

  const {
    isLoading: createLoading, isError: isCreateError, mutate: create, variables: createVariables,
  } = useMutation(
    (formData: CheckinPostRequest) => CheckInsService.Create(formData),
    {
      onSuccess: (data) => {
        if (data?.data) {
          if (calendarData?.data?.data) {
            calendarData.data.data = { ...calendarData.data.data, ...data.data };
          }
        }
      },
    },
  );
  const {
    isLoading: updateLoading, isError: isUpdateError, mutate: update, variables: updateVariables,
  } = useMutation(
    ({ id, formData }: { id: number, formData: { answer: string, date: string } }) => CheckInsService.Update(id, { answer: formData.answer }),
    {
      onSuccess: (data) => {
        if (data?.data) {
          if (calendarData?.data?.data) {
            calendarData.data.data = { ...calendarData.data.data, ...data.data };
          }
        }
      },
    },
  );
  const updateCheckin = (answer: CheckinAnswerType, date: string, id?: number | null) => {
    if (id) {
      update({ id, formData: { answer, date } });
    } else {
      create({ dates: [date], answer });
    }
  };
  const updateMultipleCheckins = (answer: CheckinAnswerType, dates: string[]) => {
    create({ dates, answer });
  };
  return {
    data: calendarData,
    isLoading,
    isRefetching,
    isError,
    error,
    createLoading,
    createVariables,
    isCreateError,
    updateLoading,
    updateVariables,
    isUpdateError,
    updateCheckin,
    updateMultipleCheckins,
  };
}
