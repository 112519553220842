import {
  Box, TextField,
} from '@mui/material';
import { Roles } from '../../../framework/constants/roles';
import { GenderOptions } from '../../../framework/constants/GenderOptions';
import { useHubs } from '../../../framework/hooks/api/Hubs/useHubs';
import { useRoles } from '../../../framework/hooks/api/Roles/useRoles';
import { useBasicInfoUpdate } from '../../../framework/hooks/api/Users/useBasicInfoUpdate';
import { useHubUpdate } from '../../../framework/hooks/api/Users/useHubUpdate';
import { useProfileEditModes } from '../../../framework/hooks/api/Users/useProfileEditModes';
import { useRoleUpdate } from '../../../framework/hooks/api/Users/useRoleUpdate';
import { Hub, HubRequest } from '../../../framework/types/Hubs';
import { Role, RoleRequest } from '../../../framework/types/Role';
import { User, UserBasicInfoRequest } from '../../../framework/types/User';
import { ControlledSelect } from '../../molecules/ControlledSelect';
import { ActionsGroup } from '../../utils/ActionsGroup';
import { AuthGuard } from '../../utils/AuthGuard';
import { FormSection } from '../../utils/FormSection';
import { usePositions } from '../../../framework/hooks/api/Positions/usePositions';
import { Position } from '../../../framework/types/Position';
import { ControlledDatePicker } from '../../molecules/ControlledDatePicker';
import { useAuthStore } from '../../../framework/store/AuthStore';
import { authGuard } from '../../../utils/authGuardHelper';

export function BasicInfoForm({ user }: { user: User }) {
  const { user: authUser } = useAuthStore();
  const isAdmin = authUser ? authGuard([Roles.ADMIN], authUser.role) : false;

  const {
    basicInfoEditMode, roleEditMode, openBasicInfoEditMode, openRoleEditMode, closeModes,
  } = useProfileEditModes();

  const { data: hubsData, isLoading: isHubsLoading } = useHubs();
  const { data: rolesData, isLoading: isRolesLoading } = useRoles(isAdmin);
  const { data: positionsData, isLoading: isPositionsLoading } = usePositions();
  const {
    isLoading, register, onSubmit, errors, control, reset,
  } = useBasicInfoUpdate(user, closeModes);
  const { control: roleControl, isLoading: isRoleSubmitLoading, onSubmit: onRoleSubmit } = useRoleUpdate(user, closeModes);
  const {
    control: hubControl, onSubmit: onHubSubmit,
  } = useHubUpdate(user, closeModes);
  const cancelEdit = () => {
    reset();
    closeModes();
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <form onSubmit={onSubmit}>
        <FormSection
          title="Basic Info"
          actions={(
            <AuthGuard roles={[Roles.ADMIN]}>
              <ActionsGroup mode={basicInfoEditMode} openMode={openBasicInfoEditMode} closeMode={cancelEdit} submitLoading={isLoading} />
            </AuthGuard>
          )}
        >
          <TextField
            {...register('first_name')}
            label="First Name"
            InputProps={{ readOnly: !basicInfoEditMode }}
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
          />
          <TextField
            {...register('last_name')}
            label="Last Name"
            InputProps={{ readOnly: !basicInfoEditMode }}
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
          />
          <TextField
            {...register('nickname')}
            label="Nickname"
            InputProps={{ readOnly: !basicInfoEditMode }}
            error={!!errors.nickname}
            helperText={errors.nickname?.message}
          />
        </FormSection>
        <FormSection>
          <TextField
            {...register('email')}
            label="Email Address"
            InputProps={{ readOnly: !basicInfoEditMode }}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <ControlledSelect< { value: string, label: string }, UserBasicInfoRequest>
            control={control}
            fieldName="gender"
            options={GenderOptions}
            label="gender"
            readOnly={!basicInfoEditMode}
          />
          <TextField
            type="number"
            {...register('core_tool_user_id')}
            label="Core Tool User ID"
            InputProps={{ readOnly: !basicInfoEditMode, inputProps: { min: 0 } }}
            error={!!errors.core_tool_user_id}
            helperText={errors.core_tool_user_id?.message}
          />
        </FormSection>
        <FormSection title="Contract Info">
          <ControlledDatePicker<UserBasicInfoRequest>
            control={control}
            fieldName="started_at"
            label="Contract Started"
            readOnly={!basicInfoEditMode}
          />
          <ControlledDatePicker<UserBasicInfoRequest>
            control={control}
            fieldName="ended_at"
            label="Contract ended"
            readOnly={!basicInfoEditMode}
          />
          {positionsData?.data?.data && !isPositionsLoading && (
            <ControlledSelect< Position, UserBasicInfoRequest>
              control={control}
              fieldName="position_id"
              options={positionsData?.data?.data || []}
              label="Job Title"
              readOnly={!basicInfoEditMode}
              isLoading={isPositionsLoading}
            />
          )}
        </FormSection>
      </form>
      <AuthGuard roles={[Roles.ADMIN]}>
        <form onSubmit={onRoleSubmit}>
          <FormSection
            title="Role Info"
            actions={(
              <ActionsGroup mode={roleEditMode} openMode={openRoleEditMode} closeMode={closeModes} submitLoading={isRoleSubmitLoading} />
            )}
          >
            {rolesData?.data?.data && !isRolesLoading && (
              <ControlledSelect<Role, RoleRequest>
                control={roleControl}
                fieldName="role_id"
                options={rolesData?.data?.data || []}
                label="Role"
                readOnly={!roleEditMode}
                isLoading={isRolesLoading}
              />
            )}
          </FormSection>
        </form>
      </AuthGuard>
      <form onSubmit={onHubSubmit}>
        <FormSection
          title="Hub Info"
        // actions={(
        //   <AuthGuard roles={[Roles.ADMIN]}>
        //     <ActionsGroup mode={hubEditMode} openMode={openHubEditMode} closeMode={closeModes} submitLoading={isHubSubmitLoading} />
        //   </AuthGuard>
        // )}
        >
          {hubsData?.data?.data && !isHubsLoading && (
            <ControlledSelect<Hub, HubRequest>
              control={hubControl}
              fieldName="hub_id"
              options={hubsData?.data?.data || []}
              label="Hub"
              readOnly
              isLoading={isHubsLoading}
            />
          )}
        </FormSection>
      </form>
    </Box>
  );
}
