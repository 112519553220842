import {
  Box, Card, CardHeader, CardContent, Dialog,
} from '@mui/material';
import { useAllergens } from '../../../framework/hooks/api/Allergens/useAllergens';
import { useCreateOrUpdateAllergens } from '../../../framework/hooks/api/Allergens/useCreateOrUpdateAllergens';
import { useDeleteAllergens } from '../../../framework/hooks/api/Allergens/useDeleteAllergens';
import { ResponsiveAddNewButton } from '../../atoms/ResponsiveAddNewButton';
import { Title } from '../../atoms/Title';
import { AllergenForm } from '../../organisms/allergens/AllergenForm';
import { AllergensList } from '../../organisms/allergens/AllergensList';
import { ConfirmationModal } from '../../utils/ConfirmationModal';
import { FetchUi } from '../../utils/FetchUi';
import { AllergensSkeleton } from '../../utils/skeletons/AllergensSkeleton';

export function AllergensPage() {
  const {
    data, isLoading, ListPagination, SearchField, per_page,
  } = useAllergens();
  const dialogContext = useCreateOrUpdateAllergens();
  const { openDialogMode, dialogMode, closeDialogMode } = dialogContext;
  const {
    deleteMode, closeDeleteMode, openDeleteMode, deleteAllergen, deleteModeItem, deleteLoading,
  } = useDeleteAllergens();

  return (
    <Box sx={{ px: { xs: 0, md: 8 }, maxWidth: '100%' }}>
      <Title title="Allergens" />
      <Card>
        <CardHeader title={(
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: { xs: '100%', md: 'auto' } }}>
            <Box sx={{ display: 'flex', width: { xs: '100%', md: 'auto' } }}>
              {SearchField}
            </Box>
            {/* <Box sx={{ display: 'flex' }}>
              {Sort}
            </Box> */}
          </Box>
        )}
        />
        <CardContent>
          <Box>
            <FetchUi isLoading={isLoading} loadingUi={<AllergensSkeleton amount={per_page} />}>
              {data?.data?.data && <AllergensList allergens={data?.data?.data} openDeleteMode={openDeleteMode} openDialogMode={openDialogMode} />}
              {ListPagination}
            </FetchUi>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={dialogMode} onClose={closeDialogMode}><AllergenForm {...dialogContext} /></Dialog>
      <ConfirmationModal
        open={deleteMode}
        cancel={closeDeleteMode}
        confirm={() => deleteAllergen()}
        title="Delete allergen"
        isLoading={deleteLoading}
      >
        {deleteModeItem?.name}
      </ConfirmationModal>
      <ResponsiveAddNewButton onClick={openDialogMode} />
    </Box>
  );
}
