export class FamilyMember {
  dob: string;

  first_name: string;

  gender: 'male' | 'female' | '';

  id: number | undefined;

  is_emergency_contact: boolean;

  last_name: string;

  phone: string;

  relationship_type: string;

  constructor(member?: FamilyMember) {
    this.dob = member?.dob || '';
    this.first_name = member?.first_name || '';
    this.gender = member?.gender || '';
    this.id = member?.id || undefined;
    this.is_emergency_contact = member?.is_emergency_contact || false;
    this.last_name = member?.last_name || '';
    this.phone = member?.phone || '';
    this.relationship_type = member?.relationship_type || '';
  }
}
