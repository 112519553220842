import {
  Box, Button, Dialog, List,
} from '@mui/material';
import { Roles } from '../../../framework/constants/roles';
import { useCreateOrUpdateVehicles } from '../../../framework/hooks/api/Users/useCreateOrUpdateVehicles';
import { useDeleteVehicles } from '../../../framework/hooks/api/Users/useDeleteVehicles';
import { User } from '../../../framework/types/User';
import { VehicleItem } from '../../molecules/users/VehicleItem';
import { AuthGuard } from '../../utils/AuthGuard';
import { ConfirmationModal } from '../../utils/ConfirmationModal';
import { FormSection } from '../../utils/FormSection';
import { VehicleForm } from './VehicleForm';

export function VehiclesData({ user }: { user: User }) {
  const dialogContext = useCreateOrUpdateVehicles(user.id!);
  const { dialogMode, openDialogMode, closeDialogMode } = dialogContext;
  const {
    deleteMode, deleteModeItem, deleteLoading, deleteVehicle, openDeleteMode, closeDeleteMode,
  } = useDeleteVehicles();

  return (
    <Box>
      <FormSection
        title="Vehicles"
        actions={(
          <AuthGuard roles={[Roles.ADMIN]}>
            <Button onClick={() => openDialogMode()}>Add New</Button>
          </AuthGuard>
        )}
      >
        <List sx={{ width: '100%' }} dense disablePadding>
          {user.vehicles.map((vehicle) => (
            <VehicleItem vehicle={vehicle} key={vehicle.id} openEditMode={openDialogMode} openDeleteMode={openDeleteMode} />
          ))}
        </List>
      </FormSection>
      <Dialog open={dialogMode} onClose={closeDialogMode}>
        <VehicleForm {...dialogContext} />
      </Dialog>
      <ConfirmationModal
        open={deleteMode}
        cancel={closeDeleteMode}
        confirm={deleteVehicle}
        isLoading={deleteLoading}
        title="Are you sure you want to delete this family member?"
      >
        {deleteModeItem?.model}
        {' - '}
        {deleteModeItem?.reg_number}
      </ConfirmationModal>
    </Box>
  );
}
