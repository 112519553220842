export const QueryKey = {
  USERS: 'users',
  CURRENT_USER: 'current_user',
  SINGLE_USER: 'single_user',
  USER_CHECK_INS: 'user_check_ins',
  CHECK_INS: 'check_ins',
  MEAL_TYPES: 'meal_types',
  MEALS: 'meals',
  SINGLE_MEAL: 'single_meal',
  MENUS: 'menus',
  CALENDAR: 'calendar',
  SINGLE_DATE: 'single_date',
  ALLERGENS: 'allergens',
  HUBS: 'hubs',
  ROLES: 'roles',
  USER_ALLERGENS: 'user_allergens',
  POSITIONS: 'positions',
} as const;
