import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../framework/store/AuthStore';

export function BaseLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    loggedIn, initialized, initialize, user,
  } = useAuthStore();
  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (initialized && !loggedIn) navigate('/login');
    if (initialized
      && loggedIn
      && (!location.pathname.includes('/admin')
        && !location.pathname.includes('/calendar')
        && !location.pathname.includes('/profile')
        && !location.pathname.includes('/meal/'))) navigate('/calendar');
  }, [initialized, loggedIn, location.pathname, navigate, user]);

  return (
    <Outlet />
  );
}
