import {
  Badge,
  Box, Button, Dialog, DialogContent, DialogTitle, Fab,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { EduDegreeOptions } from '../../../framework/constants/EduDegrees';
import { GenderOptions } from '../../../framework/constants/GenderOptions';
import { RelationshipStatusOptions } from '../../../framework/constants/RelationshipOptions';
import { User } from '../../../framework/types/User';
import { UserQueryFilter } from '../../../framework/types/UsersQueryFilter';
import { ControlledDatePicker } from '../../molecules/ControlledDatePicker';
import { ControlledSelect } from '../../molecules/ControlledSelect';
import UpdatedComboBox from '../../molecules/UpdatedComboBox';
import { FormSection } from '../../utils/FormSection';
import { usePositions } from '../../../framework/hooks/api/Positions/usePositions';
import { Position } from '../../../framework/types/Position';
import { useUsersList } from '../../../framework/hooks/api/Users/useUsersList';
import { useUserFilterSchema } from '../../../framework/hooks/api/Users/useUserFilterSchema';
import { ContractStatusOptions } from '../../../framework/constants/ContractStatus';
import { SearchInput } from '../../atoms/SearchInput';
import { UsersAPIQuery } from '../../../framework/types/UsersAPIQuery';

type Props = {
  onFiltersChange: (newValues: UserQueryFilter) => void,
  onSearchReset: () => void,
  query: UsersAPIQuery,
};

export function UsersFilter({
  onFiltersChange, onSearchReset, query,
}: Props) {
  const [filterDialog, setFilterDialog] = useState(false);
  const {
    control, handleSubmit, reset,
  } = useUserFilterSchema();
  const noOfFilters = query.getNoOfFilters;
  const onSubmit = handleSubmit((formData) => onFiltersChange(formData));

  const {
    data: users, isLoading, setSearch,
  } = useUsersList();

  const { data: positionsData, isLoading: isPositionsLoading } = usePositions();

  const clearFilters = () => {
    const resetQuery = new UserQueryFilter();
    onFiltersChange(resetQuery);
    reset(resetQuery);
  };

  return (
    <Box>
      <form onSubmit={onSubmit} id="users-filter">
        <Box sx={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '10px', gap: '20px',
        }}
        >
          <Box sx={{
            width: '100%',
            display: 'flex',
            gap: '20px',
          }}
          >
            <Controller
              render={({
                field: {
                  onChange, onBlur, value, name, ref,
                }, fieldState: { invalid, error },
              }) => (
                <SearchInput
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  name={name}
                  invalid={invalid}
                  errorText={error?.message}
                  onReset={() => {
                    onChange('');
                    onSearchReset();
                  }}
                />
              )}
              control={control}
              name="search"
            />
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px',
            }}
            >
              <Badge
                color="secondary"
                badgeContent={noOfFilters}
                overlap="circular"
                invisible={noOfFilters === 0}
              >
                <Fab onClick={() => setFilterDialog(true)} color="primary" size="small" sx={{ boxShadow: 'none', position: 'static', zIndex: 1 }}>
                  <FilterAltIcon />
                </Fab>
              </Badge>
              <Box>{noOfFilters > 0 && <Button onClick={clearFilters}>Clear Filters</Button>}</Box>
            </Box>
          </Box>
        </Box>
        <Dialog open={filterDialog} onClose={() => setFilterDialog(false)} maxWidth="md">
          <DialogTitle>Filter</DialogTitle>
          <DialogContent>
            <FormSection title="Contract Started" dense>
              <ControlledDatePicker<UserQueryFilter>
                clearable
                control={control}
                fieldName="started_at_from"
                label="From"
              />
              <ControlledDatePicker<UserQueryFilter>
                clearable
                control={control}
                fieldName="started_at_to"
                label="To"
              />
            </FormSection>
            <FormSection>
              <ControlledSelect<{ value: string, label: string }, UserQueryFilter>
                control={control}
                fieldName="ended_at"
                options={ContractStatusOptions}
                isLoading={false}
                label="Contract Status"
                clearable
              />
            </FormSection>
            <FormSection title="Contract Ended" dense>
              <ControlledDatePicker<UserQueryFilter>
                clearable
                control={control}
                fieldName="ended_at_from"
                label="From"
              />
              <ControlledDatePicker<UserQueryFilter>
                clearable
                control={control}
                fieldName="ended_at_to"
                label="To"
              />
            </FormSection>
            <FormSection title="Date of Birth" dense>
              <ControlledDatePicker<UserQueryFilter>
                clearable
                control={control}
                fieldName="dob_from"
                label="From"
              />
              <ControlledDatePicker<UserQueryFilter>
                clearable
                control={control}
                fieldName="dob_to"
                label="To"
              />
            </FormSection>
            {/* <FormSection>
                <TextField
                  {...register('work_experience_from', { valueAsNumber: true })}
                  label="Work Experience From"
                  error={!!errors.work_experience_from}
                  helperText={errors.work_experience_from?.message}
                />
                <TextField
                  {...register('work_experience_to', { valueAsNumber: true })}
                  label="Work Experience To"
                  error={!!errors.work_experience_from}
                  helperText={errors.work_experience_from?.message}
                />
              </FormSection> */}
            <FormSection>
              <UpdatedComboBox<Position, UserQueryFilter>
                control={control}
                name="position"
                options={positionsData?.data?.data || []}
                isLoading={isPositionsLoading}
                label="Job Title"
              />
            </FormSection>
            <FormSection>
              <UpdatedComboBox<User, UserQueryFilter>
                control={control}
                name="users"
                options={users?.data?.data || []}
                isLoading={isLoading}
                label="Specific Users"
                setSearch={setSearch}
                getOptionsLabel={(option) => `${option.first_name} ${option.last_name}`}
              />
            </FormSection>
            <FormSection>
              <UpdatedComboBox<{ value: string, label: string }, UserQueryFilter>
                control={control}
                name="edu_level"
                options={EduDegreeOptions}
                isLoading={false}
                label="Education Level"
              />
            </FormSection>
            <FormSection>
              <ControlledSelect<{ value: string, label: string }, UserQueryFilter>
                control={control}
                fieldName="has_children"
                // TODO add NO option { label: 'No', value: 'false' }
                options={[{ label: 'Yes', value: 'true' }]}
                label="User Has Children"
                clearable
              />
              <UpdatedComboBox<{ value: string, label: string }, UserQueryFilter>
                control={control}
                name="relationship_status"
                options={RelationshipStatusOptions}
                isLoading={false}
                label="Relationship Status"
              />
              <ControlledSelect<{ value: string, label: string }, UserQueryFilter>
                control={control}
                fieldName="gender"
                options={GenderOptions}
                label="Gender"
                clearable
              />
            </FormSection>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={clearFilters}>Clear Filters</Button>
              <Button type="submit" variant="contained" onClick={() => setFilterDialog(false)} form="users-filter">Apply Filters</Button>
            </Box>
          </DialogContent>
        </Dialog>
      </form>
    </Box>
  );
}
