import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { QueryKey } from '../../../constants/QueryKey';
import { MealsService } from '../../../services/api/MealsService';
import { Meal } from '../../../types/Meals';
import { PaginationQueryParams } from '../../../types/Pagination';
import { useFilter } from '../../useFilter';
import { usePagination } from '../../usePagination';

export function useMeals(keepPreviousData: boolean = true) {
  const [mealTypeFilter, setMealTypeFilter] = useState<number | undefined>();
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteModeItem, setDeleteModeItem] = useState<Meal | null>(null);

  const {
    page, per_page, ListPagination, setCount, setPage,
  } = usePagination();
  const mealSortProps = [{ label: 'Name', value: 'name' }];
  const {
    sortBy, direction, Sort, SearchField, search, setSearch,
  } = useFilter(mealSortProps);

  useEffect(() => {
    setPage(1);
  }, [sortBy, direction, search, mealTypeFilter, setPage, per_page]);

  const {
    data, isError, error, isLoading, isRefetching,
  } = useQuery(
    [QueryKey.MEALS, page, per_page, sortBy, direction, search, mealTypeFilter],
    () => {
      const query: PaginationQueryParams = {
        page, size: per_page, 'sort[field]': `${sortBy}`, 'sort[type]': direction,
      };
      if (search) {
        query['search[name]'] = search;
      }
      if (mealTypeFilter) {
        query['filter[meal_type_id]'] = `${mealTypeFilter}`;
      }
      return MealsService.GetAll(query);
    },
    { keepPreviousData },
  );

  const handleOpenDeleteMode = (item: Meal) => {
    setDeleteMode(true);
    setDeleteModeItem(item);
  };
  const handleCloseDeleteMode = () => {
    setDeleteMode(false);
    setDeleteModeItem(null);
  };

  useEffect(() => {
    if (!isLoading) setCount(data?.data?.metadata?.total_count || 0);
  }, [data, isLoading, setCount]);

  const queryClient = useQueryClient();

  const {
    isLoading: deleteLoading, isError: isDeleteError, mutate: deleteMeal, variables: deleteVariables,
  } = useMutation(
    (id: number) => MealsService.Delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.MEAL_TYPES]);
        queryClient.invalidateQueries([QueryKey.MEALS]);
        handleCloseDeleteMode();
      },
    },
  );

  return {
    data,
    isLoading,
    isRefetching,
    isError,
    error,
    search,
    setSearch,
    per_page,
    ListPagination,
    Sort,
    SearchField,
    setMealTypeFilter,
    deleteMode,
    deleteModeItem,
    handleOpenDeleteMode,
    handleCloseDeleteMode,
    deleteLoading,
    isDeleteError,
    deleteMeal,
    deleteVariables,
  };
}
