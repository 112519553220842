import {
  Box, ButtonGroup,
} from '@mui/material';
import { CheckinAnswerType, CheckinAnswers } from '../../../framework/constants/CheckinAnswers';
import { CheckIn } from '../../../framework/types/Checkin';
import { CheckinButton } from './CheckinButton';

export function CheckinCalendarItem({
  checkin, isLoading, date, disabled, handleCheckinUpdate, today,
}: {
  checkin?: Partial<CheckIn>,
  isLoading?: boolean,
  date: string,
  disabled: boolean,
  handleCheckinUpdate: (answer: CheckinAnswerType, date: string, id: number | null) => void,
  today: boolean,
}) {
  const { YES, NO } = CheckinAnswers;
  if (today && checkin?.answer === YES) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <ButtonGroup size="small">
          <CheckinButton
            isLoading={isLoading}
            type="error"
            clickHandler={() => handleCheckinUpdate(NO, date, checkin?.id || null)}
            selected={checkin?.answer === NO}
          >
            No
          </CheckinButton>
        </ButtonGroup>
      </Box>
    );
  }
  if (disabled) {
    return null;
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <ButtonGroup size="small">
        <CheckinButton
          isLoading={isLoading}
          type="success"
          clickHandler={() => handleCheckinUpdate(YES, date, checkin?.id || null)}
          selected={checkin?.answer === YES}
        >
          Yes
        </CheckinButton>
        <CheckinButton
          isLoading={isLoading}
          type="error"
          clickHandler={() => handleCheckinUpdate(NO, date, checkin?.id || null)}
          selected={checkin?.answer === NO}
        >
          No
        </CheckinButton>
      </ButtonGroup>
    </Box>
  );
}
