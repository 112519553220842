import { Box } from '@mui/material';
import { useState } from 'react';
import { Roles } from '../../../framework/constants/roles';
import { useAllergens } from '../../../framework/hooks/api/Allergens/useAllergens';
import { useUserAllergens } from '../../../framework/hooks/api/Users/useUserAllergens';
import {
  Allergen, AllergenFilterOption, AllergenRequest,
} from '../../../framework/types/Allergens';
import { User } from '../../../framework/types/User';
import UpdatedComboBox from '../../molecules/UpdatedComboBox';
import { ActionsGroup } from '../../utils/ActionsGroup';
import { AuthGuard } from '../../utils/AuthGuard';
import { FormSection } from '../../utils/FormSection';
import { AddNewAllergenDialog } from '../allergens/AddNewAllergenDialog';

export function AllergensData({ user }: { user: User }) {
  const {
    isLoading, openEditMode, closeEditMode, editMode, onSubmit, isUpdateLoading, control, setValue, getValues,
  } = useUserAllergens(user.id!);
  const {
    data: allergens, isLoading: isAllergensLoading, setSearch,
  } = useAllergens();
  const newCustomValue = (inputValue: string): AllergenFilterOption => ({
    inputValue,
    id: 0,
    name: `Add new ${inputValue}`,
  });
  const [allergenDialog, setAllergenDialog] = useState(false);
  const [allergenDefaultValues, setAllergenDefaultValues] = useState<AllergenRequest>({ name: '' });
  const openNewAllergenDialog = (inputValue: string) => {
    setAllergenDefaultValues({
      name: inputValue,
    });
    setAllergenDialog(true);
  };
  const closeAllergenDialog = (allergen?: Allergen) => {
    if (allergen) {
      const newAllergens = [...getValues('allergen_ids'), allergen];
      setValue('allergen_ids', newAllergens);
    }
    setAllergenDefaultValues({ name: '' });
    setAllergenDialog(false);
  };
  return (
    <Box>
      <form onSubmit={onSubmit}>
        <FormSection
          title="Allergens List"
          actions={(
            <AuthGuard roles={[Roles.ADMIN]}>
              <ActionsGroup mode={editMode} openMode={openEditMode} closeMode={closeEditMode} submitLoading={isUpdateLoading} />
            </AuthGuard>
          )}
        >
          <UpdatedComboBox<Allergen, { allergen_ids: Allergen[] }>
            name="allergen_ids"
            options={allergens?.data?.data || []}
            isLoading={isLoading || isAllergensLoading}
            control={control}
            openNewDialog={openNewAllergenDialog}
            onNewCustomValue={newCustomValue}
            label="Allergens"
            setSearch={setSearch}
            readOnly={!editMode}
          />
        </FormSection>
      </form>
      <AddNewAllergenDialog show={allergenDialog} close={closeAllergenDialog} defaultValues={allergenDefaultValues} />
    </Box>
  );
}
