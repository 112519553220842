import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { StoreWithDevtools } from './StoreWithDevtools';

interface IPortalStore {
  toolbarElement?: Element | null;
  setToolbarElement: () => void;
  mainElement?: Element | null;
  setMainElement: () => void;
}

export const usePortalStore = StoreWithDevtools<IPortalStore>((set) => ({
  setToolbarElement: () => set(() => ({ toolbarElement: document.querySelector('#toolbar-portal') })),
  setMainElement: () => set(() => ({ mainElement: document.querySelector('#main-portal') })),
}));

export function useToolbarPortal(children: ReactNode) {
  const target = usePortalStore((s) => s.toolbarElement);
  if (!target) return null;
  return ReactDOM.createPortal(children, target);
}

export function useMainPortal(children: ReactNode) {
  const target = usePortalStore((s) => s.mainElement);
  if (!target) return null;
  return ReactDOM.createPortal(children, target);
}
