import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersService } from '../../../services/api/UsersService';
import { PaginationQueryParams } from '../../../types/Pagination';
import { useFilter } from '../../useFilter';
import { usePagination } from '../../usePagination';

export function useUsersList() {
  const {
    page, per_page,
  } = usePagination();
  const { search, setSearch } = useFilter();
  const {
    data, isLoading, isRefetching, // isError, error,
  } = useQuery(
    [QueryKey.USERS, page, per_page, search],
    () => {
      const params: PaginationQueryParams = {
        page, size: per_page, 'sort[field]': 'first_name', 'sort[type]': 'asc',
      };
      if (search) {
        params['search[first_name]'] = search;
        params['search[last_name]'] = search;
      }
      return UsersService.GetAll(params || {});
    },
    { keepPreviousData: true },
  );
  return {
    data, isLoading: isLoading || isRefetching, search, setSearch,
  };
}
