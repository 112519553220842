import {
  Box, Button, Dialog, List,
} from '@mui/material';
import { RelationshipOptions } from '../../../framework/constants/RelationshipOptions';
import { useCreateOrUpdateFamily } from '../../../framework/hooks/api/Users/useCreateOrUpdateFamily';
import { useDeleteFamily } from '../../../framework/hooks/api/Users/useDeleteFamily';
import { User } from '../../../framework/types/User';
import { FamilyMember as FamilyMemberType } from '../../../framework/types/FamilyMember';
import { FamilyMember } from '../../molecules/users/FamilyMember';
import { ConfirmationModal } from '../../utils/ConfirmationModal';
import { FamilyForm } from './FamilyForm';
import { FormSection } from '../../utils/FormSection';
import { Roles } from '../../../framework/constants/roles';
import { AuthGuard } from '../../utils/AuthGuard';

export function FamilyData({ user }: { user: User }) {
  const dialogContext = useCreateOrUpdateFamily(user.id!);
  const { dialogMode, openDialogMode, closeDialogMode } = dialogContext;
  const deleteContext = useDeleteFamily();
  const {
    deleteMode, deleteModeItem, deleteLoading, deleteFamilyMember, openDeleteMode, closeDeleteMode,
  } = deleteContext;
  const { family } = user;
  const mappedFamily: Record<string, FamilyMemberType[]> = {};
  family.forEach((member) => {
    if (mappedFamily[member.relationship_type]) {
      mappedFamily[member.relationship_type].push(member);
    } else {
      mappedFamily[member.relationship_type] = [member];
    }
  });

  return (
    <Box>
      <FormSection
        title="Family"
        actions={(
          <AuthGuard roles={[Roles.ADMIN]}>
            <Button onClick={() => openDialogMode()}>Add New</Button>
          </AuthGuard>
        )}
      />
      {RelationshipOptions.map((option) => {
        const memberList = mappedFamily[option.value];
        if (memberList) {
          return (
            <FormSection
              key={option.value}
              title={option.plural}
            >
              <List sx={{ width: '100%' }} dense disablePadding>
                {memberList.map((item) => (
                  <FamilyMember member={item} key={item.id} openEditMode={openDialogMode} openDeleteMode={openDeleteMode} />
                ))}
              </List>
            </FormSection>
          );
        }
        return null;
      })}
      <Dialog open={dialogMode} onClose={closeDialogMode}>
        <FamilyForm {...dialogContext} />
      </Dialog>
      <ConfirmationModal
        open={deleteMode}
        cancel={closeDeleteMode}
        confirm={deleteFamilyMember}
        isLoading={deleteLoading}
        title="Are you sure you want to delete this family member?"
      >
        {deleteModeItem?.first_name}
        {' '}
        {deleteModeItem?.last_name}
      </ConfirmationModal>
    </Box>
  );
}
