import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersService } from '../../../services/api/UsersService';
import { HubRequest } from '../../../types/Hubs';
import { User } from '../../../types/User';

export function useHubUpdate(user: User, closeMode?: () => void) {
  const schema = z.object({
    hub_id: z.number(),
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<HubRequest>(
    { resolver: zodResolver(schema) },
  );
  useEffect(() => {
    setValue('hub_id', user.hub?.id!);
  }, [user, setValue]);
  const queryClient = useQueryClient();

  const {
    isLoading, mutate: update,
  } = useMutation(
    (formData: HubRequest) => UsersService.UpdateHub(user.id!, formData),
    {
      onSuccess: (responseData) => {
        if (responseData.success) {
          queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
          if (closeMode) closeMode();
        }
      },
    },
  );

  const onSubmit = handleSubmit((formData) => update(formData));

  return {
    errors, control, isLoading, onSubmit,
  };
}
