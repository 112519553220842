import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../constants/QueryKey';
import { HubsService } from '../../../services/api/HubsService';

export function useHubs() {
  const {
    data, isLoading, isRefetching, // isError, error,
  } = useQuery(
    [QueryKey.HUBS],
    () => HubsService.GetAll(),
    { keepPreviousData: true },
  );
  return { data, isLoading: isLoading || isRefetching };
}
