import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKey } from '../../../framework/constants/QueryKey';
import { UsersService } from '../../../framework/services/api/UsersService';
import { useAuthStore } from '../../../framework/store/AuthStore';
import { Title } from '../../atoms/Title';
import { UserProfile } from '../../organisms/users/UserProfile';

export function UserPage() {
  const { user } = useAuthStore();
  const params = useParams();
  const user_id = params.user_id ? params.user_id : user?.id;
  const {
    data, isLoading, isRefetching, // isError, error,
  } = useQuery(
    [QueryKey.SINGLE_USER, user_id],
    () => UsersService.GetOne(user_id!),
    { keepPreviousData: true },
  );
  return (
    <Box sx={{
      px: { xs: 0, md: 8 },
      maxWidth: '100%',
    }}
    >
      <Title title="User Profile" />
      <UserProfile user={data?.data} isLoading={isLoading || isRefetching || !data} />
    </Box>
  );
}
