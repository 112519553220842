/* eslint-disable @typescript-eslint/no-unused-vars */
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../../framework/store/AuthStore';
import { Role } from '../../framework/types/Role';
import { authGuard } from '../../utils/authGuardHelper';

type TAuthGuardProps = {
  roles?: Role[];
  redirectToLogin?: boolean;
  redirectToHome?: boolean;
  override?: boolean;
};
export function AuthGuard({
  children,
  roles,
  redirectToLogin = false,
  redirectToHome = false,
  override,
}: PropsWithChildren<TAuthGuardProps>) {
  const { loggedIn, user } = useAuthStore();

  let authorized = false;
  if (loggedIn && user) {
    if (roles && roles.length > 0) {
      authorized = authGuard(roles, user.role);
    } else {
      authorized = true;
    }
  }
  // rendering
  if (authorized || override) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  if (redirectToHome) {
    return <Navigate to={{ pathname: '/' }} />;
  }
  if (redirectToLogin) {
    return <Navigate to={{ pathname: '/login' }} />;
  }
  return null;
}
