import { Position } from './Position';
import { User } from './User';

export class UserQueryFilter {
  search?: string;

  users?: User[];

  started_at_from?: Date | null;

  started_at_to?: Date | null;

  ended_at_from?: Date | null;

  ended_at_to?: Date | null;

  ended_at?: string | null;

  edu_level?: { label: string, value: string }[];

  gender?: string;

  dob_from?: Date | null;

  dob_to?: Date | null;

  work_experience_from?: number | null;

  work_experience_to?: number | null;

  relationship_status?: { label: string, value: string }[];

  has_children?: string | null;

  children_count?: number;

  position?: Position[];

  constructor(query?: UserQueryFilter) {
    this.search = query?.search || '';

    this.users = query?.users || [];

    this.started_at_from = query?.started_at_from || null;

    this.started_at_to = query?.started_at_to || null;

    this.ended_at_from = query?.ended_at_from || null;

    this.ended_at_to = query?.ended_at_to || null;

    this.ended_at = query?.ended_at || null;

    this.edu_level = query?.edu_level || [];

    this.gender = query?.gender || '';

    this.dob_from = query?.dob_from || null;

    this.dob_to = query?.dob_to || null;

    this.work_experience_from = query?.work_experience_from || null;

    this.work_experience_to = query?.work_experience_to || null;

    this.relationship_status = query?.relationship_status || [];

    this.has_children = query?.has_children || null;

    this.children_count = query?.children_count || 0;

    this.position = query?.position || [];
  }
}
