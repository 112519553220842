import { LoadingButton } from '@mui/lab';
import {
  Button, Card, CardActions, CardContent, CardHeader, MenuItem, TextField,
} from '@mui/material';
import {
  UseFormRegister, FieldErrors, Control, Controller,
} from 'react-hook-form';
import { FuelTypeOptions } from '../../../framework/constants/FuelTypes';
import { Vehicle } from '../../../framework/types/Vehicles';

type Props = {
  isLoading: boolean,
  onSubmit: () => void,
  register: UseFormRegister<Vehicle>,
  control: Control<Vehicle, any>,
  errors: FieldErrors<Vehicle>,
  closeDialogMode: () => void,
  editModeItem: Vehicle | null,
};

export function VehicleForm({
  isLoading, onSubmit, register, control, errors, closeDialogMode, editModeItem,
}: Props) {
  return (
    <Card>
      <form onSubmit={onSubmit}>
        <CardHeader title={editModeItem?.id ? 'Update Vehicle' : 'Create Vehicle'} />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '20p' }}>
          <TextField {...register('model')} label="Model" error={!!errors.model} helperText={errors.model?.message} />
          <TextField {...register('reg_number')} label="Licence Plate Number" error={!!errors.reg_number} helperText={errors.reg_number?.message} />
          <Controller
            name="fuel_type"
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                select
                {...field}
                label="Fuel Type"
                error={invalid}
                helperText={error?.message}
              >
                {FuelTypeOptions.map((fuel_type) => <MenuItem key={fuel_type.value} value={fuel_type.value}>{fuel_type.label}</MenuItem>)}
              </TextField>
            )}
          />
          <TextField
            {...register('avg_consumption')}
            label="Average Consumption"
            error={!!errors.avg_consumption}
            helperText={errors.avg_consumption?.message}
          />
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={closeDialogMode}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>Save</LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
}
