import { List } from '@mui/material';
import { Allergen } from '../../../framework/types/Allergens';
import { AllergenItem } from '../../molecules/allergens/AllergenItem';

type Props = { allergens: Allergen[], openDeleteMode: (item: Allergen) => void, openDialogMode: (item: Allergen) => void };

export function AllergensList({ allergens, openDeleteMode, openDialogMode }: Props) {
  return (
    <List>
      {allergens.map((all) => <AllergenItem allergen={all} key={all.id} openDeleteMode={openDeleteMode} openEditMode={openDialogMode} />)}
    </List>
  );
}
