import {
  Box,
  IconButton,
  MenuItem, TextField,
} from '@mui/material';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { SearchInput } from '../../components/atoms/SearchInput';

export function useFilter(filterProps?: { label: string, value: string }[]) {
  const [sortBy, setSortBy] = useState(filterProps?.length ? filterProps[0].value : '');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [search, setSearch] = useState('');
  const handleFilterChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setSortBy(event.target.value);
  };
  const toggleDirection = () => {
    const newDirection = direction === 'asc' ? 'desc' : 'asc';
    setDirection(newDirection);
  };
  const Sort = (
    <Box sx={{ width: '100%', display: 'flex' }}>
      <TextField
        select
        onChange={handleFilterChange}
        value={sortBy}
        label="Sort by"
        sx={{ flex: 1 }}
      >
        {filterProps?.map((prop) => <MenuItem value={prop.value} key={prop.value}>{prop.label}</MenuItem>)}
      </TextField>
      <IconButton onClick={toggleDirection}>
        {direction === 'asc' && <KeyboardDoubleArrowUpIcon />}
        {direction === 'desc' && <KeyboardDoubleArrowDownIcon />}
      </IconButton>
    </Box>
  );

  const handleSearchInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const SearchField = (
    <SearchInput
      name="search"
      onChange={handleSearchInput}
      onReset={() => setSearch('')}
      value={search}
    />
  );

  return {
    sortBy,
    direction,
    Sort,
    SearchField,
    search,
    setSearch,
    setSortBy,
    setDirection,
  };
}
