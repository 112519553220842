import {
  Box, Button,
  IconButton,
  useMediaQuery, useTheme,
} from '@mui/material';
import {
  DataGrid,
  GridColDef, GridColumnVisibilityModel, GridSortModel, GridPreferencePanelsValue, useGridApiRef,
} from '@mui/x-data-grid';
import { JSXElementConstructor, ReactNode } from 'react';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { perPageOptions } from '../../../framework/constants/PerPageOptions';

type Props<T> = {
  columns: GridColDef[],
  rows: T[],
  paginationComponent: JSXElementConstructor<null>,
  isLoading?: boolean,
  onSortChange: (model: GridSortModel) => void,
  onColumnVisibilityChange: (model: GridColumnVisibilityModel) => void,
  toolbarSlot?: ReactNode,
};

export default function DataTable<T>({
  columns, rows, paginationComponent, isLoading, onSortChange, onColumnVisibilityChange, toolbarSlot,
}: Props<T>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const apiRef = useGridApiRef();

  const handleClick = () => {
    apiRef.current?.showPreferences(GridPreferencePanelsValue.columns);
  };

  return (
    <Box sx={{
      width: '100%',
    }}
    >
      <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-end', md: 'center' },
        flexDirection: { xs: 'column', md: 'row' },
      }}
      >
        {toolbarSlot || <div />}
        {isMobile ? (
          <IconButton onClick={handleClick} color="primary">
            <ViewColumnIcon />
          </IconButton>
        ) : (
          <Button onClick={handleClick} color="primary" variant="contained" endIcon={<ViewColumnIcon />} sx={{ mb: 2 }}>
            Select Columns
          </Button>
        )}
      </Box>
      <DataGrid
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSizeOptions={perPageOptions}
        getRowHeight={() => 'auto'}
        autoHeight
        columnHeaderHeight={70}
        sortingMode="server"
        showCellVerticalBorder
        showColumnVerticalBorder
        hideFooterSelectedRowCount
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell': {
            py: 1,
          },
          '& .no-padding': {
            padding: 0,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
          },
        }}
        onSortModelChange={onSortChange}
        onColumnVisibilityModelChange={onColumnVisibilityChange}
        slots={{
          pagination: paginationComponent,
        }}
      />
    </Box>
  );
}
