import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { parseResponseFormErrors } from '../../../../utils/responseFormErrorHelper';
import { GenericTrimmedRequiredString } from '../../../../utils/zodHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { VehiclesService } from '../../../services/api/VehiclesService';
import { Vehicle } from '../../../types/Vehicles';

export function useCreateOrUpdateVehicles(userId: number) {
  const [dialogMode, setDialogMode] = useState(false);
  const [editModeItem, setEditModeItem] = useState<Vehicle>(new Vehicle());
  const schema = z.object({
    model: GenericTrimmedRequiredString,
    reg_number: GenericTrimmedRequiredString,
    fuel_type: GenericTrimmedRequiredString,
    avg_consumption: GenericTrimmedRequiredString,
    user_id: z.number(),
  });
  const {
    register, handleSubmit, formState: { errors }, control, setError,
  } = useForm<Vehicle>({
    resolver: zodResolver(schema),
    values: editModeItem,
  });
  const openDialogMode = (editVehicle?: Vehicle) => {
    setDialogMode(true);
    if (editVehicle) {
      setEditModeItem(new Vehicle({ ...editVehicle, user_id: userId }));
    } else {
      setEditModeItem(new Vehicle({ user_id: userId }));
    }
  };
  const closeDialogMode = () => {
    setDialogMode(false);
    setEditModeItem(new Vehicle());
  };
  const queryClient = useQueryClient();
  const {
    isLoading: isCreateLoading, mutate: create, // isError: isCreateError,
  } = useMutation(
    (formData: Vehicle) => VehiclesService.Create(formData),
    {
      onSuccess: (responseData) => {
        if (responseData.success) {
          queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
          closeDialogMode();
        } else if (!responseData.success) {
          if (responseData?.data?.errors) {
            parseResponseFormErrors(responseData.data.errors, setError);
          }
        }
      },
    },
  );
  const {
    isLoading: isUpdateLoading, mutate: update,
  } = useMutation(
    (formData: Vehicle) => {
      const res = VehiclesService.Update(editModeItem?.id!, formData);
      return res;
    },
    {
      onSuccess: (responseData) => {
        if (responseData.success) {
          queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
          closeDialogMode();
        } else if (!responseData.success) {
          if (responseData?.data?.errors) {
            parseResponseFormErrors(responseData.data.errors, setError);
          }
        }
      },
    },
  );
  const onSubmit = editModeItem?.id
    ? handleSubmit((formData) => update(formData))
    : handleSubmit((formData) => create(formData));
  return {
    dialogMode, editModeItem, openDialogMode, closeDialogMode, register, control, errors, isLoading: isCreateLoading || isUpdateLoading, onSubmit,
  };
}
