import {
  Dialog, Box, Button, DialogTitle, DialogContent, DialogActions, FormHelperText, FormControl,
} from '@mui/material';
import {
  FormEventHandler, useEffect, useState,
} from 'react';
import { LoadingButton } from '@mui/lab';
import { useMealTypes } from '../../../framework/hooks/api/MealTypes/useMealTypes';
import { Meal } from '../../../framework/types/Meals';
import { Menu, MenuMap } from '../../../framework/types/Menus';
import { standardFormatFromDate } from '../../../utils/dateHelper';
import { FetchUi } from '../../utils/FetchUi';
import { MenuSkeleton } from '../../utils/skeletons/MenuSkeleton';
import { AddNewMealDialog } from '../meals/AddNewMealDialog';
import { MealsCombobox } from '../../molecules/meals/MealsCombobox';
import { MealRequest } from '../../../framework/types/MealRequest';

type Props = {
  selectedDate: Date | null,
  singleDateDialog: boolean,
  closePopup: () => void,
  selectedMenu: Menu | null,
  mealsValue: MenuMap | undefined,
  setMealsValue: (mealsValue: MenuMap | undefined) => void,
  onSubmit: FormEventHandler<HTMLFormElement>,
  updateMealsValues: (newValues: Meal[] | undefined, mealTypeId: number) => void,
  isSubmitLoading: boolean,
  error?: string | boolean;
};

export function MenuForm({
  selectedDate,
  singleDateDialog,
  closePopup,
  selectedMenu,
  mealsValue,
  setMealsValue,
  onSubmit,
  updateMealsValues,
  isSubmitLoading,
  error,
}: Props) {
  const {
    mealTypesData,
    mealTypesIsLoading, mealTypesIsRefetching,
  } = useMealTypes();

  const [addNewMealDialog, setAddNewMealDialog] = useState(false);
  const [defaultValues, setDefaultValues] = useState<MealRequest>(new MealRequest());
  const openNewMealDialog = (customValues: MealRequest) => {
    setAddNewMealDialog(true);
    setDefaultValues(customValues);
  };
  const closeNewmealDialog = (meal?: Meal) => {
    if (meal) {
      const value = mealsValue && mealsValue[meal.meal_type.id] ? mealsValue[meal.meal_type.id] : [];
      updateMealsValues([...value, meal], meal.meal_type.id);
    }
    setAddNewMealDialog(false);
  };
  const handleDialogClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      closePopup();
    }
  };
  useEffect(() => {
    if (selectedMenu) {
      const obj: MenuMap = {};
      selectedMenu.items.forEach((item) => {
        if (obj[item.meal_type.id]) {
          obj[item.meal_type.id].push(item);
        } else {
          obj[item.meal_type.id] = [item];
        }
      });
      setMealsValue(obj);
    } else {
      setMealsValue(undefined);
    }
  }, [selectedMenu, setMealsValue]);

  return (
    <Dialog open={singleDateDialog} onClose={handleDialogClose}>
      <form onSubmit={onSubmit}>
        <FormControl sx={{ width: '100%' }} error={!!error}>
          <DialogTitle>{selectedDate && standardFormatFromDate(selectedDate)}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <FetchUi isLoading={mealTypesIsLoading || mealTypesIsRefetching} loadingUi={<MenuSkeleton />}>
                {mealTypesData?.data?.data.map((mt) => (
                  <MealsCombobox
                    mealType={mt}
                    key={mt.id}
                    values={mealsValue ? mealsValue[mt.id] : []}
                    updateMealsValues={updateMealsValues}
                    openNewDialog={openNewMealDialog}
                  />
                ))}
                <FormHelperText>{error}</FormHelperText>
              </FetchUi>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={closePopup}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitLoading}>
              Save
            </LoadingButton>
          </DialogActions>
        </FormControl>
      </form>
      {addNewMealDialog && (
        <AddNewMealDialog show={addNewMealDialog} close={closeNewmealDialog} defaultValues={defaultValues} />
      )}
    </Dialog>
  );
}
