import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteInputChangeReason, createFilterOptions } from '@mui/material/Autocomplete';
import {
  debounce, FilterOptionsState, FormControl, FormHelperText, ListItemText, MenuItem, Skeleton,
} from '@mui/material';
import { SyntheticEvent, useCallback, useState } from 'react';

type Props<T> = {
  data: T[],
  isLoading: boolean,
  search: string,
  setSearch: (search: string) => void,
  values: T[] | undefined,
  updateValues: (newValue: T[] | undefined) => void,
  openNewDialog?: (newValue: string) => void,
  onNewCustomValue?: (newValue: string) => T,
  label?: string,
  readOnly?: boolean,
  error?: string,
};

export default function ComboBox<T extends { id: number, name: string }>({
  values, updateValues, openNewDialog, data, label, setSearch, isLoading, onNewCustomValue, readOnly, error,
}: Props<T>) {
  const filter = createFilterOptions<T>();

  const [inputValue, setInputValue] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((s: string) => {
      setSearch(s);
    }, 400),
    [],
  );

  const setFilterInputValue = (searchValue: string) => {
    setInputValue(searchValue);
    debounceSearch(searchValue);
  };
  const onChange = (event: any, newValue: ((T & { inputValue?: string }) | string)[] | undefined) => {
    if (newValue) {
      if (typeof newValue === 'string') {
        if (openNewDialog) openNewDialog(newValue);
      } else {
        const customValue = newValue.find((value) => typeof value !== 'string' && value.inputValue);
        if (customValue && typeof customValue !== 'string' && customValue.inputValue) {
          if (openNewDialog) openNewDialog(customValue.inputValue);
        } else if (newValue.every((value) => typeof value !== 'string')) {
          updateValues(newValue as T[]);
          setFilterInputValue('');
        }
      }
    }
  };
  const onInputChange = (event: SyntheticEvent<Element, Event>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    if (event && event.type === 'blur') {
      setFilterInputValue('');
    } else if (reason !== 'reset') {
      setFilterInputValue(newInputValue);
    }
  };
  const onFilterOptions = (options: T[], params: FilterOptionsState<T>) => {
    const filtered = filter(options, params);
    if (options.length === 0 && onNewCustomValue) {
      filtered.push(onNewCustomValue(params.inputValue || ' '));
    }
    return filtered;
  };
  if (data) {
    return (
      <FormControl error={!!error} sx={{ width: '100%' }}>
        <Autocomplete
          autoComplete
          includeInputInList
          disableCloseOnSelect
          filterSelectedOptions
          freeSolo
          clearOnBlur
          multiple
          options={data}
          loading={isLoading}
          filterOptions={onFilterOptions}
          value={values || []}
          inputValue={inputValue}
          onInputChange={onInputChange}
          renderInput={(params) => <TextField {...params} label={label || ''} />}
          renderOption={(props, option) => <MenuItem value={option.id} key={option.id} {...props}>{option.name}</MenuItem>}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name) || ''}
          onChange={onChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          readOnly={readOnly}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    );
  }
  return (
    <ListItemText>
      <Skeleton variant="text" width={200} height={30} sx={{ fontSize: '24px' }} />
    </ListItemText>
  );
}
