import {
  List, ListItem, ListItemText, Skeleton,
} from '@mui/material';

export function MealsListSkeleton({ amount }: { amount: number }) {
  return (
    <List dense>
      {Array.from({ length: amount }, (_, k) => (
        <ListItem key={k} sx={{ height: 48 }}>
          <ListItemText>
            <Skeleton variant="text" width={300} height={30} sx={{ fontSize: '24px' }} />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
