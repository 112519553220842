import { ChangeEvent, useState } from 'react';
import {
  MenuItem, Pagination, Select, SelectChangeEvent,
} from '@mui/material';
import { perPageOptions } from '../constants/PerPageOptions';

export function usePagination() {
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / per_page);
  const handleChangePage = (
    event: ChangeEvent<unknown>,
    pageNumber: number,
  ) => {
    setPage(pageNumber);
  };

  const handleChangeRowsPerPage = (
    event: SelectChangeEvent<number>,
  ) => {
    setPerPage(Number(event.target.value));
    setPage(1);
  };

  const ListPagination = (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Pagination count={totalPages} color="primary" onChange={handleChangePage} page={page} />
      <Select
        value={per_page}
        onChange={handleChangeRowsPerPage}
        variant="standard"
      >
        {perPageOptions.map((option) => <MenuItem value={option} key={option}>{option}</MenuItem>)}
      </Select>
    </div>
  );
  function ListPaginationComponent() { return ListPagination; }
  return {
    page,
    per_page,
    setCount,
    ListPagination,
    ListPaginationComponent,
    setPage,
  };
}
