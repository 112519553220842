import { Vehicle, VehicleResponse } from '../../types/Vehicles';
import { APIService } from '../utils';

const endpoint = 'vehicles';

export const VehiclesService = {
  Create: async (data: Vehicle) => new APIService(endpoint).post<Vehicle, VehicleResponse>(data),
  Update: async (id: number, data: Vehicle) => new APIService(endpoint).updateWhole<Vehicle, VehicleResponse>(`${id}`, data),
  Delete: async (id: number) => new APIService(endpoint).delete(`${id}`),
};
