import { useMutation, useQueryClient } from '@tanstack/react-query';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { collapseWorkExperience } from '../../../../utils/workExperienceHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersService } from '../../../services/api/UsersService';
import { PersonalData, PersonalDataForm } from '../../../types/PersonalData';
import { User } from '../../../types/User';
import { usePersonalDataSchema } from './usePersonalDataSchema';
import { parseResponseFormErrors } from '../../../../utils/responseFormErrorHelper';

export function usePersonalDataUpdate(user: User, closeMode?: () => void) {
  const { personal } = user;
  const {
    register, handleSubmit, errors, control, setError, reset,
  } = usePersonalDataSchema(personal);

  const queryClient = useQueryClient();
  const {
    isLoading, mutate: update,
  } = useMutation(
    (formData: PersonalDataForm) => {
      const work_experience = collapseWorkExperience(formData.work_experience_years, formData.work_experience_months);
      const religious_date = formData.religious_date && standardFormatFromDate(new Date(formData.religious_date));
      const dob = standardFormatFromDate(new Date(formData.dob));
      const data = new PersonalData({
        ...formData, work_experience, dob, religious_date,
      });
      return UsersService.UpdatePersonalData(user.id!, data);
    },
    {
      onSuccess: (responseData) => {
        if (responseData.success) {
          queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
          if (closeMode) closeMode();
        } else {
          parseResponseFormErrors(responseData?.data?.errors, setError);
        }
      },
    },
  );
  const onSubmit = handleSubmit((formData) => update(formData));
  return {
    register, errors, isLoading, onSubmit, control, reset,
  };
}
