import { zodResolver } from '@hookform/resolvers/zod';
import { differenceInDays } from 'date-fns';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { validations } from '../../../../utils/validationHelper';
import { UserQueryFilter } from '../../../types/UsersQueryFilter';

export function useUserFilterSchema() {
  const userSchema = z.object({
    id: z.number(),
    first_name: z.string(),
    last_name: z.string(),
  });
  const positionSchema = z.object({
    id: z.number(),
    name: z.string(),
  });
  const valueLabelSchema = z.object({
    value: z.string(),
    label: z.string(),
  });
  const schema = z.object({
    search: z.string().optional(),
    users: z.array(userSchema).optional(),
    position: z.array(positionSchema).optional(),
    started_at_from: z.date().or(z.null()).optional(),
    started_at_to: z.date().or(z.null()).optional(),
    ended_at_from: z.date().or(z.null()).optional(),
    ended_at_to: z.date().or(z.null()).optional(),
    ended_at: z.string().or(z.null()).optional(),
    edu_level: z.array(valueLabelSchema).optional(),
    gender: z.string().or(z.null()).optional(),
    dob_from: z.date().or(z.null()).optional(),
    dob_to: z.date().or(z.null()).optional(),
    // work_experience_from: z.number().or(z.null()).optional(),
    // work_experience_to: z.number().or(z.null()).optional(),
    relationship_status: z.array(valueLabelSchema).optional(),
    has_children: z.string().or(z.null()).optional(),
    children_count_from: z.number().or(z.null()).optional(),
    children_count_to: z.number().or(z.null()).optional(),
  }).superRefine((val, ctx) => {
    if (!!val.started_at_from
      && !!val.started_at_to
      && differenceInDays(val.started_at_to, val.started_at_from) < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: validations.FIRST_DATE_BEFORE_SECOND,
        path: ['started_at_from'],
      });
    }
    if (!!val.ended_at_from
      && !!val.ended_at_to
      && differenceInDays(val.ended_at_to, val.ended_at_from) < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: validations.FIRST_DATE_BEFORE_SECOND,
        path: ['ended_at_from'],
      });
    }
    if (!!val.dob_from
      && !!val.dob_to
      && differenceInDays(val.dob_to, val.dob_from) < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: validations.FIRST_DATE_BEFORE_SECOND,
        path: ['dob_from'],
      });
    }
  });
  const {
    control, handleSubmit, reset,
  } = useForm<UserQueryFilter>({
    resolver: zodResolver(schema),
    defaultValues: new UserQueryFilter(),
  });
  return {
    control, handleSubmit, reset,
  };
}
