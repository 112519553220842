import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { validations } from '../../../../utils/validationHelper';
import { GenericTrimmedRequiredString } from '../../../../utils/zodHelper';
import { User, UserBasicInfoRequest } from '../../../types/User';

const symphonyDomainRegex = '^[A-Za-z0-9._%+-]+@symphony.is$';

export function useBasicInfoSchema(user: User) {
  function testEmailDomain(email: string) {
    return email.match(symphonyDomainRegex);
  }
  const schema = z.object({
    first_name: GenericTrimmedRequiredString,
    last_name: GenericTrimmedRequiredString,
    nickname: z.string().trim().or(z.null()).optional(),
    gender: GenericTrimmedRequiredString,
    email: GenericTrimmedRequiredString.refine((e) => testEmailDomain(e), validations.SYMPHONY_DOMAIN),
    started_at: z.date().or(z.string()),
    ended_at: z.date().or(z.string()).or(z.null()),
    role_id: z.number(),
    hub_id: z.number(),
    core_tool_user_id: z.number().or(z.string()),
    position_id: z.number(),
  });

  const {
    register, handleSubmit, formState: { errors }, setValue, control, setError, reset,
  } = useForm<UserBasicInfoRequest>({
    resolver: zodResolver(schema),
    values: {
      ...user, role_id: user.role?.id!, hub_id: user.hub?.id!, position_id: user.position?.id!,
    },
  });

  return {
    register, handleSubmit, errors, setValue, control, setError, reset,
  };
}
