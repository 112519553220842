import {
  Table, TableBody, TableRow, TableCell,
} from '@mui/material';

type Props<T> = { members: T[], field?: keyof T, getRenderForFieldValue?: (member: T) => string };

export function PeopleDataColumn<T extends { id: number | undefined }>({ members, field, getRenderForFieldValue }: Props<T>) {
  function getRender(f: T) {
    if (field) {
      return f[field] as string;
    } if (getRenderForFieldValue) {
      return getRenderForFieldValue(f);
    }
    return '';
  }
  return (
    <Table sx={{ height: '100%' }}>
      <TableBody>
        {members
          .map((f) => (
            <TableRow key={f.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
              <TableCell sx={{ width: '200px', borderRight: '1px solid #f0f0f0' }}>
                {getRender(f)}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
