import {
  Box, Button,
} from '@mui/material';
import { isBefore, isWeekend } from 'date-fns';
import { useMenus } from '../../../framework/hooks/api/Menus/useMenus';
import { useCalendar } from '../../../framework/hooks/useCalendar';
import { standardFormatFromDate } from '../../../utils/dateHelper';
import { Calendar } from '../../organisms/calendar';
import { MenuForm } from '../../organisms/menus/MenuForm';
import { useMenu } from '../../../framework/hooks/api/Menus/useMenu';
import { useMealTypes } from '../../../framework/hooks/api/MealTypes/useMealTypes';
import { MenuCalendarItem } from '../../molecules/menus/MenuCalendarItem';
import { Title } from '../../atoms/Title';

export function MenusPage() {
  const useMenuContext = useMenu();
  const {
    singleDateDialog,
    setSingleDateDialog,
    selectedDate,
    setSelectedDate,
    setSelectedMenu,
  } = useMenuContext;

  const calendar = useCalendar();
  const { start, end, today } = calendar;

  const {
    menusData,
    menusIsLoading,
    menusIsRefetching,
  } = useMenus(start, end);
  const {
    mealTypesData, mealTypesIsLoading,
  } = useMealTypes();

  const handleDateClick = (calendarDate: Date) => {
    setSingleDateDialog(true);
    setSelectedDate(calendarDate);
    if (menusData) {
      const menu = menusData[standardFormatFromDate(calendarDate)];
      setSelectedMenu(menu);
    }
  };

  const getCalendarItemComponent = (date: Date) => {
    if (menusData && mealTypesData) {
      const menu = menusData[standardFormatFromDate(date)];
      if (menu) {
        return <MenuCalendarItem menu={menu} mealTypes={mealTypesData.data?.data || []} />;
      } if (!isWeekend(date) && !isBefore(date, today)) {
        return <Button onClick={() => handleDateClick(date)}>Add menu</Button>;
      }
    }
    return null;
  };

  return (
    <Box>
      <Title title="Menus" />
      <Calendar
        getRenderForDay={getCalendarItemComponent}
        onDateClick={handleDateClick}
        loading={menusIsLoading || menusIsRefetching || mealTypesIsLoading}
        disableWeekends
        {...calendar}
      />
      {singleDateDialog && selectedDate && (
        <MenuForm
          {...useMenuContext}
        />
      )}
    </Box>
  );
}
