import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../organisms/navigation/Sidebar';
import { AppToolbar } from './AppToolbar';
import { useAuthStore } from '../../framework/store/AuthStore';
import { usePortalStore } from '../../framework/store/PortalStore';
import { authGuard } from '../../utils/authGuardHelper';
import { Roles } from '../../framework/constants/roles';

export function DashboardContainer() {
  const { initialized, user } = useAuthStore();
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const closeSidebar = () => {
    setOpen(true);
  };
  const portalStore = usePortalStore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => portalStore.setMainElement(), []);
  const isUser = authGuard([Roles.USER], user?.role);

  return (
    <Box sx={{ display: 'flex', maxWidth: '100%' }}>
      {user && (
        <>
          <AppToolbar toggle={toggleDrawer} isUser={isUser} />
          {!isUser && (
            <Sidebar closeSidebar={closeSidebar} open={open} />
          )}
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          maxWidth: isUser ? '100%' : { xs: '100%', md: open ? 'calc(100% - 240px)' : 'calc(100% - 64px)' },
          position: 'relative',
        }}
      >
        <Toolbar />
        <Container
          maxWidth={isUser ? 'xl' : false}
          sx={{
            py: { xs: 0, md: 4 }, px: { xs: 0, md: 'initial' }, height: { xs: '100%', md: 'auto' }, maxWidth: '100%',
          }}
        >
          {initialized && <Outlet />}
        </Container>
        <Box sx={{ position: 'fixed', bottom: '40px', right: '30px' }}>
          <div id="main-portal" />
        </Box>
      </Box>
    </Box>
  );
}
