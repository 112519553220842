import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Roles } from '../../../framework/constants/roles';
import { authGuard } from '../../../utils/authGuardHelper';
import { useAuthStore } from '../../../framework/store/AuthStore';

export function CheckinsStats({ checkins }: { checkins: { yes: string, no: string, unanswered: string } }) {
  const { user } = useAuthStore();
  return (
    <Box sx={{ display: 'flex', color: 'primary.main' }}>
      {authGuard([Roles.ADMIN, Roles.MEAL_PROVIDER], user?.role) ? (
        <>
          <Typography color="success.main">{checkins.yes}</Typography>
          <Typography>/</Typography>
          <Typography color="error.main">{checkins.no}</Typography>
          <Typography>/</Typography>
          <Typography>{checkins.unanswered}</Typography>
        </>
      ) : (
        <Typography color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CheckIcon fontSize="small" />
          {checkins.yes}
        </Typography>
      )}
    </Box>
  );
}
