import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import saveAs from 'file-saver';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { CalendarService } from '../../../services/api/CaledarService';
import { ExportRequest } from '../../../types/ExportRequest';
import { UsersService } from '../../../services/api/UsersService';

export function useSingleDate() {
  const [singleDate, setSingleDate] = useState<Date | null>(null);
  const [singleDateDialog, setSingleDateDialog] = useState(false);
  const openSingleDateDialog = (date: Date) => {
    setSingleDate(date);
    setSingleDateDialog(true);
  };
  const closeSingleDateDialog = () => {
    setSingleDate(null);
    setSingleDateDialog(false);
  };
  const {
    data, isError, isLoading, isRefetching, // error,
  } = useQuery(
    [QueryKey.SINGLE_DATE, singleDate],
    () => CalendarService.GetOne(standardFormatFromDate(singleDate!)),
    { keepPreviousData: true, enabled: !!singleDate },
  );

  const { isLoading: isExportLoading, mutate: onExport } = useMutation(
    (date: Date | null) => {
      const sort = { field: 'first_name', type: 'desc' };
      const filter = [
        {
          'checkins.date_from': date ? standardFormatFromDate(date) : undefined,
        },
        {
          'checkins.date_to': date ? standardFormatFromDate(date) : undefined,
        },
        { 'checkins.answer': 'yes' },
      ];
      const postData: ExportRequest = {
        columns: ['employee'], sort, filter,
      };
      return UsersService.Export(postData);
    },
    {
      onSuccess: (response, variables) => {
        if (response.success && response.data && variables) {
          saveAs(response.data, `Checkins list ${standardFormatFromDate(variables)}.xlsx`);
        }
      },
    },
  );

  return {
    data: data?.data,
    isError,
    isLoading,
    isRefetching,
    singleDate,
    singleDateDialog,
    openSingleDateDialog,
    closeSingleDateDialog,
    onExport,
    isExportLoading,
  };
}
