import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersTableColumns } from '../../../constants/UsersTableColumns';
import { UsersService } from '../../../services/api/UsersService';
import { ExportRequest } from '../../../types/ExportRequest';
import { PaginationQueryParams } from '../../../types/Pagination';
import { UsersAPIQuery } from '../../../types/UsersAPIQuery';
import { useColumnSelection } from '../../useColumnSelection';
import { useFilter } from '../../useFilter';
import { usePagination } from '../../usePagination';

export function useUsers() {
  const [query, setQuery] = useState<UsersAPIQuery>(new UsersAPIQuery());
  const {
    page, per_page, ListPaginationComponent, setCount, setPage,
  } = usePagination();
  const usersSortProps = [{ label: 'First Name', value: 'first_name' }, { label: 'Last Name', value: 'last_name' }];
  const {
    sortBy, direction, search, setSortBy, setDirection, setSearch,
  } = useFilter(usersSortProps);
  const {
    data, isLoading, isRefetching, refetch, // isError, error,
  } = useQuery(
    [QueryKey.USERS, page, per_page, sortBy, direction, query, query.query.search.first_name],
    () => {
      const params: PaginationQueryParams = {
        page, size: per_page, 'sort[field]': `${sortBy}`, 'sort[type]': direction,
      };
      return UsersService.GetAll({ ...params, ...query.getFilterQuery } || {});
    },
    { keepPreviousData: true },
  );
  useEffect(() => {
    if (!isLoading) setCount(data?.data?.metadata?.total_count || 0);
  }, [data, isLoading, setCount]);
  const setNewQuery = (newQuery: UsersAPIQuery) => {
    setPage(1);
    setQuery(newQuery);
  };
  const { columns, onColumnsChange } = useColumnSelection(UsersTableColumns);

  const { isLoading: isExportLoading, mutate: onExport } = useMutation(
    () => {
      const sort = { field: sortBy, type: direction };
      const postData: ExportRequest = {
        columns: Object.keys(columns).filter((key) => columns[key]), sort, filter: query.getExportFilter, search: query.getExportSearch,
      };
      return UsersService.Export(postData);
    },
    {
      onSuccess: (response) => {
        if (response.success && response.data) {
          saveAs(response.data, 'Users list.xlsx');
        }
      },
    },
  );
  return {
    data,
    ListPaginationComponent,
    isLoading: isLoading || isRefetching,
    setSortBy,
    setDirection,
    search,
    setSearch,
    refetch,
    query,
    setQuery: setNewQuery,
    onColumnsChange,
    onExport,
    isExportLoading,
  };
}
