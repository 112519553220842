import { MealRequest, MealResponse } from '../../types/MealRequest';
import { Meal, MealsResponse } from '../../types/Meals';
import { PaginationQueryParams } from '../../types/Pagination';
import { APIService } from '../utils';

type ActualMealRequest = MealRequest | { allergen_ids: number[] };
const endpoint = 'meals';

export const MealsService = {
  GetAll: async (params: PaginationQueryParams) => new APIService<MealsResponse>(endpoint).getAll(params),
  GetOne: async (id: string) => new APIService<Meal>(endpoint).getOne(id),
  Create: async (data: ActualMealRequest) => new APIService(endpoint).post<ActualMealRequest, MealResponse>(data),
  Update: async (id: string, data: ActualMealRequest) => new APIService(endpoint).updateWhole<ActualMealRequest, MealResponse>(id, data),
  Delete: async (id: number) => new APIService(endpoint).delete(`${id}`),
};
