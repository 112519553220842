import { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import {
  Box, Card, Container, Typography,
} from '@mui/material';
import { useAuthStore } from '../../../framework/store/AuthStore';
import { Title } from '../../atoms/Title';

export function LoginPage() {
  const navigate = useNavigate();
  const { loggedIn, login } = useAuthStore();
  useEffect(() => {
    if (loggedIn) {
      navigate('/admin');
    }
  }, [loggedIn, navigate]);

  const authUrl = `${process.env.REACT_APP_BASE_URL}/api/auth/google`;

  let windowObjectReference: Window | null = null;
  let previousUrl = '';
  const receiveMessage = useCallback((event: MessageEvent) => {
    const { data: { tokenSent } } = event;
    if (tokenSent) {
      window.removeEventListener('message', receiveMessage);
      login();
    }
  }, [login]);

  function openSignInWindow(url: string, name: string) {
    window.removeEventListener('message', receiveMessage);
    const w = 600;
    const h = 700;
    const left = (window.innerWidth / 2) - (w / 2);
    const top = (window.innerHeight / 2) - (h / 2);
    const strWindowFeatures = `toolbar=no, menubar=no, width=${w}, height=${h}, top=${top}, left=${left}`;
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      if (windowObjectReference !== null) {
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference?.focus();
      }
    } else {
      windowObjectReference.focus();
    }
    window.addEventListener('message', receiveMessage, false);
    previousUrl = url;
  }

  return (
    <Container
      component="main"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Title title="Login" />
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 6,
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{
            width: { xs: '60vw', md: '300px' }, display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <Box sx={{ width: '80%', height: '80%' }}>
              <img src="/logo.png" alt="logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </Box>
          </Box>
          <Box sx={{
            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '40px', p: 6,
          }}
          >
            <Typography variant="h5" align="center">
              Sign in with your
              <Typography variant="inherit" color="primary">symphony.is</Typography>
              account
            </Typography>
            <Button variant="contained" onClick={() => openSignInWindow(authUrl, 'popup')} fullWidth>Sign in</Button>
          </Box>
        </Box>
      </Card>
    </Container>
  );
}
