import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { MenusService } from '../../../services/api/MenusService';
import { Menu } from '../../../types/Menus';

export function useMenus(start: Date, end: Date) {
  const [mapData, setMapData] = useState<Record<string, Menu> | null>(null);
  const {
    data: queryData, isError, error, isLoading, isRefetching,
  } = useQuery(
    [QueryKey.MENUS, start, end],
    () => MenusService.GetAll({
      'sort[field]': 'name',
      'sort[type]': 'asc',
      'filter[date_from]': standardFormatFromDate(start),
      'filter[date_to]': standardFormatFromDate(end),
    }),
    { keepPreviousData: true },
  );
  useEffect(() => {
    const obj: Record<string, Menu> = {};
    queryData?.data?.data?.forEach((menu) => {
      obj[menu.date] = menu;
    });
    setMapData(obj);
  }, [queryData]);

  return {
    menusData: mapData,
    menusIsLoading: isLoading,
    menusIsRefetching: isRefetching,
    menusIsError: isError,
    menusError: error,
  };
}
