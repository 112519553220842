import { CalendarResponse, SingleDateResponse } from '../../types/Calendar';
import { PaginationQueryParams } from '../../types/Pagination';
import { APIService } from '../utils';

const endpoint = 'calendar';

export const CalendarService = {
  GetAll: async (params?: PaginationQueryParams) => new APIService<CalendarResponse>(endpoint).getAll(params),
  GetOne: async (date: string) => new APIService<SingleDateResponse>(endpoint).getOne(date),
};
