// eslint-disable-next-line
import { useAuthStore } from '../../store/AuthStore';

const apiUrl = process.env.REACT_APP_BASE_URL;

const PrefixURL = (url: string) => `${apiUrl}/api${url}`;

function logout() {
  useAuthStore.getState().logout();
  const url = `${window.location.origin}/login`;
  if (window.location.href !== url) {
    window.location.assign(url);
  }
}

export async function RefreshWrapper(
  url: string,
  options?: RequestInit,
): Promise<Response> {
  const response = await fetch(PrefixURL(url), options);
  /**
   * try refreshing the token ONLY if this req isn't:
   * - hitting the refresh endpoint
   * - and response code is 401
   * */
  if (response.status === 401) {
    const refresh = await fetch(PrefixURL('/auth/refresh'), { method: 'POST', credentials: 'include' });
    if (refresh.status === 401) {
      // logout
      logout();
      return refresh;
    }
    return fetch(PrefixURL(url), options);
  }
  return response;
}
