import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useMeal } from '../../../framework/hooks/api/Meals/useMeal';
import { useMealTypes } from '../../../framework/hooks/api/MealTypes/useMealTypes';
import { Title } from '../../atoms/Title';
import { EditMeal } from '../../organisms/meals/EditMeal';
import { FetchUi } from '../../utils/FetchUi';
import { MealFormSkeleton } from '../../utils/skeletons/MealFormSkeleton';

export function MealPage() {
  const { id } = useParams();
  const { state } = useLocation();
  const {
    data, isLoading, // isError, error,
  } = useMeal(id!);
  const {
    mealTypesData,
    mealTypesIsLoading,
    // mealTypesIsError,
    // mealTypesError,
  } = useMealTypes();
  const meal = data?.data;
  return (
    <Box sx={{
      height: {
        xs: '100%', md: 'auto',
      },
      px: { xs: 0, md: 8 },
      maxWidth: '100%',
    }}
    >
      <Title title="Meal" />
      <FetchUi isLoading={(isLoading || mealTypesIsLoading) && !meal} loadingUi={<MealFormSkeleton />} error={null}>
        <EditMeal
          meal={meal}
          stateEditMode={state?.editMode}
          mealTypesData={mealTypesData?.data?.data}
        />
      </FetchUi>
    </Box>
  );
}
