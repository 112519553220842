import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { GenericTrimmedRequiredString } from '../../../../utils/zodHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersService } from '../../../services/api/UsersService';
import { FamilyMember } from '../../../types/FamilyMember';

export function useCreateOrUpdateFamily(userId: number) {
  const [dialogMode, setDialogMode] = useState(false);
  const [editModeItem, setEditModeItem] = useState<FamilyMember>(new FamilyMember());

  const schema = z.object({
    last_name: GenericTrimmedRequiredString,
    first_name: GenericTrimmedRequiredString,
    dob: z.date().or(z.string()),
    gender: GenericTrimmedRequiredString,
    relationship_type: GenericTrimmedRequiredString,
    phone: z.string().trim(),
    is_emergency_contact: z.boolean(),
  }).partial({ is_emergency_contact: true }).superRefine((values, ctx) => {
    if (values.is_emergency_contact === true && values.phone.trim().length === 0) {
      ctx.addIssue({
        message: 'This field is required',
        code: z.ZodIssueCode.custom,
        path: ['phone'],
      });
    }
  });

  const {
    register, handleSubmit, formState: { errors }, control,
  } = useForm<FamilyMember>({
    resolver: zodResolver(schema),
    values: editModeItem,
  });

  const openDialogMode = (editMember?: FamilyMember) => {
    setDialogMode(true);
    if (editMember) {
      setEditModeItem(new FamilyMember(editMember));
    } else {
      setEditModeItem(new FamilyMember());
    }
  };
  const closeDialogMode = () => {
    setDialogMode(false);
    setEditModeItem(new FamilyMember());
  };
  const queryClient = useQueryClient();
  const {
    isLoading: isCreateLoading, mutate: create, // isError: isCreateError,
  } = useMutation(
    (formData: FamilyMember) => {
      const dob = formData.dob ? standardFormatFromDate(new Date(formData.dob)) : '';
      return UsersService.FamilyCreate(userId, { ...formData, dob });
    },
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          queryClient.invalidateQueries([QueryKey.USERS]);
          queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
          closeDialogMode();
        }
      },
    },
  );
  const {
    isLoading: isUpdateLoading, mutate: update,
  } = useMutation(
    (formData: FamilyMember) => {
      const dob = formData.dob ? standardFormatFromDate(new Date(formData.dob)) : '';
      return UsersService.FamilyUpdate(editModeItem?.id!, { ...formData, dob });
    },
    {
      onSuccess: (responseData) => {
        if (responseData.success) {
          queryClient.invalidateQueries([QueryKey.USERS]);
          queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
          closeDialogMode();
        }
      },
    },
  );
  const onSubmit = editModeItem?.id
    ? handleSubmit((formData) => update(formData))
    : handleSubmit((formData) => create(formData));
  return {
    dialogMode, editModeItem, openDialogMode, closeDialogMode, register, control, errors, isLoading: isCreateLoading || isUpdateLoading, onSubmit,
  };
}
