import { zodResolver } from '@hookform/resolvers/zod';
import { parse } from 'date-fns';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { religiousDateFormat } from '../../../../utils/dateHelper';
import { expandWorkExperience } from '../../../../utils/workExperienceHelper';
import { GenericTrimmedRequiredString } from '../../../../utils/zodHelper';
import { PersonalData, PersonalDataForm } from '../../../types/PersonalData';

export function usePersonalDataSchema(personal: PersonalData) {
  const schema = z.object({
    pin: GenericTrimmedRequiredString,
    lbo: GenericTrimmedRequiredString,
    dob: z.date().or(z.string()),
    avatar: GenericTrimmedRequiredString,
    work_experience_years: z.number().gte(0),
    work_experience_months: z.number().gte(0).lte(11, 'The number of months cannot be higher than 11'),
    marriage_status: GenericTrimmedRequiredString,
    phone: GenericTrimmedRequiredString,
    address: GenericTrimmedRequiredString,
    city: GenericTrimmedRequiredString,
    country: GenericTrimmedRequiredString,
    address_id_card: GenericTrimmedRequiredString,
    city_id_card: GenericTrimmedRequiredString,
    country_id_card: GenericTrimmedRequiredString,
    county_id_card: GenericTrimmedRequiredString,
    edu_degree: GenericTrimmedRequiredString,
    bank_account: z.union([z.string().trim(), z.null()]).optional(),
    personal_email: GenericTrimmedRequiredString,
    religious_date: z.date().or(z.string()).or(z.null()),
    passport_number: z.union([z.string().trim(), z.null()]).optional(),
  });
  const { work_experience_months, work_experience_years } = expandWorkExperience(personal.work_experience);
  const religious_date = personal.religious_date && parse(personal.religious_date, religiousDateFormat, new Date());
  const personalValues = {
    ...personal, work_experience_months, work_experience_years,
  };
  if (religious_date) {
    personalValues.religious_date = religious_date.toDateString();
  }
  const {
    register, handleSubmit, formState: { errors }, setValue, control, setError, reset,
  } = useForm<PersonalDataForm>({
    resolver: zodResolver(schema),
    values: personalValues,
  });
  return {
    register, handleSubmit, errors, setValue, control, setError, reset,
  };
}
