import {
  TextField, InputAdornment, IconButton,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  Noop, RefCallBack,
} from 'react-hook-form';

export function SearchInput({
  name, value, onChange, onBlur, inputRef, invalid, errorText, onReset,
}: {
  name: string,
  value: string,
  onChange: (...event: any[]) => void,
  onBlur?: Noop,
  inputRef?: RefCallBack,
  invalid?: boolean,
  errorText?: string | undefined,
  onReset?: () => void,
}) {
  return (
    <TextField
      label="Search"
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={inputRef}
      name={name}
      error={invalid}
      helperText={errorText}
      InputProps={{
        endAdornment:
          (
            <InputAdornment position="start">
              <IconButton
                onClick={() => { if (onReset) onReset(); }}
                className="clearBtn"
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                aria-label="toggle password visibility"
                type="submit"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
      }}
      sx={{
        width: '100%',
        '.clearBtn': {
          visibility: 'hidden',
          pointerEvents: 'none',
        },
        '&:hover .clearBtn': {
          visibility: 'visible',
          pointerEvents: 'all',
        },
      }}
    />
  );
}
