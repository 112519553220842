import { standardFormatFromDate } from '../../utils/dateHelper';
import { Relationships } from '../constants/RelationshipOptions';
import { UserQueryFilter } from './UsersQueryFilter';

type UsersAPIQueryFilter = {
  started_at_from: string | undefined,
  started_at_to: string | undefined,
  ended_at_from: string | undefined,
  ended_at_to: string | undefined,
  ended_at: string | undefined,
  gender: string | undefined,
  'personal.dob_from': string | undefined,
  'personal.dob_to': string | undefined,
  'personal.edu_degree': string | undefined,
  id: string | undefined,
  'position.id': string | undefined,
  'family.relationship_type': string | undefined,
  'count.family_from': string | undefined,
  'personal.marriage_status': string | undefined,
};

type UsersAPIQuerySearch = {
  first_name: string | undefined;
  last_name: string | undefined;
};

type UsersAPIQueryObject = {
  search: UsersAPIQuerySearch,
  filter: UsersAPIQueryFilter,
};

function getArrayOfObjects<T extends Record<keyof T, string | undefined>>(prop: T): Array<Record<string, string | undefined>> {
  const array: Array<Record<string, string | undefined>> = [];
  const keys = Object.keys(prop) as Array<keyof T>;
  keys.forEach((key) => {
    if (key) {
      array.push({ [key]: prop[key] });
    }
  });
  return array;
}

export class UsersAPIQuery {
  query: UsersAPIQueryObject;

  public get getFilterQuery() : Record<string, string | undefined> {
    const queryObj: Record<string, string> = {};
    const keys = Object.keys(this.query) as Array<keyof UsersAPIQueryObject>;
    keys.forEach((key) => {
      const deepKeys = Object.keys(this.query[key]) as Array<keyof UsersAPIQuerySearch & keyof UsersAPIQueryFilter>;
      deepKeys.forEach((deepKey) => {
        queryObj[`${key}[${deepKey}]`] = this.query[key][deepKey];
      });
    });
    return queryObj;
  }

  public get getNoOfFilters() : number {
    const deepKeys = Object.keys(this.query.filter) as Array<keyof UsersAPIQueryFilter>;
    const fil = deepKeys.filter((k) => this.query.filter[k]);
    return fil.length;
  }

  public get getExportFilter() : Array<Record<string, string | undefined>> {
    return getArrayOfObjects<UsersAPIQueryFilter>(this.query.filter);
  }

  public get getExportSearch() : Array<Record<string, string | undefined>> {
    return getArrayOfObjects<UsersAPIQuerySearch>(this.query.search);
  }

  public set setSearch(search: string) {
    this.query.search.first_name = search || undefined;
    this.query.search.last_name = search || undefined;
  }

  constructor(query?: UserQueryFilter) {
    this.query = {
      search: {
        first_name: query?.search || undefined,
        last_name: query?.search || undefined,
      },
      filter: {
        started_at_from: query?.started_at_from ? standardFormatFromDate(query.started_at_from) : undefined,
        started_at_to: query?.started_at_to ? standardFormatFromDate(query.started_at_to) : undefined,
        ended_at_from: query?.ended_at_from ? standardFormatFromDate(query.ended_at_from) : undefined,
        ended_at_to: query?.ended_at_to ? standardFormatFromDate(query.ended_at_to) : undefined,
        ended_at: query?.ended_at || undefined,
        'personal.dob_from': query?.dob_from ? standardFormatFromDate(query.dob_from) : undefined,
        'personal.dob_to': query?.dob_to ? standardFormatFromDate(query.dob_to) : undefined,
        gender: query?.gender || undefined,
        'personal.edu_degree': query?.edu_level?.map((lvl) => lvl.value).join() || undefined,
        id: query?.users?.map((user) => user.id).join() || undefined,
        'position.id': query?.position?.map((pos) => pos.id).join() || undefined,
        // personal.work_experience_from: query?.work_experience_from || undefined,
        // personal.work_experience_to: query?.work_experience_to || undefined,
        'personal.marriage_status': query?.relationship_status?.map((rs) => rs.value).join() || undefined,
        'family.relationship_type': query?.has_children === 'true' ? Relationships.CHILD.value : '',
        'count.family_from': '',
      },
    };
  }
}
