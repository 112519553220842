export const userTableFields = {
  ID: 'id',
  EMPLOYEE: 'employee',
  JOB_TITLE: 'job_title',
  LBO: 'lbo',
  PHONE: 'phone',
  ADDRESS: 'address',
  ACTUAL_ADDRESS: 'actual_address',
  STARTED_AT: 'contract_started',
  ENDED_AT: 'contract_ends',
  EDUCATION: 'education_level',
  GENDER: 'gender',
  DOB: 'date_of_birth',
  AGE: 'age',
  PIN: 'identity_number',
  PASSPORT: 'passport',
  BANK: 'bank_account',
  EMAIL: 'email',
  WORK_EXP: 'work_experience',
  WORK_EXP_SYM: 'work_experience_symphony',
  WORK_EXP_TOTAL: 'work_experience_total',
  REL_DATE: 'religious_date',
  NICKNAME: 'nickname',
  STATUS: 'marriage_status',
  PARTNER_NAME: 'partner_name',
  PARTNER_DOB: 'partner_date_of_birth',
  EC_NAME: 'emergency_contact_name',
  EC_PHONE: 'emergency_contact_phone',
  VEHICLE: 'vehicle',
  FUEL_TYPE: 'fuel_type',
  AVG_CONSUMPTION: 'avg_consumption',
  REG_NUMBER: 'reg_number',
  NUM_CHILDREN: 'number_of_children',
  CHILDREN: 'children',
};

export const userTableOptions = {
  [userTableFields.ID]: {
    sort: 'core_tool_user_id',
  },
  [userTableFields.EMPLOYEE]: {
    sort: 'first_name',
  },
  [userTableFields.LBO]: {
    sort: 'lbo',
  },
  [userTableFields.STARTED_AT]: {
    sort: 'started_at',
  },
  [userTableFields.ENDED_AT]: {
    sort: 'ended_at',
  },
  [userTableFields.DOB]: {
    sort: 'personal.dob',
  },
  [userTableFields.EMAIL]: {
    sort: 'email',
  },
  // [userTableFields.NUM_CHILDREN]: {
  //   sort: 'family.count_children', // TODO ???
  // },
};
