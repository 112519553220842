import { Dialog } from '@mui/material';
import { useCreateOrUpdateAllergens } from '../../../framework/hooks/api/Allergens/useCreateOrUpdateAllergens';
import { AllergenRequest } from '../../../framework/types/Allergens';
import { AllergenForm } from './AllergenForm';

export function AddNewAllergenDialog({ show, close, defaultValues }: { show: boolean, close: () => void, defaultValues: AllergenRequest }) {
  const createContext = useCreateOrUpdateAllergens({ defaultValues, closeModeOverride: close });
  return (
    <Dialog open={show} onClose={close}>
      <AllergenForm {...createContext} />
    </Dialog>
  );
}
