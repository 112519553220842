import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  error?: Error | null,
  isLoading: boolean,
  loadingUi?: ReactNode,
  children: ReactNode,
};
export function FetchUi({
  error, isLoading, loadingUi, children,
}: Props) {
  if (isLoading) {
    if (loadingUi) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{loadingUi}</>;
    }
    return <CircularProgress />;
  }
  if (error) {
    return (
      <div className="error">{error.message}</div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
