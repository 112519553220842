import { createBrowserRouter } from 'react-router-dom';
import { BaseLayout } from '../../components/layouts/BaseLayout';
import { DashboardContainer } from '../../components/layouts/DashboardContainer';
import { UsersPage } from '../../components/pages/users/UsersPage';
import { LoginPage } from '../../components/pages/auth/LoginPage';
import Token from '../../components/pages/auth/Token';
import { MealsPage } from '../../components/pages/meals/MealsPage';
import { UserPage } from '../../components/pages/users/UserPage';
import { MenusPage } from '../../components/pages/meals/MenusPage';
import { CalendarPage } from '../../components/pages/calendar/CalendarPage';
import { MealPage } from '../../components/pages/meals/MealPage';
import { CreateMealPage } from '../../components/pages/meals/CreateMealPage';
import { AuthGuard } from '../../components/utils/AuthGuard';
import { Roles } from '../constants/roles';
import { AllergensPage } from '../../components/pages/allergens/AllergensPage';
import { CheckinsPage } from '../../components/pages/checkins/CheckinsPage';
import { NotFound } from '../../components/pages/NotFound';

const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/admin',
        element: <AuthGuard roles={[Roles.ADMIN, Roles.MEAL_PROVIDER]} redirectToHome><DashboardContainer /></AuthGuard>,
        children: [
          {
            path: 'users',
            element: <AuthGuard roles={[Roles.ADMIN]} redirectToHome><UsersPage /></AuthGuard>,
          },
          {
            path: 'users/:user_id',
            element: <AuthGuard roles={[Roles.ADMIN]} redirectToHome><UserPage /></AuthGuard>,
          },
          {
            path: 'checkins',
            element: <AuthGuard roles={[Roles.ADMIN]} redirectToHome><CheckinsPage /></AuthGuard>,
          },
          {
            path: 'meals',
            element: <AuthGuard roles={[Roles.ADMIN, Roles.MEAL_PROVIDER]} redirectToHome><MealsPage /></AuthGuard>,
          },
          {
            path: 'meal/create',
            element: <AuthGuard roles={[Roles.ADMIN, Roles.MEAL_PROVIDER]} redirectToHome><CreateMealPage /></AuthGuard>,

          },
          {
            path: 'menus',
            element: <AuthGuard roles={[Roles.ADMIN, Roles.MEAL_PROVIDER]} redirectToHome><MenusPage /></AuthGuard>,
          },
          {
            path: 'allergens',
            element: <AuthGuard roles={[Roles.ADMIN, Roles.MEAL_PROVIDER]} redirectToHome><AllergensPage /></AuthGuard>,
          },
        ],
      },
      {
        path: 'calendar',
        element: <DashboardContainer />,
        children: [
          {
            path: '',
            element: <CalendarPage />,
          },
        ],
      },
      {
        path: 'profile',
        element: <DashboardContainer />,
        children: [
          {
            path: '',
            element: <UserPage />,
          },
        ],
      },
      {
        path: 'meal/:id',
        element: <DashboardContainer />,
        children: [
          {
            path: '',
            element: <MealPage />,
          },
        ],
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
    ],
  },
  {
    path: '/token',
    element: <Token />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
