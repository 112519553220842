import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { GenericTrimmedRequiredString } from '../../../../utils/zodHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { AllergensService } from '../../../services/api/AllergensService';
import { Allergen, AllergenRequest } from '../../../types/Allergens';

type Props = { defaultValues?: AllergenRequest, closeModeOverride?: (allergen?: Allergen) => void };

export function useCreateOrUpdateAllergens({ defaultValues, closeModeOverride }: Props = {}) {
  const schema = z.object({
    name: GenericTrimmedRequiredString,
  });

  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm<AllergenRequest>({
    resolver: zodResolver(schema),
    values: defaultValues,
  });

  const [dialogMode, setDialogMode] = useState(false);
  const [editModeItem, setEditModeItem] = useState<Allergen | null>(null);

  const openDialogMode = (allergen?: Allergen) => {
    setDialogMode(true);
    if (allergen) {
      setValue('name', allergen.name);
      setEditModeItem(allergen);
    } else {
      setValue('name', '');
    }
  };
  const closeDialogMode = () => {
    setDialogMode(false);
    setEditModeItem(null);
  };
  const closeMode = closeModeOverride || closeDialogMode;
  const queryClient = useQueryClient();

  const {
    isLoading: isCreateLoading, mutate: create, // isError: isCreateError,
  } = useMutation(
    (formData: AllergenRequest) => AllergensService.Create(formData),
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          queryClient.invalidateQueries([QueryKey.ALLERGENS]);
          closeMode(responseData.data);
        }
      },
    },
  );
  const {
    isLoading: isUpdateLoading, mutate: update,
  } = useMutation(
    ({ id, formData }: { id: number, formData: AllergenRequest }) => AllergensService.Update(id, formData),
    {
      onSuccess: (responseData) => {
        if (responseData.data) {
          queryClient.invalidateQueries([QueryKey.ALLERGENS]);
          closeMode(responseData.data);
        }
      },
    },
  );
  const onSubmit = editModeItem?.id
    ? handleSubmit((formData) => update({ id: editModeItem.id, formData }))
    : handleSubmit((formData) => create(formData));

  return {
    dialogMode, editModeItem, openDialogMode, closeDialogMode, register, errors, isLoading: isCreateLoading || isUpdateLoading, onSubmit,
  };
}
