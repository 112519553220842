import {
  List, ListItem, ListItemText, Skeleton,
} from '@mui/material';

export function AllergensSkeleton({ amount }: { amount: number }) {
  return (
    <List>
      {Array.from({ length: amount }, (_, k) => (
        <ListItem key={k}>
          <ListItemText>
            <Skeleton variant="text" width={200} height={20} />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
