import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { StyledLink } from '../../components/molecules/StyledLink';
import { CheckInUser } from '../types/Checkin';
import { CheckinAnswers } from './CheckinAnswers';
import { userTableFields } from './UserTableColumnFields';

export const CheckinsUsersTableColumns: GridColDef<CheckInUser>[] = [
  {
    field: userTableFields.ID,
    headerName: 'ID',
    valueGetter: (params: GridValueGetterParams) => params.row.core_tool_user_id,
  },
  {
    field: userTableFields.EMPLOYEE,
    headerName: 'Employee',
    width: 300,
    renderCell: (params: GridRenderCellParams<CheckInUser>) => (
      <StyledLink to={`/admin/users/${params.row.id}`}>{`${params.row.first_name || ''} ${params.row.last_name || ''}`}</StyledLink>
    ),
    hideable: false,
  },
  {
    field: 'working_days',
    headerName: 'Working Days',
    sortable: false,
    filterable: false,
    width: 200,
  },
  // { field: 'nickname', headerName: 'Nickname' },
  {
    field: 'checked_yes',
    headerName: 'Checkins',
    width: 200,
    valueGetter: (params: GridValueGetterParams<CheckInUser>) => params.row.checkins.filter((c) => c.answer === CheckinAnswers.YES).length,
    hideable: false,
    sortable: false,
    filterable: false,
  },
  {
    field: 'checked_no',
    headerName: 'Checkins - NO',
    width: 200,
    valueGetter: (params: GridValueGetterParams<CheckInUser>) => params.row.checkins.filter((c) => c.answer === CheckinAnswers.NO).length,
    sortable: false,
    filterable: false,
  },
  {
    field: 'percentage_yes',
    headerName: 'Percentage',
    width: 200,
    sortable: false,
    filterable: false,
  },
];
