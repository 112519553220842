import {
  useMediaQuery, Box, useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { useToolbarPortal, useMainPortal } from '../../framework/store/PortalStore';

export function ResponsivePortalButton({ small, large }: { small?: ReactNode, large?: ReactNode }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const actions = useToolbarPortal(
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {large}
    </Box>,
  );
  const mobileAction = useMainPortal(
    small,
  );
  return (
    <div>
      {isMobile && small && mobileAction}
      {!isMobile && large && actions}
    </div>
  );
}
