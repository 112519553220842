import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../constants/QueryKey';
import { PositionsService } from '../../../services/api/PositionsService';

export function usePositions() {
  const {
    data, isLoading, isRefetching, // isError, error,
  } = useQuery(
    [QueryKey.POSITIONS],
    () => PositionsService.GetAll(),
    { keepPreviousData: true },
  );
  return { data, isLoading: isLoading || isRefetching };
}
