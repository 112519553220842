import { Menu, MenuRequest, MenusResponse } from '../../types/Menus';
import { PaginationQueryParams } from '../../types/Pagination';
import { APIService } from '../utils';

const endpoint = 'menus';

export const MenusService = {
  GetAll: async (params: PaginationQueryParams) => new APIService<MenusResponse>(endpoint).getAll(params),
  Create: async (data: MenuRequest) => new APIService(endpoint).post<MenuRequest, Menu>(data),
  Update: async (id: number, data: MenuRequest) => new APIService(endpoint).updateWhole<MenuRequest, Menu>(`${id}`, data),
};
