import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import { EduDegreeOptions } from '../../../framework/constants/EduDegrees';
import { usePersonalDataUpdate } from '../../../framework/hooks/api/Users/usePersonalDataUpdate';
import { User } from '../../../framework/types/User';
import { PersonalDataForm as PersonalDataFormType } from '../../../framework/types/PersonalData';
import { ControlledSelect } from '../../molecules/ControlledSelect';
import { ActionsGroup } from '../../utils/ActionsGroup';
import { FormSection } from '../../utils/FormSection';
import { RelationshipStatusOptions } from '../../../framework/constants/RelationshipOptions';
import { Roles } from '../../../framework/constants/roles';
import { AuthGuard } from '../../utils/AuthGuard';
import { religiousDateFormat } from '../../../utils/dateHelper';
import { ControlledDatePicker } from '../../molecules/ControlledDatePicker';

export function PersonalDataForm({ user }: { user: User }) {
  const [editMode, setEditMode] = useState(false);
  const openMode = () => setEditMode(true);
  const closeModeCallback = () => setEditMode(false);
  const {
    register, errors, isLoading, onSubmit, control, reset,
  } = usePersonalDataUpdate(user, closeModeCallback);
  const closeMode = () => {
    reset();
    setEditMode(false);
  };
  return (
    <Box>
      <form onSubmit={onSubmit}>
        <FormSection
          title="Personal Data"
          actions={(
            <AuthGuard roles={[Roles.ADMIN]}>
              <ActionsGroup mode={editMode} openMode={openMode} closeMode={closeMode} submitLoading={isLoading} />
            </AuthGuard>
          )}
        >
          <ControlledDatePicker<PersonalDataFormType>
            control={control}
            fieldName="dob"
            label="Date of Birth"
            readOnly={!editMode}
          />
          <TextField
            {...register('pin')}
            label="Personal Identification Number"
            error={!!errors.pin}
            helperText={errors.pin?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <TextField
            {...register('lbo')}
            label="LBO"
            error={!!errors.lbo}
            helperText={errors.lbo?.message}
            InputProps={{ readOnly: !editMode }}
          />
        </FormSection>
        <FormSection>
          <TextField
            {...register('passport_number')}
            label="Passport Number"
            error={!!errors.passport_number}
            helperText={errors.passport_number?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <ControlledSelect<{ value: string, label: string }, PersonalDataFormType>
            control={control}
            fieldName="marriage_status"
            options={RelationshipStatusOptions}
            label="Marriage Status"
            readOnly={!editMode}
          />
          <ControlledDatePicker<PersonalDataFormType>
            control={control}
            fieldName="religious_date"
            label="Religious Date"
            readOnly={!editMode}
            views={['day', 'month']}
            inputFormat={religiousDateFormat}
            clearable
          />
        </FormSection>
        <FormSection title="Work Experience">
          <ControlledSelect<{ value: string, label: string }, PersonalDataFormType>
            control={control}
            fieldName="edu_degree"
            options={EduDegreeOptions}
            label="Education Level"
            readOnly={!editMode}
          />
          <TextField
            type="number"
            {...register('work_experience_years', { valueAsNumber: true })}
            label="Work experience (years)"
            error={!!errors.work_experience_years}
            helperText={errors.work_experience_years?.message}
            InputProps={{ readOnly: !editMode, inputProps: { min: 0 } }}
          />
          <TextField
            type="number"
            {...register('work_experience_months', { valueAsNumber: true })}
            label="Work experience (months)"
            error={!!errors.work_experience_months}
            helperText={errors.work_experience_months?.message}
            InputProps={{ readOnly: !editMode, inputProps: { min: 0 } }}
          />
        </FormSection>
        <FormSection title="Personal">
          <TextField
            {...register('phone')}
            label="Phone"
            error={!!errors.phone}
            helperText={errors.phone?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <TextField
            {...register('bank_account')}
            label="Bank Account Number"
            error={!!errors.bank_account}
            helperText={errors.bank_account?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <TextField
            {...register('personal_email')}
            label="Personal Email Address"
            error={!!errors.personal_email}
            helperText={errors.personal_email?.message}
            InputProps={{ readOnly: !editMode }}
          />
        </FormSection>
        <FormSection title="Actual Address">
          <TextField
            {...register('address')}
            label="Address"
            error={!!errors.address}
            helperText={errors.address?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <TextField
            {...register('city')}
            label="City"
            error={!!errors.city}
            helperText={errors.city?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <TextField
            {...register('country')}
            label="Country"
            error={!!errors.country}
            helperText={errors.country?.message}
            InputProps={{ readOnly: !editMode }}
          />
        </FormSection>
        <FormSection title="Address">
          <TextField
            {...register('address_id_card')}
            label="Address in ID Card"
            error={!!errors.address_id_card}
            helperText={errors.address_id_card?.message}
          />
          <TextField
            {...register('city_id_card')}
            label="City in ID Card"
            error={!!errors.city_id_card}
            helperText={errors.city_id_card?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <TextField
            {...register('county_id_card')}
            label="County"
            error={!!errors.county_id_card}
            helperText={errors.county_id_card?.message}
            InputProps={{ readOnly: !editMode }}
          />
          <TextField
            {...register('country_id_card')}
            label="Country in ID Card"
            error={!!errors.country_id_card}
            helperText={errors.country_id_card?.message}
            InputProps={{ readOnly: !editMode }}
          />
        </FormSection>
      </form>
    </Box>
  );
}
