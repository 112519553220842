import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useState } from 'react';

export function useColumnSelection(tableColumns: GridColDef[]) {
  const mappedColumns: Record<string, boolean> = {};
  tableColumns.forEach((f) => {
    mappedColumns[f.field] = true;
  });
  const [columns, setColumns] = useState(mappedColumns);
  const onColumnsChange = (model: GridColumnVisibilityModel) => {
    if (Object.keys(model).length === 0) {
      Object.keys(mappedColumns).forEach((k) => { mappedColumns[k] = true; });
      setColumns(mappedColumns);
    } else {
      setColumns({ ...columns, ...model });
    }
  };
  return {
    columns, onColumnsChange,
  };
}
