import { PaletteMode, ThemeOptions, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const lightTheme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'sm',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 28,
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#7169FF',
    },
    secondary: {
      main: grey[50],
      dark: grey[300],
      contrastText: grey[700],
    },
    success: {
      main: '#81c784',
      light: '#f4fdf5',
    },
    error: {
      main: '#e57373',
      light: '#fef5f5',
    },
    warning: {
      main: '#ffb74d',
    },
  },
});

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'sm',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 28,
        },
      },
    },
  },
  palette: {
    mode,
    primary: {
      main: '#7169FF',
    },
    success: {
      main: '#81c784',
    },
    error: {
      main: '#e57373',
    },
    warning: {
      main: '#ffb74d',
    },
    ...(mode === 'light'
      ? {
        secondary: {
          main: grey[50],
          dark: grey[300],
          contrastText: grey[700],
        },
        background: {
          default: grey[50],
        },
      }
      : {
        secondary: {
          main: '#424242',
          dark: '#303030',
          contrastText: grey[50],
        },
        background: {
          default: '#242424',
          paper: '#242424',
        },
      }),
  },
});
