// eslint-disable-next-line
import { UsersService } from '../services/api/UsersService';
import { AuthService } from '../services/auth/AuthService';
import { User } from '../types/User';
import { StoreWithDevtools } from './StoreWithDevtools';

type AuthState = {
  initialized: boolean;
  loggedIn: boolean;
  user?: User;
  initialize: () => Promise<void>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
};

export const useAuthStore = StoreWithDevtools<AuthState>((set, get) => ({
  initialized: false,
  loggedIn: false,
  initialize: async () => {
    // fetch the user
    const userResponse = await UsersService.GetCurrent();
    if (userResponse.success && userResponse.data) {
      // if user data is retrieved init and log in
      set(() => ({
        initialized: true,
        loggedIn: true,
        user: userResponse.data,
      }));
    } else {
      set(() => ({
        initialized: true,
        loggedIn: false,
        user: undefined,
      }));
    }
  },
  login: async () => {
    // get current user
    const userResponse = await UsersService.GetCurrent();
    if (userResponse.success && userResponse.data) {
      // if user data is retrieved we log in the user
      set(() => ({ loggedIn: true, user: userResponse.data }));
    } else {
      // if the request didn't pass we log out the user
      set(() => ({ loggedIn: false, user: undefined }));
    }
  },
  logout: async () => {
    if (get().loggedIn) {
      const logoutResponse = await AuthService.Logout();
      if (logoutResponse.success) {
        set(() => ({ loggedIn: false, user: undefined }));
      }
    }
  },
}));
