import { LoadingButton } from '@mui/lab';
import {
  Card, CardHeader, CardContent, TextField, CardActions, Button, Checkbox, FormControlLabel, MenuItem,
} from '@mui/material';
import {
  UseFormRegister, FieldErrors, Control, Controller,
} from 'react-hook-form';
import { RelationshipOptions } from '../../../framework/constants/RelationshipOptions';
import { GenderOptions } from '../../../framework/constants/GenderOptions';
import { FamilyMember } from '../../../framework/types/FamilyMember';
import { ControlledDatePicker } from '../../molecules/ControlledDatePicker';

type Props = {
  isLoading: boolean,
  onSubmit: () => void,
  register: UseFormRegister<FamilyMember>,
  errors: FieldErrors<FamilyMember>,
  closeDialogMode: () => void,
  editModeItem: FamilyMember | null,
  control: Control<FamilyMember, any>,
};

export function FamilyForm({
  isLoading, onSubmit, register, control, errors, closeDialogMode, editModeItem,
}: Props) {
  return (
    <Card>
      <form onSubmit={onSubmit}>
        <CardHeader title={editModeItem?.id ? 'Update Family Member' : 'Create Family Member'} />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '20p' }}>
          <TextField {...register('first_name')} label="First Name" error={!!errors.first_name} helperText={errors.first_name?.message} />
          <TextField {...register('last_name')} label="Last Name" error={!!errors.last_name} helperText={errors.last_name?.message} />
          <ControlledDatePicker<FamilyMember>
            control={control}
            fieldName="dob"
            label="Date of Birth"
          />
          <Controller
            name="gender"
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                select
                {...field}
                label="Gender"
                error={invalid}
                helperText={error?.message}
              >
                {GenderOptions.map((gender) => <MenuItem key={gender.value} value={gender.value}>{gender.label}</MenuItem>)}
              </TextField>
            )}
          />
          <Controller
            name="relationship_type"
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                select
                {...field}
                label="Relationship Type"
                error={invalid}
                helperText={error?.message}
              >
                {RelationshipOptions.map((rel) => <MenuItem key={rel.value} value={rel.value}>{rel.label}</MenuItem>)}
              </TextField>
            )}
          />
          <FormControlLabel
            control={(
              <Controller
                name="is_emergency_contact"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} checked={!!field.value} />
                )}
              />
            )}
            label="Emergency Contact"
          />
          <TextField {...register('phone')} label="Phone" error={!!errors.phone} helperText={errors.phone?.message} />
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={closeDialogMode}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>Save</LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
}
