import { LoadingButton } from '@mui/lab';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  open: boolean,
  children?: ReactNode | null,
  cancel: () => void,
  confirm: () => void,
  title?: string,
  noText?: string,
  yesText?: string,
  isLoading?: boolean,
};

export function ConfirmationModal({
  open, children, cancel, confirm, title, noText, yesText, isLoading,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={cancel}
    >
      {title && (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}
      {children && (
        <DialogContent>
          <DialogContentText>
            {children}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={cancel}>{noText || 'No'}</Button>
        <LoadingButton variant="contained" onClick={confirm} autoFocus loading={isLoading}>
          {yesText || 'Yes'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
