import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { QueryKey } from '../../../constants/QueryKey';
import { AllergensService } from '../../../services/api/AllergensService';
import { PaginationQueryParams } from '../../../types/Pagination';
import { useFilter } from '../../useFilter';
import { usePagination } from '../../usePagination';

export function useAllergens() {
  const {
    page, per_page, ListPagination, setCount, setPage,
  } = usePagination();

  const allergensSortProps = [{ label: 'Name', value: 'name' }];

  const {
    sortBy, direction, Sort, SearchField, search, setSearch,
  } = useFilter(allergensSortProps);

  useEffect(() => {
    setPage(1);
  }, [sortBy, direction, search, setPage, per_page]);

  const {
    data, isLoading, isRefetching, // isError, error,
  } = useQuery(
    [QueryKey.ALLERGENS, page, per_page, sortBy, direction, search],
    () => {
      const query: PaginationQueryParams = {
        page, size: per_page, 'sort[field]': `${sortBy}`, 'sort[type]': direction,
      };
      if (search) {
        query['search[name]'] = search;
      }
      return AllergensService.GetAll(query);
    },
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (!isLoading) setCount(data?.data?.metadata?.total_count || 0);
  }, [data, isLoading, setCount]);

  return {
    data, isLoading: isLoading || isRefetching, page, per_page, ListPagination, sortBy, Sort, direction, search, SearchField, setSearch,
  };
}
