import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../../constants/QueryKey';
import { CheckInsService } from '../../../services/api/CheckInsService';

export function useAddCheckins() {
  const queryClient = useQueryClient();

  const {
    isLoading: isCreateLoading, mutate: create, // isError: isCreateError,
  } = useMutation(
    ({ user_id, date }: { user_id: number, date: string; }) => CheckInsService.Create({ answer: 'yes', dates: [date] }, `${user_id}`),
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          queryClient.invalidateQueries([QueryKey.SINGLE_DATE]);
          queryClient.invalidateQueries([QueryKey.CALENDAR]);
        }
      },
    },
  );

  const {
    isLoading: isUpdateLoading, mutate: update, // isError: isUpdateError,
  } = useMutation(
    ({ checkin_id }: { checkin_id: string; }) => CheckInsService.Update(Number(checkin_id), { answer: 'yes' }),
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          queryClient.invalidateQueries([QueryKey.SINGLE_DATE]);
          queryClient.invalidateQueries([QueryKey.CALENDAR]);
        }
      },
    },
  );
  return {
    isCreateLoading,
    create,
    isUpdateLoading,
    update,
  };
}
