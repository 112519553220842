import { MenuMap } from '../framework/types/Menus';

export function parseMenuMealsIntoIdsArray(mealsObj?: MenuMap) {
  const idsArray: number[] = [];
  if (mealsObj) {
    Object.keys(mealsObj).forEach((key: string) => {
      mealsObj[Number(key)].forEach((meal) => {
        idsArray.push(meal.id);
      });
    });
  }
  return idsArray;
}
