import { useEffect } from 'react';

export default function Token() {
  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage({ tokenSent: true });
      window.close();
    }
  });
  return <p>Please wait...</p>;
}
