import { LoadingButton } from '@mui/lab';
import { ReactNode } from 'react';

export function CheckinButton({
  isLoading, type, children, clickHandler, selected,
}: {
  isLoading?: boolean,
  type: 'success' | 'error',
  children: ReactNode,
  clickHandler: () => void,
  selected: boolean,
}) {
  const color = `${type}.main`;
  return (
    <LoadingButton
      loading={isLoading}
      variant={selected ? 'contained' : 'contained'}
      onClick={clickHandler}
      disableElevation
      className={`${selected ? 'selected' : ''}`}
      sx={{
        bgcolor: selected ? color : 'transparent',
        color: selected ? 'white' : 'secondary.contrastText',
        boxShadow: 1,
        px: 4,
        '&.selected': {
          pointerEvents: 'none',
        },
        '&:hover': {
          bgcolor: color,
          color: 'white',
          boxShadow: 1,
        },
      }}
    >
      {children}
    </LoadingButton>
  );
}
