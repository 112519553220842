import { FieldValues, Path, UseFormSetError } from 'react-hook-form';

export function parseResponseFormErrors<T extends FieldValues>(errors: Record<keyof T, string[]>, setError: UseFormSetError<T>) {
  const keys = Object.keys(errors) as Array<keyof T>;
  keys.forEach((errKey) => {
    const errMsg = errors ? errors[errKey].join(', ') : '';
    setError(errKey as Path<T>, {
      type: 'server',
      message: errMsg,
    });
  });
}
