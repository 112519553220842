import { PaletteMode } from '@mui/material';
import { StoreWithDevtools } from './StoreWithDevtools';
import { LocalStorageManager, LocalStorageKeys } from '../../utils/localStorageHelper';

type ThemeState = {
  mode: PaletteMode;
  setMode: (mode: PaletteMode) => void;
};

export const useThemeStore = StoreWithDevtools<ThemeState>((set) => ({
  mode: 'light',
  setMode: (newMode: PaletteMode) => {
    LocalStorageManager.set(LocalStorageKeys.THEME_MODE, newMode);
    set(() => ({
      mode: newMode,
    }));
  },
}));
