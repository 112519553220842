import { MealType, MealTypeRequest, MealTypeResponse } from '../../types/MealTypes';
import { PaginationQueryParams } from '../../types/Pagination';
import { APIService } from '../utils';

const endpoint = 'meal-types';

export const MealTypesService = {
  GetAll: async (params: PaginationQueryParams) => new APIService<MealTypeResponse>(endpoint).getAll(params),
  Create: async (data: MealTypeRequest) => new APIService(endpoint).post<MealTypeRequest, MealType>(data),
  Update: async (id: number, data: MealTypeRequest) => new APIService(endpoint).update<MealTypeRequest, MealType>(`${id}`, data),
  Delete: async (id: number) => new APIService(endpoint).delete(`${id}`),
};
