import { Allergen, AllergenRequest, AllergensResponse } from '../../types/Allergens';
import { PaginationQueryParams } from '../../types/Pagination';
import { APIService } from '../utils';

const endpoint = 'allergens';

export const AllergensService = {
  GetAll: async (params: PaginationQueryParams) => new APIService<AllergensResponse>(endpoint).getAll(params),
  Create: async (data: AllergenRequest) => new APIService(endpoint).post<AllergenRequest, Allergen>(data),
  Update: async (id: number, data: AllergenRequest) => new APIService(endpoint).updateWhole<AllergenRequest, Allergen>(`${id}`, data),
  Delete: async (id: number) => new APIService(endpoint).delete(`${id}`),
};
