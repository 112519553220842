import {
  Card, CardHeader, Box, CardContent, Button,
} from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { addDays, differenceInBusinessDays } from 'date-fns';
import { CheckinAnswers } from '../../../framework/constants/CheckinAnswers';
import { CheckinsUsersTableColumns } from '../../../framework/constants/CheckinsUsersTableColumns';
import { userTableOptions } from '../../../framework/constants/UserTableColumnFields';
import { useUserCheckins } from '../../../framework/hooks/api/Checkins/useUserCheckins';
import { CheckInUser } from '../../../framework/types/Checkin';
import { DateFilter } from '../../../framework/types/Filters';
import { ControlledDatePicker } from '../../molecules/ControlledDatePicker';
import DataTable from '../../utils/datatable/DataTable';
import { ResponsiveExportButton } from '../../atoms/ResponsiveExportButton';
import { Title } from '../../atoms/Title';

export function CheckinsPage() {
  const {
    data, isLoading, ListPaginationComponent, onSubmit, control, dates, setSortBy, setDirection, onColumnsChange, onExport, isExportLoading,
  } = useUserCheckins();
  const onSortChange = (model: GridSortModel) => {
    if (model.length) {
      const singleModel = model[0];
      setSortBy(userTableOptions[singleModel.field].sort);
      setDirection(singleModel.sort || 'asc');
    }
  };
  const mappedData = data?.data?.data?.map((user) => {
    if (dates.start && dates.end) {
      const working_days = differenceInBusinessDays(addDays(dates.end, 1), dates.start);
      const yesNo = user.checkins.filter((a) => a.answer === CheckinAnswers.YES).length;
      const percentage = ((yesNo * 100) / working_days).toFixed(2);
      const percentage_yes = `${working_days > 0 ? percentage : 0}%`;
      return { ...user, working_days, percentage_yes };
    }
    return user;
  });
  const disabledExport = mappedData?.length === 0;
  return (
    <Box sx={{ px: { xs: 0, md: 8 }, maxWidth: '100%' }}>
      <Title title="Checkins" />
      <Card>
        <CardHeader
          title={(
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }} />
              <form onSubmit={onSubmit}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '20px' }}>
                  <ControlledDatePicker<DateFilter>
                    control={control}
                    fieldName="start"
                    label="From"
                  />
                  <ControlledDatePicker<DateFilter>
                    control={control}
                    fieldName="end"
                    label="To"
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button type="submit" variant="contained" sx={{ width: { xs: '100%', md: 'auto' } }}>Get</Button>
                  </Box>
                </Box>
              </form>
            </Box>
          )}
        />
        <CardContent>
          <DataTable<CheckInUser>
            columns={CheckinsUsersTableColumns}
            rows={mappedData || []}
            paginationComponent={ListPaginationComponent}
            isLoading={isLoading && !!dates.start && !!dates.end}
            onSortChange={onSortChange}
            onColumnVisibilityChange={onColumnsChange}
          />
        </CardContent>
        <ResponsiveExportButton isLoading={isExportLoading} disabled={disabledExport} onExport={onExport} />
      </Card>
    </Box>
  );
}
