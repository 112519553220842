import { ExportRequest } from '../../types/ExportRequest';
import { FamilyMember } from '../../types/FamilyMember';
import { HubRequest } from '../../types/Hubs';
import { PaginationQueryParams } from '../../types/Pagination';
import { PersonalData, PersonalDataResponse } from '../../types/PersonalData';
import { RoleRequest } from '../../types/Role';
import {
  User, UserAllergenRequest, UserAllergensResponse, UserBasicInfoRequest, UserBasicInfoResponse, UsersResponse,
} from '../../types/User';
// eslint-disable-next-line
import { APIService } from '../utils';

const endpoint = 'users';

export const UsersService = {
  GetAll: async (params: PaginationQueryParams) => new APIService<UsersResponse>(endpoint).getAll(params),
  GetOne: async (id: number | string) => new APIService<User>(endpoint).getOne(String(id)),
  GetCurrent: async () => new APIService<User>(`${endpoint}/profile`).get(),
  UpdateBasicInfo:
    async (id: number, data: UserBasicInfoRequest) => new APIService<UsersResponse>(endpoint)
      .updateWhole<UserBasicInfoRequest, UserBasicInfoResponse>(`${id}`, data),
  UpdatePersonalData:
    async (id: number, data: PersonalData) => new APIService<UsersResponse>(endpoint)
      .update<PersonalData, PersonalDataResponse>(`${id}/profile`, data),
  UpdateRole: async (id: number, data: RoleRequest) => new APIService<UsersResponse>(endpoint).update(`${id}/role`, data),
  UpdateHub: async (id: number, data: HubRequest) => new APIService<UsersResponse>(endpoint).update(`${id}/hub`, data),
  FamilyCreate: async (id: number, data: FamilyMember) => new APIService<UsersResponse>(`${endpoint}/${id}/family`).post(data),
  FamilyUpdate: async (id: number, data: FamilyMember) => new APIService<UsersResponse>(`${endpoint}/family`).updateWhole(`${id}`, data),
  FamilyDelete: async (id: number) => new APIService(`${endpoint}/family`).delete(`${id}`),
  GetAllergens: async (id: number) => new APIService<UserAllergensResponse>(`${endpoint}/${id}/allergens`).getAll(),
  UpdateAllergens:
    async (id: number, data: UserAllergenRequest) => new APIService<UserAllergensResponse>(`${endpoint}/${id}/allergens`).updateWhole('', data),
  Export: async (data: ExportRequest) => new APIService(`${endpoint}/export`).export(data),
};
