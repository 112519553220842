import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, FormEventHandler } from 'react';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { parseMenuMealsIntoIdsArray } from '../../../../utils/menusHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { MenusService } from '../../../services/api/MenusService';
import { Meal } from '../../../types/Meals';
import { Menu, MenuMap, MenuRequest } from '../../../types/Menus';
// TODO REFACTOR USING REACT HOOK FORM
export function useMenu() {
  const [singleDateDialog, setSingleDateDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const closePopup = () => {
    setSingleDateDialog(false);
    setSelectedDate(null);
    setIsDirty(false);
  };

  const [mealsValue, setMealsValue] = useState<MenuMap | undefined>();

  const meal_ids = parseMenuMealsIntoIdsArray(mealsValue);

  const error = meal_ids.length === 0 && isDirty && 'You should add at least one meal';

  const updateMealsValues = (newValues: Meal[] | undefined, mealTypeId: number) => {
    setIsDirty(true);
    let spreadObj = {};
    if (mealsValue) {
      spreadObj = mealsValue;
    }
    const newValuesObj = { ...spreadObj, [mealTypeId]: newValues || [] };
    setMealsValue(newValuesObj);
  };

  const queryClient = useQueryClient();

  const {
    isLoading: isCreateLoading, mutate: create, // isError: isCreateError,
  } = useMutation(
    (formData: MenuRequest) => MenusService.Create(formData),
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          closePopup();
        }
        queryClient.invalidateQueries([QueryKey.MENUS]);
      },
    },
  );

  const {
    isLoading: isUpdateLoading, mutate: update, // isError: isUpdateError,
  } = useMutation(
    ({ id, formData }: { id: number, formData: MenuRequest }) => MenusService.Update(id, formData),
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          closePopup();
        }
        queryClient.invalidateQueries([QueryKey.MENUS]);
      },
    },
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsDirty(true);
    if (mealsValue) {
      if (selectedDate) {
        const date = standardFormatFromDate(selectedDate);
        if (selectedMenu && selectedDate) {
          update({ id: selectedMenu.id, formData: { date, meal_ids } });
        } else {
          create({ date, meal_ids });
        }
      }
    }
  };
  return {
    mealsValue,
    setMealsValue,
    onSubmit,
    isSubmitLoading: isCreateLoading || isUpdateLoading,
    updateMealsValues,
    closePopup,
    singleDateDialog,
    setSingleDateDialog,
    selectedDate,
    setSelectedDate,
    selectedMenu,
    setSelectedMenu,
    error,
  };
}
