import { LoadingButton } from '@mui/lab';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { LoadingIconButton } from './LoadingIconButton';
import { ResponsivePortalButton } from './ResponsivePortalButton';

export function ResponsiveExportButton({ isLoading, onExport, disabled }: { isLoading?: boolean, onExport: () => void, disabled?: boolean }) {
  const actions = (
    <LoadingButton
      color="secondary"
      onClick={() => onExport()}
      variant="contained"
      sx={{ width: { xs: '100%', md: 'auto' } }}
      endIcon={<FileDownloadIcon />}
      size="large"
      loading={isLoading}
      disabled={disabled}
    >
      Export
    </LoadingButton>
  );
  const mobileAction = (
    <LoadingIconButton isLoading={isLoading} disabled={disabled} onClick={() => onExport()} color="primary">
      <FileDownloadIcon />
    </LoadingIconButton>
  );
  return (
    <ResponsivePortalButton small={mobileAction} large={actions} />
  );
}
