import {
  CssBaseline, createTheme, useMediaQuery, ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './framework/router';
import { getDesignTokens } from './utils/muiPalette';
import { useThemeStore } from './framework/store/ThemeStore';
import { LocalStorageKeys, LocalStorageManager } from './utils/localStorageHelper';

const LocalQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

export function App() {
  const { mode, setMode } = useThemeStore();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const defaultMode = prefersDarkMode ? 'dark' : 'light';
  useEffect(() => {
    const newMode = LocalStorageManager.get(LocalStorageKeys.THEME_MODE) || defaultMode;
    LocalStorageManager.set(LocalStorageKeys.THEME_MODE, newMode);
    setMode(newMode);
  }, [defaultMode, setMode]);

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <React.StrictMode>
      <QueryClientProvider client={LocalQueryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <RouterProvider router={router} />
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
