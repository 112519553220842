import { Box } from '@mui/material';
import { useCreateMeal } from '../../../framework/hooks/api/Meals/useCreateMeal';
import { useMealTypes } from '../../../framework/hooks/api/MealTypes/useMealTypes';
import { Title } from '../../atoms/Title';
import { MealForm } from '../../organisms/meals/MealForm';

export function CreateMealPage() {
  const {
    mealTypesData,
    // mealTypesIsLoading,
    // mealTypesIsError,
    // mealTypesError,

  } = useMealTypes();

  const createContext = useCreateMeal();

  return (
    <Box sx={{ px: { xs: 0, md: 8 }, maxWidth: '100%' }}>
      <Title title="Create a new meal" />
      <MealForm meal_types={mealTypesData?.data?.data} {...createContext} createMode />
    </Box>
  );
}
