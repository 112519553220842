import { TextFieldProps, TextField } from '@mui/material';
import { CalendarPickerView, DatePicker } from '@mui/x-date-pickers';
import {
  Control, Controller, FieldValues, Path,
} from 'react-hook-form';
import { prettyDateFormat } from '../../utils/dateHelper';

export function ControlledDatePicker<T extends FieldValues>({
  control, fieldName, label, views, readOnly, inputFormat, clearable,
}: {
  control: Control<T, any>,
  fieldName: Path<T>,
  label?: string, readOnly?: boolean, views?: CalendarPickerView[], inputFormat?: string, clearable?: boolean
}) {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({
        field: {
          onChange, value: fieldValue, name, ref,
        },
        fieldState: {
          invalid, error,
        },
      }) => (
        <DatePicker
          label={label}
          views={views}
          onChange={onChange}
          inputRef={ref}
          value={fieldValue}
          inputFormat={inputFormat || prettyDateFormat}
          readOnly={readOnly}
          disableMaskedInput
          componentsProps={{
            actionBar: {
              actions: clearable ? ['clear', 'accept'] : ['accept'],
            },
          }}
          renderInput={(props: TextFieldProps) => (
            <TextField
              {...props}
              name={name}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      )}
    />
  );
}
