export class Vehicle {
  id: number | undefined;

  user_id: number | undefined;

  model: string;

  reg_number: string;

  fuel_type: string;

  avg_consumption: string;

  constructor(vehicle?: Partial<Vehicle>) {
    this.id = vehicle?.id || undefined;
    this.user_id = vehicle?.user_id || undefined;
    this.model = vehicle?.model || '';
    this.reg_number = vehicle?.reg_number || '';
    this.fuel_type = vehicle?.fuel_type || '';
    this.avg_consumption = vehicle?.avg_consumption || '';
  }
}
export type VehicleResponse = {
  errors?: Record<keyof Vehicle, string[]>
  data: Vehicle
};
