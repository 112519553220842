import CloseIcon from '@mui/icons-material/Close';
import {
  TextField, MenuItem, CircularProgress, IconButton, InputAdornment,
} from '@mui/material';
import {
  Control, Controller, FieldValues, Path,
} from 'react-hook-form';

export function ControlledSelect<R extends { id?: number, name?: string, value?: string, label?: string } | string, T extends FieldValues>({
  fieldName, control, options, label, readOnly, isLoading, getOptionsLabel, clearable,
}: {
  fieldName: Path<T>,
  control: Control<T, any>,
  options: R[],
  label?: string,
  readOnly?: boolean,
  isLoading?: boolean,
  getOptionsLabel?: (option: R) => string,
  clearable?: boolean,
}) {
  return (
    <Controller
      render={({
        field: {
          onChange,
          onBlur, value, name, ref,
        },
        fieldState: {
          invalid, error,
        },
      }) => (
        <TextField
          select
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          name={name}
          error={invalid}
          helperText={error?.message}
          label={label}
          InputProps={{
            readOnly,
            endAdornment: clearable && (
              <InputAdornment position="start" sx={{ mr: 2 }} className="clearBtn">
                <IconButton
                  onClick={() => onChange(null)}
                  edge="start"
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '.clearBtn': {
              visibility: 'hidden',
              pointerEvents: 'none',
            },
            '&:hover .clearBtn': {
              visibility: 'visible',
              pointerEvents: 'all',
            },
          }}
        >
          {!isLoading && options.map((option) => {
            if (typeof option === 'string') {
              return (
                <MenuItem value={option}>{getOptionsLabel ? getOptionsLabel(option) : option}</MenuItem>
              );
            }
            return (
              <MenuItem
                value={option.value || option.id}
                key={option.value || option.id}
              >
                {getOptionsLabel ? getOptionsLabel(option) : option.label || option.name}
              </MenuItem>
            );
          })}
          {isLoading && <CircularProgress />}
        </TextField>
      )}
      name={fieldName}
      control={control}
      rules={{ required: true }}
    />
  );
}
