import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { NavLink } from 'react-router-dom';
import {
  Collapse, List, ListItem,
} from '@mui/material';
import { menuItems, MenuItemType } from './menuItems';
import { AuthGuard } from '../../utils/AuthGuard';

function SingleMenuItem({ item, drawerOpen }: { item: MenuItemType, drawerOpen: boolean }) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem disablePadding sx={{ display: 'block' }}>
        {item.href && (
          <ListItemButton
            component={NavLink}
            to={item.href}
            sx={{
              minHeight: 48,
              justifyContent: drawerOpen ? 'initial' : 'center',
              px: 2.5,
              mr: 2,
              borderTopRightRadius: '25px',
              borderBottomRightRadius: '25px',
              '&.active': {
                bgcolor: 'secondary.main',
                fontWeight: 600,
              },
            }}
          >
            <ListItemIcon sx={{
              minWidth: 0,
              mr: drawerOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        )}
        {!item.href && (
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: drawerOpen ? 'initial' : 'center',
              px: 2.5,
              '&.active': {
                background: '#f5f6fa',
                fontWeight: 600,
              },
            }}
            onClick={handleClick}
          >
            <ListItemIcon sx={{
              minWidth: 0,
              mr: drawerOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        )}
      </ListItem>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ paddingLeft: '10px' }}>
          <List component="div" disablePadding>
            {item.children.map((child) => (
              <SingleMenuItem key={child.href} item={child} drawerOpen={drawerOpen} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export function MenuItemsList({ open }: { open: boolean }) {
  return (
    <>
      {menuItems.map((item) => {
        if (item.authorize) {
          return (
            <AuthGuard roles={item.authorize} key={item.label}>
              <SingleMenuItem item={item} drawerOpen={open} />
            </AuthGuard>
          );
        }
        return (
          <SingleMenuItem item={item} key={item.label} drawerOpen={open} />
        );
      })}
    </>
  );
}
