import { LoadingButton } from '@mui/lab';
import {
  Card, CardHeader, CardContent, TextField, Box, Button,
} from '@mui/material';
import { useState } from 'react';
import {
  UseFormRegister, FieldErrors, Control, UseFormSetValue, UseFormGetValues,
} from 'react-hook-form';
import { Roles } from '../../../framework/constants/roles';
import { useAllergens } from '../../../framework/hooks/api/Allergens/useAllergens';
import { Allergen, AllergenFilterOption, AllergenRequest } from '../../../framework/types/Allergens';
import { MealRequest } from '../../../framework/types/MealRequest';
import { Meal } from '../../../framework/types/Meals';
import { MealType } from '../../../framework/types/MealTypes';
import { ControlledSelect } from '../../molecules/ControlledSelect';
import UpdatedComboBox from '../../molecules/UpdatedComboBox';
import { AuthGuard } from '../../utils/AuthGuard';
import { AddNewAllergenDialog } from '../allergens/AddNewAllergenDialog';

type Props = {
  title?: string,
  meal?: Meal,
  meal_types?: MealType[],
  editMode?: boolean,
  openEditMode?: (item: Meal) => void,
  closeMode?: (meal?: Meal) => void,
  isLoading: boolean,
  onSubmit: () => void,
  control: Control<MealRequest, any>,
  register: UseFormRegister<MealRequest>,
  errors: FieldErrors<MealRequest>,
  createMode?: boolean,
  updateAllergens?: (newValue: (AllergenFilterOption)[] | undefined) => void,
  setValue: UseFormSetValue<MealRequest>,
  getValues: UseFormGetValues<MealRequest>,
};

export function MealForm({
  title,
  meal,
  meal_types,
  editMode,
  openEditMode,
  closeMode,
  isLoading,
  onSubmit,
  control,
  register,
  errors,
  createMode,
  setValue,
  getValues,
}: Props) {
  const onCloseMode = () => closeMode && closeMode();
  const {
    data: allergens, isLoading: isAllergensLoading, setSearch,
  } = useAllergens();
  const newCustomValue = (inputValue: string): AllergenFilterOption => ({
    inputValue,
    id: 0,
    name: `Add new ${inputValue}`,
  });
  const [allergenDialog, setAllergenDialog] = useState(false);
  const [allergenDefaultValues, setAllergenDefaultValues] = useState<AllergenRequest>({ name: '' });
  const openNewAllergenDialog = (inputValue: string) => {
    setAllergenDefaultValues({
      name: inputValue,
    });
    setAllergenDialog(true);
  };
  const closeAllergenDialog = (allergen?: Allergen) => {
    if (allergen) {
      const newAllergens = [...getValues('allergen_ids'), allergen];
      setValue('allergen_ids', newAllergens);
    }
    setAllergenDefaultValues({ name: '' });
    setAllergenDialog(false);
  };
  return (
    <Card sx={{ height: { xs: '100%', md: 'auto' } }}>
      <form onSubmit={onSubmit}>
        <CardHeader title={(
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
            {title || ''}
            <div />
            <AuthGuard roles={[Roles.ADMIN, Roles.MEAL_PROVIDER]}>
              <Box>
                {!editMode && openEditMode && meal && <Button onClick={() => openEditMode(meal)} variant="contained">Edit Meal</Button>}
                {(editMode || createMode) && (
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    {onCloseMode && <Button onClick={onCloseMode}>Cancel</Button>}
                    <LoadingButton type="submit" variant="contained" loading={isLoading}>
                      Save
                    </LoadingButton>
                  </Box>
                )}
              </Box>
            </AuthGuard>
          </Box>
        )}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Box sx={{ flex: '1' }}>
            <TextField
              {...register('name')}
              label="Name"
              size="medium"
              error={!!errors.name}
              helperText={errors.name?.message as string}
              InputProps={{ readOnly: !editMode && !createMode }}
            />
          </Box>
          {meal_types && (
            <ControlledSelect<MealType, MealRequest >
              fieldName="meal_type_id"
              control={control}
              options={meal_types || []}
              label="Meal Type"
              readOnly={!editMode && !createMode}
            />
          )}
          <TextField
            {...register('description')}
            multiline
            rows={5}
            label="Description"
            error={!!errors.description}
            helperText={errors.description?.message as string}
            InputProps={{ readOnly: !editMode && !createMode }}
          />
          <UpdatedComboBox<Allergen, MealRequest>
            name="allergen_ids"
            options={allergens?.data?.data || []}
            isLoading={isAllergensLoading}
            control={control}
            openNewDialog={openNewAllergenDialog}
            onNewCustomValue={newCustomValue}
            label="Allergens"
            setSearch={setSearch}
            readOnly={!editMode && !createMode}
          />
        </CardContent>
      </form>
      <AddNewAllergenDialog show={allergenDialog} close={closeAllergenDialog} defaultValues={allergenDefaultValues} />
    </Card>
  );
}
