import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useUpdateMeal } from '../../../framework/hooks/api/Meals/useUpdateMeal';
import { Meal } from '../../../framework/types/Meals';
import { MealType } from '../../../framework/types/MealTypes';
import { Title } from '../../atoms/Title';
import { MealForm } from './MealForm';

type Props = {
  meal: Meal | undefined,
  stateEditMode: boolean,
  mealTypesData?: MealType[],
};
export function EditMeal({
  meal, stateEditMode, mealTypesData,
}: Props) {
  const updateContext = useUpdateMeal(meal);
  const { openEditMode, editMode } = updateContext;
  useEffect(() => {
    if (stateEditMode && meal) {
      openEditMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meal]);
  return (
    <Box sx={{ height: { xs: '100%', md: 'auto' } }}>
      <Title title={editMode ? 'Edit meal' : 'Meal'} />
      <MealForm
        meal={meal}
        meal_types={mealTypesData}
        {...updateContext}
      />
    </Box>
  );
}
