import { StoreWithDevtools } from './StoreWithDevtools';

type TitleState = {
  title: string;
  setTitle: (title: string) => void;
};

export const useTitleStore = StoreWithDevtools<TitleState>((set) => ({
  title: '',
  setTitle: (newTitle: string) => {
    set(() => ({
      title: newTitle,
    }));
    document.title = `${newTitle}${newTitle ? ' | ' : ''}Symphony Operations`;
  },
}));
