import { Role } from '../framework/types/Role';

export function authGuard(roles: Role[], userRole?: Role) {
  if (userRole) {
    if (roles && roles.find((r) => r.id === userRole.id)) {
      return true;
    }
  }
  return false;
}
