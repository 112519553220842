import {
  ListItem, ListItemButton, ListItemAvatar, Avatar, Box, ListItemText, IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Add, Delete } from '@mui/icons-material';
import { Roles } from '../../../framework/constants/roles';
import { useAuthStore } from '../../../framework/store/AuthStore';
import { authGuard } from '../../../utils/authGuardHelper';

type Props = {
  first_name: string,
  last_name: string,
  avatar: string,
  id: number,
  onDeleteCheckin?: () => void,
  onAddCheckin?: () => void,
};

export function UserItem({
  first_name, last_name, avatar, id, onDeleteCheckin, onAddCheckin,
}: Props) {
  const { user: authUser } = useAuthStore();
  const fullName = `${first_name} ${last_name}`;
  const { user: currentUser } = useAuthStore();
  const isAdminOrMealProvider = authUser ? authGuard([Roles.ADMIN, Roles.MEAL_PROVIDER], authUser.role) : false;

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={`/admin/users/${id}`}
        disabled={!authGuard([Roles.ADMIN], currentUser?.role)}
        sx={{
          '&.Mui-disabled': {
            opacity: 1,
          },
        }}
      >
        <ListItemAvatar>
          <Avatar
            alt={fullName}
            src={avatar || ''}
          />
        </ListItemAvatar>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <ListItemText primary={fullName} />
        </Box>
      </ListItemButton>
      {isAdminOrMealProvider && onDeleteCheckin && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDeleteCheckin();
          }}
        >
          <Delete />
        </IconButton>
      )}
      {isAdminOrMealProvider && onAddCheckin && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onAddCheckin();
          }}
        >
          <Add />
        </IconButton>
      )}
    </ListItem>
  );
}
