import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersService } from '../../../services/api/UsersService';
import { FamilyMember } from '../../../types/FamilyMember';

export function useDeleteFamily(closeMode?: () => void) {
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteModeItem, setDeleteModeItem] = useState<FamilyMember | null>(null);

  const openDeleteMode = (member: FamilyMember) => {
    setDeleteMode(true);
    setDeleteModeItem(member);
  };
  const closeDeleteMode = () => {
    setDeleteMode(false);
    setDeleteModeItem(null);
  };

  const queryClient = useQueryClient();

  const {
    isLoading: deleteLoading, mutate, // isError: isDeleteError,
  } = useMutation(
    (id: number) => UsersService.FamilyDelete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.SINGLE_USER]);
        if (closeMode) {
          closeMode();
        } else {
          closeDeleteMode();
        }
      },
    },
  );
  const deleteFamilyMember = () => {
    if (deleteModeItem?.id) {
      mutate(deleteModeItem.id);
    }
  };
  return {
    deleteMode, deleteModeItem, deleteLoading, deleteFamilyMember, openDeleteMode, closeDeleteMode,
  };
}
