import { useEffect } from 'react';
import { useMeals } from '../../../framework/hooks/api/Meals/useMeals';
import { MealRequest } from '../../../framework/types/MealRequest';
import { Meal } from '../../../framework/types/Meals';
import { MealType } from '../../../framework/types/MealTypes';
import ComboBox from '../ComboBox';

type MealFilterOptions = Meal & { inputValue?: string };

type Props = {
  values: MealFilterOptions[] | undefined,
  updateMealsValues: (newValue: MealFilterOptions[] | undefined, id: number) => void,
  mealType: MealType,
  openNewDialog: (defaultValues: MealRequest) => void;
};

export function MealsCombobox({
  values, updateMealsValues, mealType, openNewDialog,
}: Props) {
  const {
    data, isLoading, setSearch, isRefetching, setMealTypeFilter, search,
  } = useMeals();

  useEffect(() => {
    setMealTypeFilter(mealType.id);
  }, [mealType.id, setMealTypeFilter]);

  const openNewMealDialog = (inputValue: string) => {
    openNewDialog({
      name: inputValue,
      meal_type_id: mealType.id,
      allergen_ids: [],
    });
  };
  const updateValues = (newValue?: MealFilterOptions[]) => {
    updateMealsValues(newValue, mealType.id);
  };
  const newCustomValue = (inputValue: string): MealFilterOptions => ({
    inputValue,
    id: 0,
    name: `Add new ${inputValue}`,
    description: '',
    hub: { id: 0, name: '' },
    meal_type: mealType,
    allergens: [],
  });
  return (
    <ComboBox<MealFilterOptions>
      search={search}
      setSearch={setSearch}
      data={data?.data?.data || []}
      isLoading={isLoading || isRefetching}
      values={values}
      updateValues={updateValues}
      openNewDialog={openNewMealDialog}
      onNewCustomValue={newCustomValue}
      label={mealType.name}
    />
  );
}
