import { MenuDateData } from '../../types/Calendar';
import {
  CheckInRequest, CheckInsResponse, CheckInsUsersResponse, CheckinPostRequest,
} from '../../types/Checkin';
import { ExportRequest } from '../../types/ExportRequest';
import { PaginationQueryParams } from '../../types/Pagination';
import { APIService } from '../utils';

const endpoint = 'checkins';

export const CheckInsService = {
  GetAll: async (params: PaginationQueryParams) => new APIService<CheckInsUsersResponse>(endpoint).getAll(params),
  GetAllForUser:
    async (user_id: string, params?: PaginationQueryParams) => new APIService<CheckInsResponse>(`users/${user_id}/${endpoint}`).getAll(params),
  Create:
    async (data: CheckinPostRequest, user_id?: string) => new APIService(endpoint)
      .post<CheckinPostRequest, Record<string, MenuDateData>>(data, user_id),
  Update:
    async (id: number, data: { answer: string; }) => new APIService(endpoint).update<CheckInRequest, Record<string, MenuDateData>>(`${id}`, data),
  Export: async (data: ExportRequest) => new APIService(`${endpoint}/users/export`).export(data),
  Delete: async (id: string) => new APIService(endpoint).delete(`${id}`),
};
