import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { QueryKey } from '../../../constants/QueryKey';
import { UsersService } from '../../../services/api/UsersService';
import { Allergen } from '../../../types/Allergens';
import { useFilter } from '../../useFilter';

type AllergenIdsArray = { allergen_ids: Allergen[] };

export function useUserAllergens(userId: number) {
  const {
    search, setSearch,
  } = useFilter([]);
  const { data, isLoading, isRefetching } = useQuery(
    [QueryKey.USER_ALLERGENS, search],
    () => UsersService.GetAllergens(userId),
  );

  const allergenSchema = z.object({
    id: z.number(),
    name: z.string(),
  });
  const schema = z.object({
    allergen_ids: z.array(allergenSchema),
  });

  const {
    handleSubmit, formState: { errors }, control, reset, setValue, getValues,
  } = useForm<AllergenIdsArray>({
    resolver: zodResolver(schema),
    values: { allergen_ids: data?.data?.data?.allergens || [] },
  });

  const [editMode, setEditMode] = useState(false);

  const openEditMode = () => setEditMode(true);
  const closeEditMode = () => {
    setEditMode(false);
    reset();
  };

  const queryClient = useQueryClient();

  const {
    isLoading: isUpdateLoading, mutate: update, // isError: isUpdateError,
  } = useMutation(
    (formData: AllergenIdsArray) => {
      const allergen_ids = formData.allergen_ids.map((all) => all.id);
      return UsersService.UpdateAllergens(userId, { allergen_ids });
    },
    {
      onSuccess: (responseData) => {
        if (responseData?.data) {
          closeEditMode();
          queryClient.invalidateQueries([QueryKey.USER_ALLERGENS]);
        }
      },
    },
  );

  const onSubmit = handleSubmit((formData) => update(formData));

  return {
    isLoading: isLoading || isRefetching,
    search,
    setSearch,
    openEditMode,
    closeEditMode,
    onSubmit,
    editMode,
    isUpdateLoading,
    errors,
    control,
    setValue,
    getValues,
  };
}
