import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../constants/QueryKey';
import { RolesService } from '../../../services/api/RolesService';

export function useRoles(isAdmin: boolean) {
  const {
    data, isLoading, isRefetching, // isError, error,
  } = useQuery(
    [QueryKey.ROLES],
    () => RolesService.GetAll(),
    { keepPreviousData: true, enabled: isAdmin },
  );
  return { data, isLoading: isLoading || isRefetching };
}
