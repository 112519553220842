import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import {
  CSSObject, Drawer, styled, Theme, Toolbar, useMediaQuery, useTheme,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { MenuItemsList } from './MenuList';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerLg = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function DrawerContent({ open }: { open: boolean }) {
  return (
    <>
      <Toolbar />
      <Divider />
      <List component="nav">
        <MenuItemsList open={open} />
      </List>
    </>
  );
}
export function Sidebar({ open, closeSidebar }: { open: boolean, closeSidebar: () => void }) {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (isMobile) {
      closeSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {/* !open for Mobile because initially we want the sidebar to be hidden */}
      <Drawer
        variant="persistent"
        open={!open}
        onClose={closeSidebar}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '80vw' },
        }}
      >
        <DrawerContent open={!open} />
      </Drawer>
      <DrawerLg
        variant="permanent"
        open={open}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box' },
        }}
      >
        <DrawerContent open={open} />
      </DrawerLg>
    </>
  );
}
