import { Dialog } from '@mui/material';
import { useCreateMeal } from '../../../framework/hooks/api/Meals/useCreateMeal';
import { useMealTypes } from '../../../framework/hooks/api/MealTypes/useMealTypes';
import { MealRequest } from '../../../framework/types/MealRequest';
import { Meal } from '../../../framework/types/Meals';
import { MealForm } from './MealForm';

export function AddNewMealDialog({ show, close, defaultValues }: { show: boolean, close: (meal?: Meal) => void, defaultValues?: MealRequest }) {
  const {
    mealTypesData,
    // mealTypesIsLoading,
    // mealTypesIsError,
    // mealTypesError,

  } = useMealTypes();

  const createContext = useCreateMeal({ defaultValues, closeModeOverride: close });

  return (
    <Dialog open={show} onClose={() => close()}>
      <MealForm
        meal_types={mealTypesData?.data?.data || []}
        {...createContext}
        closeMode={close}
        createMode
        title="Create a new meal"
      />
    </Dialog>
  );
}
