export const FuelTypes = {
  diesel: {
    value: 'diesel',
    label: 'Diesel',
  },
  electric: {
    value: 'electric',
    label: 'Electric',
  },
  gas: {
    value: 'gas',
    label: 'Gas',
  },
  hybrid: {
    value: 'hybrid',
    label: 'Hybrid',
  },
  petrol: {
    value: 'petrol',
    label: 'Petrol',
  },
};
export const FuelTypeOptions = Object.values(FuelTypes);
