import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { QueryKey } from '../../../constants/QueryKey';
import { AllergensService } from '../../../services/api/AllergensService';
import { Allergen } from '../../../types/Allergens';

export function useDeleteAllergens(closeMode?: () => void) {
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteModeItem, setDeleteModeItem] = useState<Allergen | null>(null);

  const openDeleteMode = (allergen: Allergen) => {
    setDeleteMode(true);
    setDeleteModeItem(allergen);
  };
  const closeDeleteMode = () => {
    setDeleteMode(false);
    setDeleteModeItem(null);
  };

  const queryClient = useQueryClient();

  const {
    isLoading: deleteLoading, mutate, // isError: isDeleteError,
  } = useMutation(
    (id: number) => AllergensService.Delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.ALLERGENS]);
        if (closeMode) {
          closeMode();
        } else {
          closeDeleteMode();
        }
      },
    },
  );
  const deleteAllergen = () => {
    if (deleteModeItem) {
      mutate(deleteModeItem.id);
    }
  };
  return {
    deleteMode, deleteModeItem, deleteLoading, deleteAllergen, openDeleteMode, closeDeleteMode,
  };
}
