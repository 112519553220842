import { useState } from 'react';

export function useProfileEditModes() {
  const [basicInfoEditMode, setBasicInfoEditMode] = useState(false);
  const [roleEditMode, setRoleEditMode] = useState(false);
  const [hubEditMode, setHubEditMode] = useState(false);

  const closeModes = () => {
    setBasicInfoEditMode(false);
    setRoleEditMode(false);
    setHubEditMode(false);
  };
  const openBasicInfoEditMode = () => {
    closeModes();
    setBasicInfoEditMode(true);
  };
  const openRoleEditMode = () => {
    closeModes();
    setRoleEditMode(true);
  };
  const openHubEditMode = () => {
    closeModes();
    setHubEditMode(true);
  };

  return {
    basicInfoEditMode, roleEditMode, hubEditMode, openBasicInfoEditMode, openHubEditMode, openRoleEditMode, closeModes,
  };
}
