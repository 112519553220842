import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { parseResponseFormErrors } from '../../../../utils/responseFormErrorHelper';
import { QueryKey } from '../../../constants/QueryKey';
import { MealsService } from '../../../services/api/MealsService';
import { MealRequest } from '../../../types/MealRequest';
import { Meal } from '../../../types/Meals';
import { useMealSchema } from './useMealSchema';

type Props = { defaultValues?: MealRequest, closeModeOverride?: (meal?: Meal) => void };

export function useCreateMeal({ closeModeOverride, defaultValues }: Props = {}) {
  const navigate = useNavigate();
  const navigateAway = (meal?: Meal) => navigate({ pathname: '/admin/meals' }, { state: meal ? { initialTabValue: meal.meal_type.id } : {} });
  const closeMode = closeModeOverride || navigateAway;

  const {
    register, handleSubmit, errors, control, setValue, getValues, setError,
  } = useMealSchema(defaultValues || new MealRequest());

  const queryClient = useQueryClient();

  const {
    isLoading, mutate: create, // isError: isCreateError,
  } = useMutation(
    (formData: MealRequest) => {
      const allergen_ids = formData.allergen_ids.map((a) => a.id);
      return MealsService.Create({ ...formData, allergen_ids });
    },
    {
      onSuccess: (responseData) => {
        if (responseData.success) {
          if (responseData?.data) {
            queryClient.invalidateQueries([QueryKey.MEALS]);
            closeMode(responseData.data);
          }
        } else if (responseData?.data?.errors) {
          parseResponseFormErrors(responseData.data.errors, setError);
        }
      },
    },
  );
  const onSubmit = handleSubmit((formData) => create(formData));

  return {
    isLoading, onSubmit, register, errors, control, closeMode, setValue, getValues,
  };
}
