export class LocalStorageManager {
  static get(key: string) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  }

  static set(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }
}

export const LocalStorageKeys = {
  THEME_MODE: 'theme-mode',
};
