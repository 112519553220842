import {
  Divider,
  List, ListItem, ListSubheader, Skeleton,
} from '@mui/material';

export function MenuSkeleton() {
  return (
    <List
      sx={{
        bgcolor: 'secondary.main', borderRadius: 2, px: 1, py: 2, mb: 1, display: 'flex', flexDirection: 'column', gap: 1,
      }}
    >
      <ListSubheader sx={{ bgcolor: 'transparent' }}>
        <Skeleton variant="text" width={200} height={24} />
        <Divider />
      </ListSubheader>
      <ListItem>
        <Skeleton variant="text" width={200} height={30} />
      </ListItem>
      <ListItem>
        <Skeleton variant="text" width={200} height={30} />
      </ListItem>
      <ListSubheader sx={{ bgcolor: 'transparent' }}>
        <Skeleton variant="text" width={200} height={24} />
        <Divider />
      </ListSubheader>
      <ListItem>
        <Skeleton variant="text" width={200} height={30} />
      </ListItem>
      <ListItem>
        <Skeleton variant="text" width={200} height={30} />
      </ListItem>
    </List>
  );
}
