export const EduDegreeOptions = [
  { label: 'III', value: 'III' },
  { label: 'IV', value: 'IV' },
  { label: 'V', value: 'V' },
  { label: 'VI', value: 'VI' },
  { label: 'VI-1', value: 'VI-1' },
  { label: 'VI-2', value: 'VI-2' },
  { label: 'VII', value: 'VII' },
  { label: 'VII-1', value: 'VII-1' },
  { label: 'VII-2', value: 'VII-2' },
  { label: 'VIII', value: 'VIII' },
];
