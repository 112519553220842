import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  startOfMonth, endOfMonth, differenceInDays,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { saveAs } from 'file-saver';
import { standardFormatFromDate } from '../../../../utils/dateHelper';
import { validations } from '../../../../utils/validationHelper';
import { CheckinsUsersTableColumns } from '../../../constants/CheckinsUsersTableColumns';
import { QueryKey } from '../../../constants/QueryKey';
import { CheckInsService } from '../../../services/api/CheckInsService';
import { ExportRequest } from '../../../types/ExportRequest';
import { DateFilter } from '../../../types/Filters';
import { useColumnSelection } from '../../useColumnSelection';
import { useFilter } from '../../useFilter';
import { usePagination } from '../../usePagination';

export function useUserCheckins() {
  const [dates, setDates] = useState<DateFilter>({ start: startOfMonth(new Date()), end: endOfMonth(new Date()) });
  const { start, end } = dates;
  const {
    page, per_page, ListPaginationComponent, setCount,
  } = usePagination();
  const {
    sortBy, direction, setSortBy, setDirection,
  } = useFilter();
  const {
    data, isLoading, isRefetching, // isError, error,
  } = useQuery(
    [QueryKey.CHECK_INS, start, end, page, per_page, sortBy, direction],
    () => CheckInsService.GetAll({
      page,
      size: per_page,
      'filter[checkins.date_from]': start ? standardFormatFromDate(start) : undefined,
      'filter[checkins.date_to]': end ? standardFormatFromDate(end) : undefined,
      'filter[checkins.answer]': 'yes,no',
      'sort[field]': `${sortBy}`,
      'sort[type]': direction,
    }),
    { keepPreviousData: true, enabled: !!start && !!end },
  );
  useEffect(() => {
    if (!isLoading) setCount(data?.data?.metadata?.total_count || 0);
  }, [data, isLoading, setCount]);

  const schema = z.object({
    start: z.date(),
    end: z.date(),
  }).refine((objectData) => differenceInDays(objectData.start, objectData.end) <= 0, {
    message: validations.FIRST_DATE_BEFORE_SECOND,
    path: ['start'],
  });
  const {
    handleSubmit, control,
  } = useForm<DateFilter>({
    resolver: zodResolver(schema),
    values: dates,
  });
  const onSubmit = handleSubmit((formData) => setDates(formData));

  const { columns, onColumnsChange } = useColumnSelection(CheckinsUsersTableColumns);

  const { isLoading: isExportLoading, mutate: onExport } = useMutation(
    () => {
      const sort = { field: sortBy, type: direction };
      const filter = [{
        'checkins.date_from': start ? standardFormatFromDate(start) : undefined,
      },
      {
        'checkins.date_to': end ? standardFormatFromDate(end) : undefined,
      }];
      const postData: ExportRequest = {
        columns: Object.keys(columns).filter((key) => columns[key]), sort, filter,
      };
      return CheckInsService.Export(postData);
    },
    {
      onSuccess: (response) => {
        if (response.success && response.data && start && end) {
          saveAs(response.data, `Checkins list ${standardFormatFromDate(start)} - ${standardFormatFromDate(end)}.xlsx`);
        }
      },
    },
  );

  return {
    data,
    isLoading: isLoading || isRefetching,
    ListPaginationComponent,
    onSubmit,
    control,
    dates,
    setSortBy,
    setDirection,
    onColumnsChange,
    onExport,
    isExportLoading,
  };
}
