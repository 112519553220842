import {
  Avatar,
  IconButton, List, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { Meal } from '../../../framework/types/Meals';
import { StyledLink } from '../../molecules/StyledLink';

export function MealsList({
  meals,
  handleOpenDeleteMode,
}: {
  meals: Meal[],
  handleOpenDeleteMode: (meal: Meal) => void
}) {
  return (
    <List>
      {meals.map((meal) => (
        <ListItem
          key={meal.id}
          sx={{
            '& .secondaryActions': {
              display: 'none',
            },
            '&:hover': {
              bgcolor: 'secondary.main',
              '& .secondaryActions': {
                display: 'block',
              },
            },
          }}
          secondaryAction={(
            <div className="secondaryActions">
              <IconButton component={Link} to={`/meal/${meal.id}`} state={{ editMode: true }}><EditIcon /></IconButton>
              <IconButton onClick={() => handleOpenDeleteMode(meal)}><DeleteIcon /></IconButton>
            </div>
          )}
        >
          <ListItemAvatar>
            <Avatar>
              <FastfoodIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={<StyledLink to={`/meal/${meal.id}`}>{meal.name}</StyledLink>} />
        </ListItem>
      ))}
    </List>
  );
}
