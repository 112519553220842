import { Box, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';

export function FormSection({
  title, actions, children, dense,
}: { title?: ReactNode, actions?: ReactNode, children?: ReactNode, dense?: boolean }) {
  return (
    <Box sx={{ width: '100%', my: 4 }}>
      {(title || actions) && (
        <>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: dense ? 0 : 1, width: '100%',
          }}
          >
            <Typography variant={dense ? 'body1' : 'button'} color={dense ? '' : 'primary'}>{title}</Typography>
            <div />
            {actions}
          </Box>
          {!dense && <Divider sx={{ mb: 2 }} />}
        </>
      )}
      <Box sx={{
        width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '20px', mb: dense ? 0 : 2,
      }}
      >
        {children}
      </Box>
    </Box>

  );
}
