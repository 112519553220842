export const Relationships = {
  SPOUSE: {
    value: 'spouse',
    label: 'Spouse',
    plural: 'Spouse',
  },
  PARTNER: {
    value: 'partner',
    label: 'Partner',
    plural: 'Partner',
  },
  CHILD: {
    value: 'child',
    label: 'Child',
    plural: 'Children',
  },
  PARENT: {
    value: 'parent',
    label: 'Parent',
    plural: 'Parents',
  },
  SIBLING: {
    value: 'sibling',
    label: 'Sibling',
    plural: 'Siblings',
  },
  FRIEND: {
    value: 'friend',
    label: 'Friend',
    plural: 'Friends',
  },
};

export const RelationshipOptions = Object.values(Relationships);

export const RelationStatuses: Record<string, { value: string, label: string }> = {
  in_relationship: {
    value: 'in_relationship',
    label: 'In Relationship',
  },
  married: {
    value: 'married',
    label: 'Married',
  },
  single: {
    value: 'single',
    label: 'Single',
  },
};

export const RelationshipStatusOptions = Object.values(RelationStatuses);
