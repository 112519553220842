import { LoadingButton } from '@mui/lab';
import {
  Box, Button, CircularProgress, IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Check, Close } from '@mui/icons-material';

type Props = {
  mode: boolean,
  openMode: () => void,
  closeMode: () => void,
  submitLoading?: boolean,
  icons?: boolean,
};

export function ActionsGroup({
  mode, openMode, closeMode, submitLoading, icons,
}: Props) {
  if (mode) {
    if (icons) {
      return (
        <>
          <IconButton onClick={closeMode}><Close /></IconButton>
          <IconButton type="submit">
            {!submitLoading && <Check />}
            {submitLoading && <CircularProgress />}
          </IconButton>
        </>
      );
    }
    return (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Button onClick={closeMode}>cancel</Button>
        <LoadingButton type="submit" loading={submitLoading} variant="contained">Submit</LoadingButton>
      </Box>
    );
  }
  if (icons) {
    return <IconButton onClick={openMode}><EditIcon /></IconButton>;
  }
  return <Button onClick={openMode}>Edit</Button>;
}
