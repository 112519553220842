import {
  Box,
  Divider, List, ListItem, ListSubheader, Typography,
} from '@mui/material';
import { Meal } from '../../../framework/types/Meals';
import { MealType } from '../../../framework/types/MealTypes';

export function MenuCalendarItem({ menu, mealTypes }: { menu: { items?: Meal[], meals?: Meal[] }, mealTypes: MealType[] }) {
  const mappedMealTypesData: Record<number, Meal[]> = {};
  if (menu) {
    const list = menu.items || menu.meals || [];
    list.forEach((item) => {
      if (mappedMealTypesData[item.meal_type.id]) {
        mappedMealTypesData[item.meal_type.id].push(item);
      } else {
        mappedMealTypesData[item.meal_type.id] = [item];
      }
    });
  }
  return (
    <Box>
      {mealTypes.map((mt, i) => {
        if (mappedMealTypesData[mt.id] && mappedMealTypesData[mt.id].length > 0) {
          return (
            <List
              dense
              key={mt.id}
              sx={{
                bgcolor: 'secondary.main', borderRadius: 2, p: 1, mb: 1, display: 'flex', flexDirection: 'column', gap: 1,
              }}
            >
              <ListSubheader
                disableGutters
                sx={{
                  bgcolor: 'transparent', p: 0, mb: 1, lineHeight: 'normal',
                }}
              >
                <Typography variant="body2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', color: 'blue.main' }}>{mt.name}</Typography>
                {i < mealTypes.length && <Divider />}
              </ListSubheader>
              {mappedMealTypesData[mt.id].map((item) => {
                if (item.meal_type.id === mt.id) {
                  return (
                    <ListItem disableGutters key={item.id}>
                      <Typography variant="caption" sx={{ lineHeight: 'normal', fontWeight: '500' }}>{item.name}</Typography>
                    </ListItem>
                  );
                }
                return null;
              })}
            </List>
          );
        }
        return null;
      })}
    </Box>
  );
}
