import {
  Box, Card, CardContent, CardHeader, Tab, Tabs,
} from '@mui/material';
import {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useMeals } from '../../../framework/hooks/api/Meals/useMeals';
import { useMealTypes } from '../../../framework/hooks/api/MealTypes/useMealTypes';
import { ResponsiveAddNewButton } from '../../atoms/ResponsiveAddNewButton';
import { Title } from '../../atoms/Title';
import { MealsList } from '../../organisms/meals/MealsList';
import { ConfirmationModal } from '../../utils/ConfirmationModal';
import { FetchUi } from '../../utils/FetchUi';
import { MealsListSkeleton } from '../../utils/skeletons/MealsListSkeleton';
// import { MealTypes } from '../../organisms/MealTypes';

export function MealsPage() {
  const {
    mealTypesData, // mealTypesIsLoading, mealTypesError, mealTypesIsError,
  } = useMealTypes();
  const {
    data,
    isLoading,
    isRefetching,
    isError,
    error,
    per_page,
    ListPagination,
    SearchField,
    setMealTypeFilter,
    deleteMode,
    deleteModeItem,
    handleOpenDeleteMode,
    handleCloseDeleteMode,
    deleteMeal,
  } = useMeals(false);

  const [tabValue, setTabValue] = useState<number | false>(false);

  const { state } = useLocation();
  const setActualTabValue = useCallback((value?: number) => {
    setTabValue(value || false);
    setMealTypeFilter(value || undefined);
  }, [setMealTypeFilter]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setActualTabValue(newValue);
  };
  useEffect(() => {
    if (!tabValue) {
      if (state?.initialTabValue) {
        setActualTabValue(state.initialTabValue);
      } else if (mealTypesData?.data?.data[0]) {
        setActualTabValue(mealTypesData.data?.data[0].id);
      }
    }
  }, [mealTypesData, setActualTabValue, setMealTypeFilter, state, tabValue]);

  return (
    <Box sx={{ px: { xs: 0, md: 8 }, maxWidth: '100%' }}>
      <Title title="Meals" />
      <Card>
        <CardHeader title={(
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: { xs: '100%', md: 'auto' } }}>
            <Box sx={{ display: 'flex', width: { xs: '100%', md: 'auto' } }}>
              {SearchField}
            </Box>
            {/* <Box sx={{ display: 'flex' }}>
              {Sort}
            </Box> */}
          </Box>
        )}
        />
        <CardContent>
          <Box sx={{
            width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' },
          }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="wrapped label tabs example"
            >
              {mealTypesData?.data?.data?.map((mt) => (
                <Tab
                  value={mt.id}
                  label={mt.name}
                  key={mt.id}
                  wrapped
                />
              ))}
            </Tabs>
          </Box>
          {mealTypesData && (
            <FetchUi
              isLoading={isLoading || isRefetching}
              error={isError ? error as Error : null}
              loadingUi={<MealsListSkeleton amount={per_page} />}
            >
              <>
                <MealsList
                  meals={data?.data?.data || []}
                  handleOpenDeleteMode={handleOpenDeleteMode}
                />
                {ListPagination}
              </>
            </FetchUi>
          )}
        </CardContent>
      </Card>
      <ConfirmationModal
        title="Are you sure you want to delete this meal?"
        cancel={handleCloseDeleteMode}
        confirm={() => deleteMeal(deleteModeItem?.id!)}
        open={deleteMode}
      >
        {deleteModeItem && <span>{deleteModeItem.name!}</span>}
      </ConfirmationModal>
      <ResponsiveAddNewButton to="/admin/meal/create" />
    </Box>
  );
}
