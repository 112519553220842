import {
  ListItem, IconButton, ListItemText, Avatar, Box, ListItemAvatar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Vehicle } from '../../../framework/types/Vehicles';
import { Roles } from '../../../framework/constants/roles';
import { AuthGuard } from '../../utils/AuthGuard';

type Props = { vehicle: Vehicle, openEditMode: (member: Vehicle) => void, openDeleteMode: (member: Vehicle) => void };

export function VehicleItem({ vehicle, openEditMode, openDeleteMode }: Props) {
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        '& .secondaryActions': {
          display: 'none',
        },
        '&:hover': {
          bgcolor: 'secondary.main',
          '& .secondaryActions': {
            display: 'block',
          },
        },
      }}
      secondaryAction={(
        <div className="secondaryActions">
          <AuthGuard roles={[Roles.ADMIN]}>
            <IconButton onClick={() => openEditMode(vehicle)}><EditIcon /></IconButton>
            <IconButton onClick={() => openDeleteMode(vehicle)}><DeleteIcon /></IconButton>
          </AuthGuard>
        </div>
      )}
    >
      <ListItemAvatar>
        <Avatar>
          <DirectionsCarIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={(
          <Box sx={{ display: 'flex', gap: '10px' }}>
            {vehicle.model}
            {' - '}
            {vehicle.reg_number}
          </Box>
        )}
        secondary={(
          <>
            <span>
              Fuel type:
              {' '}
              {vehicle.fuel_type}
            </span>
            <br />
            <span>
              Average consumption:
              {' '}
              {vehicle.avg_consumption}
              {' '}
              l/100km
            </span>
          </>
        )}
      />
    </ListItem>
  );
}
