import {
  Divider, IconButton, List, ListItem, ListItemButton, ListSubheader, Tooltip, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ReportIcon from '@mui/icons-material/Report';
import { SingleDateMeal } from '../../../framework/types/Calendar';
import { MealType } from '../../../framework/types/MealTypes';
import { useAuthStore } from '../../../framework/store/AuthStore';
import { authGuard } from '../../../utils/authGuardHelper';
import { Roles } from '../../../framework/constants/roles';

export function MenuItem({ menu, mealTypes, openUsersAllergenDialog }: {
  menu: { meals?: SingleDateMeal[] }, mealTypes: MealType[], openUsersAllergenDialog: (meal: SingleDateMeal) => void
}) {
  const { user: currentUser } = useAuthStore();
  const mappedMealTypesData: Record<number, SingleDateMeal[]> = {};
  if (menu) {
    const list = menu.meals || [];
    list.forEach((item) => {
      if (mappedMealTypesData[item.meal_type.id]) {
        mappedMealTypesData[item.meal_type.id].push(item);
      } else {
        mappedMealTypesData[item.meal_type.id] = [item];
      }
    });
  }

  return (
    <div>
      {mealTypes.map((mt, i) => {
        if (mappedMealTypesData[mt.id] && mappedMealTypesData[mt.id].length > 0) {
          return (
            <List
              sx={{
                bgcolor: 'secondary.main', borderRadius: 2, px: 1, py: 2, mb: 1, display: 'flex', flexDirection: 'column', gap: 1,
              }}
              key={mt.id}
            >
              <ListSubheader sx={{ textTransform: 'capitalize', bgcolor: 'transparent', mb: 1 }}>
                <Typography>{mt.name}</Typography>
                {i < mealTypes.length && <Divider />}
              </ListSubheader>
              {mappedMealTypesData[mt.id].map((item) => {
                if (item.meal_type.id === mt.id) {
                  const allergensMatched = item.meal_allergens.some((a) => a.allergic_users?.length);
                  const auth = authGuard([Roles.ADMIN, Roles.MEAL_PROVIDER], currentUser?.role);
                  const currentMatched = item.meal_allergens?.some((a) => a.allergic_users?.some((u) => u.id === currentUser?.id));
                  return (
                    <ListItem
                      key={item.id}
                      disableGutters
                      disablePadding
                      secondaryAction={allergensMatched && (auth || currentMatched) && (
                        <Tooltip title="There are allergens matched for today">
                          <IconButton onClick={() => openUsersAllergenDialog(item)}>
                            <ReportIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      )}
                      sx={{
                        '&:hover': {
                          bgcolor: 'secondary.main',
                        },
                      }}
                    >
                      <ListItemButton
                        dense
                        component={Link}
                        to={`/meal/${item.id}`}
                        sx={{
                          borderRadius: 4,
                          '&:hover': {
                            // backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <Typography>{item.name}</Typography>
                      </ListItemButton>
                    </ListItem>
                  );
                }
                return null;
              })}
            </List>
          );
        }
        return null;
      })}
    </div>
  );
}
