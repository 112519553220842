import {
  Table, TableBody, TableRow, TableCell,
} from '@mui/material';
import { differenceInYears } from 'date-fns';
import { FamilyMember } from '../../../framework/types/FamilyMember';

export function FamilyTable({ members }: { members: FamilyMember[] }) {
  return (
    <Table sx={{ height: '100%' }}>
      <TableBody>
        {members
          .map((f) => (
            <TableRow key={f.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
              <TableCell sx={{ width: '200px', borderRight: '1px solid #f0f0f0' }}>
                {f.first_name}
                {' '}
                {f.last_name}
              </TableCell>
              <TableCell sx={{ width: '150px', borderRight: '1px solid #f0f0f0' }}>{f.dob}</TableCell>
              <TableCell
                sx={{ width: '100px', borderRight: '1px solid #f0f0f0' }}
                align="center"
              >
                {differenceInYears(new Date(), new Date(f.dob))}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
