import Card from '@mui/material/Card';
import {
  Avatar, CardContent, Tab, Tabs,
  Box,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { User } from '../../../framework/types/User';
import { BasicInfoForm } from './BasicInfoForm';
import { PersonalDataForm } from './PersonalDataForm';
import { profileTabs, profileTabsList } from '../../../framework/constants/ProfileTabs';
import { FetchUi } from '../../utils/FetchUi';
import { FamilyData } from './FamilyData';
import { VehiclesData } from './VehiclesData';
import { AllergensData } from './AllergensData';
import { UserProfileSkeleton } from '../../utils/skeletons/UserProfileSkeleton';

export function UserProfile({ user, isLoading }: { user?: User, isLoading?: boolean }) {
  const [tabValue, setTabValue] = useState<string>('basic');
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <Card>
      <CardContent sx={{
        display: 'flex', flexDirection: 'column', gap: '20px', padding: '30px', position: 'relative',
      }}
      >
        <FetchUi
          isLoading={isLoading || !user}
          loadingUi={<UserProfileSkeleton />}
        >
          <Box sx={{
            display: 'flex', alignItems: 'center', gap: '30px', flexDirection: { xs: 'column', md: 'row' },
          }}
          >
            <Avatar src={user?.personal?.avatar || ''} sx={{ width: 150, height: 150 }} />
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, textAlign: 'center',
            }}
            >
              <Typography variant="h4">
                {user?.first_name}
                {' '}
                {user?.last_name}
              </Typography>
              <Typography>{user?.email}</Typography>
              <Typography>{user?.nickname}</Typography>
            </Box>
          </Box>
        </FetchUi>
        <TabContext value={tabValue}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {profileTabsList.map((tab) => (
              <Tab
                value={tab.value}
                label={tab.label}
                key={tab.value}
                wrapped
              />
            ))}
          </Tabs>
        </TabContext>
        <TabContext value={tabValue}>
          <TabPanel value={profileTabs.BASIC.value} sx={{ p: '0' }}>
            {user && <BasicInfoForm user={user} />}
          </TabPanel>
          <TabPanel value={profileTabs.PERSONAL.value} sx={{ p: '0' }}>
            {user && <PersonalDataForm user={user} />}
          </TabPanel>
          <TabPanel value={profileTabs.FAMILY.value} sx={{ p: '0' }}>
            {user && <FamilyData user={user} />}
          </TabPanel>
          <TabPanel value={profileTabs.VEHICLES.value} sx={{ p: '0' }}>
            {user && <VehiclesData user={user} />}
          </TabPanel>
          <TabPanel value={profileTabs.ALLERGENS.value} sx={{ p: '0' }}>
            {user && <AllergensData user={user} />}
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  );
}
