import { LoadingButton } from '@mui/lab';
import {
  Button, Card, CardActions, CardContent, CardHeader, TextField,
} from '@mui/material';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Allergen, AllergenRequest } from '../../../framework/types/Allergens';

type Props = {
  isLoading: boolean,
  onSubmit: () => void,
  register: UseFormRegister<AllergenRequest>,
  errors: FieldErrors<AllergenRequest>,
  closeDialogMode: () => void,
  editModeItem: Allergen | null,
};

export function AllergenForm({
  isLoading, onSubmit, register, errors, closeDialogMode, editModeItem,
}: Props) {
  return (
    <Card>
      <form onSubmit={onSubmit}>
        <CardHeader title={editModeItem?.id ? 'Update Allergen' : 'Create Allergen'} />
        <CardContent>
          <TextField
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name?.message || ''}
            label="Allergen"
          />
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={closeDialogMode}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>Save</LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
}
