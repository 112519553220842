import {
  Box,
  Card, CardContent,
} from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import DataTable from '../../utils/datatable/DataTable';
import { User } from '../../../framework/types/User';
import { UsersTableColumns } from '../../../framework/constants/UsersTableColumns';
import { UsersFilter } from '../../organisms/users/UsersFilter';
import { useUsers } from '../../../framework/hooks/api/Users/useUsers';
import { UserQueryFilter } from '../../../framework/types/UsersQueryFilter';
import { userTableOptions } from '../../../framework/constants/UserTableColumnFields';
import { UsersAPIQuery } from '../../../framework/types/UsersAPIQuery';
import { ResponsiveExportButton } from '../../atoms/ResponsiveExportButton';
import { Title } from '../../atoms/Title';

export function UsersPage() {
  const {
    data, isLoading, ListPaginationComponent, setSortBy, setDirection, refetch, query, setQuery, onColumnsChange, onExport, isExportLoading,
  } = useUsers();

  const onSortChange = (model: GridSortModel) => {
    if (model.length) {
      const singleModel = model[0];
      setSortBy(userTableOptions[singleModel.field].sort);
      setDirection(singleModel.sort || 'asc');
    }
  };
  const onFiltersChange = (newValues: UserQueryFilter) => {
    setQuery(new UsersAPIQuery(newValues));
  };
  const onSearchReset = () => {
    query.setSearch = '';
    setQuery(query);
    refetch();
  };
  const disabledExport = data?.data?.data?.length === 0;

  return (
    <Box sx={{ px: { xs: 0, md: 8 }, maxWidth: '100%' }}>
      <Title title="Users" />
      <Card>
        <CardContent>
          <DataTable<User>
            columns={UsersTableColumns}
            rows={data?.data?.data || []}
            paginationComponent={ListPaginationComponent}
            isLoading={isLoading}
            onSortChange={onSortChange}
            onColumnVisibilityChange={onColumnsChange}
            toolbarSlot={(
              <UsersFilter
                onFiltersChange={onFiltersChange}
                onSearchReset={onSearchReset}
                query={query}
              />
            )}
          />
        </CardContent>
        <ResponsiveExportButton isLoading={isExportLoading} disabled={disabledExport} onExport={onExport} />
      </Card>
    </Box>
  );
}
