import {
  Box, Tooltip, IconButton, CircularProgress, MenuItem, Menu, Divider, ButtonGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export function AnswerCheckinButtons({
  isLoading, disabled, handleYes, handleNo, datesTitle, horizontal, yesTooltip, noTooltip,
}: {
  isLoading?: boolean,
  disabled: boolean,
  handleYes: () => void;
  handleNo: () => void;
  datesTitle: string;
  horizontal?: boolean;
  noTooltip: string;
  yesTooltip: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', height: '100%' }}>
        <ButtonGroup
          sx={{ bgcolor: 'secondary.main' }}
          variant="contained"
          orientation={horizontal ? 'horizontal' : 'vertical'}
        >
          <Tooltip
            title={disabled ? '' : yesTooltip}
            placement="right"
            arrow
          >
            <IconButton
              onClick={handleYes}
              size="small"
              disabled={disabled}
              sx={{
                '&.Mui-disabled svg': {
                  color: '#c0c0c0',
                },
              }}
            >
              <DoneAllIcon fontSize="large" color="success" />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={disabled ? '' : noTooltip}
            placement="right"
            arrow
          >
            <IconButton
              onClick={handleNo}
              size="small"
              disabled={disabled}
              sx={{
                '&.Mui-disabled svg': {
                  color: '#c0c0c0',
                },
              }}
            >
              <CloseIcon fontSize="large" color="error" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Box>
      <Box sx={{
        display: { xs: disabled ? 'none' : 'flex', md: 'none' }, width: '100%', justifyContent: 'center',
      }}
      >
        <LoadingButton
          loading={isLoading}
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Check-in for
          {' '}
          {datesTitle}
        </LoadingButton>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            sx={{ width: '260px', display: 'flex', gap: 2 }}
            onClick={() => {
              handleYes();
              handleClose();
            }}
            disableRipple
          >
            <DoneAllIcon fontSize="large" color="success" />
            All Yes
          </MenuItem>
          <Divider />
          <MenuItem
            sx={{ width: '260px', display: 'flex', gap: 2 }}
            onClick={() => {
              handleNo();
              handleClose();
            }}
            disableRipple
          >
            <CloseIcon fontSize="large" color="error" />
            All No
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}
